import { device } from '../utils/responsive_utilities';
import i18n from "../utils/i18n";
import { getCDNAssetURL } from '../utils/web-helper';
const cdnAssetUrl = getCDNAssetURL();

export const GRADE_SUBJECT_CONTENT_LIST = [
  {
    "grade_id": 7,
    "subject_id": 1,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Counting": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.counting_games_for_preschoolers')}`,
      "Shapes": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.shapes_games_for_preschoolers')}`,
      "Addition": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.addition_games_for_preschoolers')}`
    },
    "seeAll": {text: "See all 157 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_games_preschoolers')}`}
  },
  {
    "grade_id": 7,
    "subject_id": 2,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Alphabet": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.alphabet_games_for_preschoolers')}`,
      "Alphabetical Order": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.alphabetical_order_games_for_preschoolers')}`,
      "Letter Tracing": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.letter_tracing_games_for_preschoolers')}`
    },
    "seeAll": {text: "See all 463 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_games_preschoolers')}`}
  },
  {
    "grade_id": 7,
    "subject_id": 2,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Alphabet": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.alphabet_worksheets_for_preschoolers')}`,
      "Alphabetical Order": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.alphabetical_order_worksheets_for_preschoolers')}`,
      "Letter Tracing": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.letter_tracing_worksheets_for_preschoolers')}`
    },
    "seeAll": {text: "See all 167 worksheets", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_worksheets_preschoolers')}`}
  },
  {
    "grade_id": 1,
    "subject_id": 1,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Number sense": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.number_sense_games_for_kindergarteners')}`,
      "Addition": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.addition_games_for_kindergarteners')}`,
      "Geometry": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.geometry_games_for_kindergarteners')}`
    },
    "seeAll": {text: "See all 314 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_games_kindergarten')}`}
  },
  {
    "grade_id": 1,
    "subject_id": 1,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Number Sense": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.number_sense_worksheets_for_kindergarteners')}`,
      "Addition": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.addition_worksheets_for_kindergarteners')}`,
      "Geometry": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.geometry_worksheets_for_kindergarteners')}`
    },
    "seeAll": {text: "See all 438 worksheets", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_worksheets_kindergarten')}`}
  },
  {
    "grade_id": 1,
    "subject_id": 2,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Letter Sounds": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.letter_sounds_games_for_kindergarteners')}`,
      "Alphabet": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.alphabet_games_for_kindergarteners')}`,
      "Reading": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.reading_games_for_kindergarteners')}`
    },
    "seeAll": {text: "See all 1055 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_games_kindergarten')}`}
  },
  {
    "grade_id": 1,
    "subject_id": 2,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Letter Sounds": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.letter_sounds_worksheets_for_kindergarteners')}`,
      "Alphabet": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.letter_sounds_games_for_kindergarteners')}`,
      "Reading": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.reading_worksheets_for_kindergarteners')}`
    },
    "seeAll": {text: "See all 438 worksheets", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_worksheets_kindergarten')}`}
  },
  {
    "grade_id": 2,
    "subject_id": 1,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Additon": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.addition_games_for_1st_graders')}`,
      "Subtraction": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.subtraction_games_for_1st_graders')}`,
      "Time": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.time_games_for_1st_graders')}`
    },
    "seeAll": {text: "See all 352 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_games_for_1st_grader')}`}
  },
  {
    "grade_id": 2,
    "subject_id": 1,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Addition": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.addition_worksheets_for_1st_graders')}`,
      "Subtraction": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.subtraction_worksheets_for_1st_graders')}`,
      "Time": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.time_worksheets_for_1st_graders')}`
    },
    "seeAll": {text: "See all 693 worksheets", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_worksheets_for_1st_grader')}`}
  },
  {
    "grade_id": 2,
    "subject_id": 2,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Reading": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.reading_games_for_1st_graders')}`,
      "Sight Words": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.sight_words_games_for_1st_graders')}`,
      "Phonics": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.phonics_games_for_1st_graders')}`
    },
    "seeAll": {text: "See all 613 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_games_for_1st_grader')}`}
  },
  {
    "grade_id": 2,
    "subject_id": 2,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Reading": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.reading_worksheets_for_1st_graders')}`,
      "Sight Words": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.sight_words_worksheets_for_1st_graders')}`,
      "Rhyming Words": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.rhyming_words_worksheets_for_1st_graders')}`
    },
    "seeAll": {text: "See all 338 worksheets", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_worksheets_for_1st_grader')}`}
  },
  {
    "grade_id": 3,
    "subject_id": 1,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Addition": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.addition_games_for_2nd_graders')}`,
      "Subtraction": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.subtraction_games_for_2nd_graders')}`,
      "Counting Money": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.counting_money_games_for_2nd_graders')}`
    },
    "seeAll": {text: "See all 359 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_games_for_2nd_grader')}`}
  },
  {
    "grade_id": 3,
    "subject_id": 1,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Addition": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.addition_worksheets_for_2nd_graders')}`,
      "Subtraction": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.subtraction_worksheets_for_2nd_graders')}`,
      "Counting Money": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.counting_money_worksheets_for_2nd_graders')}`
    },
    "seeAll": {text: "See all 825 worksheets", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_worksheets_for_2nd_grader')}`}
  },
  {
    "grade_id": 3,
    "subject_id": 2,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Reading": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.reading_games_for_2nd_graders')}`,
      "Rhyming Words": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.rhyming_words_games_for_2nd_graders')}`,
      "Sight Words": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.sight_words_games_for_2nd_graders')}`
    },
    "seeAll": {text: "See all 405 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_games_for_2nd_grader')}`}
  },
  {
    "grade_id": 3,
    "subject_id": 2,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Reading": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.reading_worksheets_for_2nd_graders')}`,
      "Diphthongs": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.diphthongs_worksheets_for_2nd_graders')}`,
      "Sight Words": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.sight_words_worksheets_for_2nd_graders')}`
    },
    "seeAll": {text: "See all 222 worksheets", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_worksheets_for_2nd_grader')}`}
  },
  {
    "grade_id": 4,
    "subject_id": 1,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Multiplication": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.multiplication_games_for_3rd_graders')}`,
      "Division": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.division_games_for_3rd_graders')}`,
      "Fractions": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.fractions_games_for_3rd_graders')}`
    },
    "seeAll": {text: "See all 527 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_games_for_3rd_grader')}`}
  },
  {
    "grade_id": 4,
    "subject_id": 1,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Multiplication": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.multiplication_worksheets_for_3rd_graders')}`,
      "Division": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.division_worksheets_for_3rd_graders')}`,
      "Fractions": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.fractions_worksheets_for_3rd_graders')}`
    },
    "seeAll": {text: "See all 742 worksheets", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_worksheets_for_3rd_grader')}`}
  },
  {
    "grade_id": 4,
    "subject_id": 2,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Reading Comprehension": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.reading-comprehension-games-for-3rd-graders')}`,
      "Sequencing": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.sequencing_games_for_3rd_graders')}`,
      "Cause and Effect": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.cause_and_effect_games_for_3rd_graders')}`
    },
    "seeAll": {text: "See all 44 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_games_for_3rd_grader')}`}
  },
  {
    "grade_id": 4,
    "subject_id": 2,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Nouns": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.nouns_worksheets_for_3rd_graders')}`,
      "Compound Words": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.compound_words_worksheets_for_3rd_graders')}`,
      "Verbs": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.verbs_and_tenses_worksheets_for_3rd_graders')}`
    },
    "seeAll": {text: "See all 64 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_worksheets_for_3rd_grader')}`}
  },
  {
    "grade_id": 5,
    "subject_id": 1,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Multiplication": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.multiplication_games_for_4th_graders')}`,
      "Decimals": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.decimals_games_for_4th_graders')}`,
      "Fractions": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.fractions_games_for_4th_graders')}`
    },
    "seeAll": {text: "See all 351 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_games_for_4th_grader')}`}
  },
  {
    "grade_id": 5,
    "subject_id": 1,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Multiplication": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.multiplication_worksheets_for_4th_graders')}`,
      "Decimals": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.decimals_worksheets_for_4th_graders')}`,
      "Fractions": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.fractions_worksheets_for_4th_graders')}`
    },
    "seeAll": {text: "See all 987 worksheets", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_worksheets_for_4th_grader')}`}
  },
  {
    "grade_id": 5,
    "subject_id": 2,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Reading Comprehension": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.reading_comprehension_games_for_4th_graders')}`,
      "Prediction": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.prediction_games_for_4th_graders')}`,
      "Inference": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.inference_games_for_4th_graders')}`
    },
    "seeAll": {text: "See all 44 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_games_for_4th_grader')}`}
  },
  {
    "grade_id": 5,
    "subject_id": 2,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Conjunctions": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.conjunctions_worksheets_for_4th_graders')}`,
      "Punctuation": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.punctuation_worksheets_for_4th_graders')}`,
      "Affixes": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.affixes_worksheets_for_4th_graders')}`
    },
    "seeAll": {text: "See all 72 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_worksheets_for_4th_grader')}`}
  },
  {
    "grade_id": 6,
    "subject_id": 1,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Fractions": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.fractions_games_for_5th_graders')}`,
      "Decimals": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.decimals_games_for_5th_graders')}`,
      "Algebra": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.algebra_games_for_5th_graders')}`
    },
    "seeAll": {text: "See all 202 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_games_for_5th_grader')}`}
  },
  {
    "grade_id": 6,
    "subject_id": 1,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Fractions": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.fractions_worksheets_for_5th_graders')}`,
      "Decimals": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.decimals_worksheets_for_5th_graders')}`,
      "Algebra": `/${i18n.t('math')}/${i18n.t('loggedout_homepage.universe_section.games.algebra_worksheets_for_5th_graders')}`
    },
    "seeAll": {text: "See all 1843 worksheets", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.math_worksheets_for_5th_grader')}`}
  },
  {
    "grade_id": 6,
    "subject_id": 2,
    "content_type": 1, "content_title": "Games",
    "topics": {
      "Reading Comprehension": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.reading_comprehension_games_for_5th_graders')}`,
      "Prediction": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.prediction_games_for_5th_graders')}`,
      "Inference": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.inference_games_for_5th_graders')}`
    },
    "seeAll": {text: "See all 44 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_games_for_5th_grader')}`}
  },
  {
    "grade_id": 6,
    "subject_id": 2,
    "content_type": 2, "content_title": "Worksheets",
    "topics": {
      "Verbs": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.verbs_worksheets_for_5th_graders')}`,
      "Grammar": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.grammar_worksheets_for_5th_graders')}`,
      "Figures of Speech": `/${i18n.t('ela')}/${i18n.t('loggedout_homepage.universe_section.games.figures_of_speech_worksheets_for_5th_graders')}`
    },
    "seeAll": {text: "See all 53 games", url: `/${i18n.t('loggedout_footer.splashLearn_content_url.ela_worksheets_for_5th_grader')}`}
  }
];


export const ContentTypeList = {
  1: {
    id: 1,
    code: "games",
    title: "Games",
    count: "(4413+)",
    imgDirPath: `${cdnAssetUrl}/web_ui/assets/images/loggedout/Curriculum-images/Playable`
  },
  2: {
    id: 2,
    code: "worksheets",
    title: "Worksheets",
    count: "(4567+)",
    imgDirPath: `${cdnAssetUrl}/web_ui/assets/images/loggedout/Curriculum-images/Worksheet`
  }
};

export const gradeList = [
  {
      "id": 7,
      "code": "pk",
      "title": `${i18n.t('loggedout-grade.pk')}`,
      "ordering": 4
  },
  {
      "id": 1,
      "code": "k",
      "title": `${i18n.t('loggedout-grade.k')}`,
      "ordering": 5
  },
  {
      "id": 2,
      "code": 1,
      "title": `${i18n.t('loggedout-grade.1')}`,
      "ordering": 6
  },
  {
      "id": 3,
      "code": 2,
      "title": `${i18n.t('loggedout-grade.2')}`,
      "ordering": 7
  },
  {
      "id": 4,
      "code": 3,
      "title": `${i18n.t('loggedout-grade.3')}`,
      "ordering": 8
  },
  {
      "id": 5,
      "code": 4,
      "title": `${i18n.t('loggedout-grade.4')}`,
      "ordering": 9
  },
  {
      "id": 6,
      "code": 5,
      "title": `${i18n.t('loggedout-grade.5')}`,
      "ordering": 10
  }
];

export const subjectList = {
  1: {
    "id": 1,
    "code": "math",
    "title": `${i18n.t('loggedout_homepage.Math')}`,
    "imageDir": "Math-images",
  },
  2: {
    "id": 2,
    "code": "ela",
    "title": `${i18n.t('loggedout_homepage.English')}`,
    "imageDir": "ELA-images",
  }
};

export const getGradeImage = (content_type, subject_id, gradeCode ) => {
  const imagePath = `${ContentTypeList[content_type].imgDirPath}/${subjectList[subject_id].imageDir}`

  return {
    src: `${imagePath}/${gradeCode}.png`,
    srcset: `${imagePath}/${gradeCode}.png 374w,
              ${imagePath}/${gradeCode}_2x.png 748w`,
    sizes: `${device.Phone} 374px,
            ${device.Tablet} 374px,
            ${device.Desktop} 748px,
            374px`
  }
}
