import styled from 'styled-components';
import {white, black} from '../../shared-styles/Colors';
import { Heading2 } from '../../shared-styles/Font';

export const Card = styled.a`
  height: 22.6rem;
  width: 32rem;
  min-width: 222px;
  background: ${white};
  box-shadow: 0 12px 24px 0 rgba(200, 200, 200, 0.5);
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 0.5s;
  cursor: pointer;
  text-align: center;
  flex-direction: column;
  color: ${black};
  & > ${Heading2} {
    margin-top: 1.8rem;
  }
  & > img {
    width: ${props => props.imageWidth || "9.5rem"};
    height: ${props => props.imageHeight || "8.8rem"};
  }
  &:hover {
    transform: scale(1.15);
    text-decoration: none;
  }
`