import React, { useState } from 'react';
import {SigninApi} from '../../../services/login-api';
import isEmail from 'validator/lib/isEmail';
import { eventHandler } from '../../../utils/event-utils';

const EmailPassForm = ({source, ...props}) => {

  const inputFieldValueFocusedCorrect = 'FOCUSED_CORRECT';
  const inputFieldValueUnfocusedCorrect = 'UNFOCUSED_CORRECT';
  const inputFieldValueIncorrect = 'INCORRECT';

  const [email, setEmail] = useState('');
  const [isPasswordBlank, setPasswordBlank] = useState(false);
  const [password, setPassword] = useState('');
  const [emailState, setEmailState] = useState(inputFieldValueUnfocusedCorrect);
  const [passwordState, setPasswordState] = useState(inputFieldValueUnfocusedCorrect);
  const [errorMessage, setErrorMessage] = useState('');

  function checkEmailTextListener(emailElement) {
    setEmail(emailElement.target.value);
    updateEmailInputFieldStyle(emailElement.target.value);
  }

  function updateEmailInputFieldStyle(email) {
    if (!isEmail(email)) {
      setEmailState(inputFieldValueIncorrect);
    } else {
      setEmailState(inputFieldValueFocusedCorrect);
    }
  }

  function onPasswordTextListener(e) {
    setPassword(e.target.value);
    updatePasswordInputFieldStyle(e.target.value);
  }

  function updatePasswordInputFieldStyle(password) {
    if (password.length === 0) {
      setPasswordState(inputFieldValueIncorrect);
    } else {
      setPasswordState(inputFieldValueFocusedCorrect);
    }
  }

  function onPasswordFocusOut() {
    if (password.length > 0) {
      setPasswordState(inputFieldValueUnfocusedCorrect);
      setPasswordBlank(false);
    } else {
      setPasswordBlank(true);
    }
  }

  function clickOnLoginButton() {
    if (password.length > 0) {
      setErrorMessage('');
      SigninApi({user: {login: email, password: password, remember_me: 0}, commit: 'login'}, 
        (source === 'class' ? 'Class Login' : 'Parent/Teacher Login'))
      .then((resp) => {
        if (resp.error) {
          showError(resp.error);
        } else {
          if (resp.goto) {
            return document.location.href = resp.goto;
          } else {
            showError('Something went wrong, Please try  again.');
          }
        }
      }).catch((exception) => {
        showError('Something went wrong, Please try  again.');
      });
    } else {
      setPasswordBlank(true);
    }
  }

  function showError(message) {
    eventHandler('Login Failed', source === 'class' ?  'Class Login' : 'Parent/Teacher Login', true);
    setErrorMessage(message);
  }

  function onPasswordKeyDown(e) {
    if (e.key === 'Enter') {
      onPasswordFocusOut()
      clickOnLoginButton();
      e.preventDefault();
    }
  }

  return (
    <div className="email-password-form">
      <p>{source === 'class' ? 'Username' : 'Email'}</p>
      <input 
        type="email"
        style={ (emailState === inputFieldValueFocusedCorrect) ? {border: '1px solid #4f52c3'} : (emailState === inputFieldValueUnfocusedCorrect) ? {border: '1px solid #cacad8'} : {border: '1px solid #d81430'} } 
        onChange={(e) => checkEmailTextListener(e)}
        onFocus={(e) => updateEmailInputFieldStyle(e.target.value)}
        onBlur={() => setEmailState(inputFieldValueUnfocusedCorrect)}
        autofocus
      />
      <div className="password-wrapper">
        <div className="password-label-forgot-link">
          <p>Password</p>
          <a className="forgot-password-link" href="/forgot_password">Forget password?</a>
        </div>
        <div>
          <input 
            type="password" 
            style={ (passwordState === inputFieldValueFocusedCorrect) ? {border: '1px solid #4f52c3'} : (passwordState === inputFieldValueUnfocusedCorrect) ? {border: '1px solid #cacad8'} : {border: '1px solid #d81430'} }
            onChange={(e) => onPasswordTextListener(e)}
            onFocus={(e) => updatePasswordInputFieldStyle(e.target.value)}
            onBlur={() => onPasswordFocusOut()}
            onKeyDown={(e) => onPasswordKeyDown(e)}
          />
          {
            isPasswordBlank && <span className="login-form-error">Can't be blank</span>
          }
        </div>
        {
          (errorMessage.length > 0) && <span className="login-form-error">{errorMessage}</span>
        }
      </div>
      <div className="button-wrapper">
        <button className="new-login-cta-button" onClick={() => clickOnLoginButton()}>Login</button>
      </div>
    </div>
  )
}

export default EmailPassForm