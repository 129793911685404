import CallableMessages from "./CallableMessages";
import MessageHandler from "./messaging/MessageHandler";
import PlatformSettings from "./PlatformSettings";
import UserMarketingData from "./UserMarketingData";
import UserService from "./UserService";
import RouteService from "./flutter-onboarding/RouteInfo";
var ApplicationContext = /** @class */ (function () {
    function ApplicationContext() {
        if (!ApplicationContext._instance) {
            ApplicationContext._instance = this;
            ApplicationContext._instance.initialize();
        }
        return ApplicationContext._instance;
    }
    ApplicationContext.prototype.initialize = function () {
        this.userService = new UserService();
        this.messageHandler = new MessageHandler();
        this.platformSettings = new PlatformSettings();
        this.callableMessages = new CallableMessages();
        this.userMarketingData = new UserMarketingData();
        this.routeService = new RouteService();
    };
    return ApplicationContext;
}());
export default ApplicationContext;
