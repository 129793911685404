import {gql, GraphQLClient} from "graphql-request";

const graphqlApiHandler = (path, query, variables = null, extServiceFlag = true) => {

  const EXT_SERVICE_MODE = 'cors';
  const EXT_SERVICE_CREDENTIALS = 'include';

  let requestObject;

  if (extServiceFlag) {
   requestObject = {
      credentials: EXT_SERVICE_CREDENTIALS,
      mode: EXT_SERVICE_MODE
    }
  } else {
    // As of now, it's empty we could add, if we want to
    requestObject = {};
  }

  const graphQLClient = new GraphQLClient(path, requestObject);

  return () => {

    const success = (response) => {
      return response;
    }

    const error = (err) => {
      throw err;
    }

    return graphQLClient.request(
        gql`${query}`,
        variables
    )
    .then(success)
    .catch(error)
  }

}

export default graphqlApiHandler;