import React, { useEffect } from 'react';
import styled from 'styled-components';
import {whiteLilac} from '../../components/loggedout/shared-styles/Colors';
import MainSigninPage from '../../components/loggedout/new_signin/index';
import { eventHandler } from '../../utils/event-utils';

const NewSigninPage = (props) => {

  useEffect(() => {
    eventHandler('Account Type Screen Shown', {'Intent for': 'login', 'Screen type': 'page', 'From Page': 'Login'});
  })

  return (
    <Wrapper>
      <div className="content-container">
        <MainSigninPage {...props}/>
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  height: calc(100vh - 80px);
  min-height: 695px;
  width: 100%;
  background: ${whiteLilac};
  position: relative;
  .content-container {
    height: 100%;
  }
`
export default NewSigninPage