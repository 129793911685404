import {PRE_K_GRADE , K_GRADE, FIRST_GRADE } from './constants';

const secrets = require('../../../public_keys.json');

export let multiplayer_classes =  ["k", "1", "2", "3", "4", "5"];

//Polling frequency and idle timeouts of apis (in milliseconds)
//Make sure to keep these values same as prod keys for consistency
export const multiplayer_poll_data = {
  "fetch_invites_default": 10000,
  "fetch_invites_persistent_default": 6000,
  "fetch_players_default": 35000,
  "fetch_invites_timeout": 600000,
}

export const inactiveTime = 60000;
export const timeOutTime = secrets.WEB_SOCKET_TIMEOUT_TIME;

export const dateForHeaderChanges = '2022-08-30T07:51:29Z';

export const dateForActivityGamesChanges = '2022-09-22T07:51:29Z';
export const ActivityGamesClasses = [PRE_K_GRADE, K_GRADE, FIRST_GRADE];