import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { assetPath, deleteItemFromLocalStorage, getQueryParamsObj, saveHeaderVariationInStorage, setShowPopupInStorage} from '../../utils/web-helper';
import { white } from '../../components/loggedout/shared-styles/Colors';
import { SectionTitle } from '../../components/loggedout/shared-styles/SectionTitle';
import {
  breakpoints
} from '../../utils/responsive_utilities';
import ModularSection0 from '../../components/loggedout/home/Modular/Section0/index';
import ModularSection1 from '../../components/loggedout/home/Modular/Section1/index';
import ModularSection2 from '../../components/loggedout/home/Modular/Section2/index';
import ModularSection3 from '../../components/loggedout/home/Modular/Section3/index';
import ModularSectionProgressReport from '../../components/loggedout/home/Modular/SectionProgressReport/index';
import ModularCurve from '~images/loggedout/home/modularPage/modular_curve.png';
import ModularCurveLg from '~images/loggedout/home/modularPage/modular_curve2.png';
import ModularCurveXl from '~images/loggedout/home/modularPage/modular_curve3.png';
import PinkEllipseShape from '~images/loggedout/home/modularPage/modularpink_curve.svg'
import UniverseLearning from '../../components/loggedout/home/UniverseLearning/index';
import TrySplashlearn from '../../components/loggedout/home/TrySplashlearn/index';
import { trySplashContentHome } from '../../components/loggedout/home/TrySplashlearn/data';
import WhiteCurveImage from '~images/loggedout/home/modularPage/white_curve.png';
import { DesktopVideoHead} from '../../components/loggedout/home/HomepageVideoVariant/videoHead';
import '../../../css/dist/loggedout-home-page.scss';
import ErrorBoundary from '../../utils/error-boundary';
import { TypesOfModes } from '../../components/gamification/header-banner/typeOfModes';
import ModularSectionFAQs from '../../components/loggedout/home/Modular/SectionFAQs/index'

const Home = ({ abTestsDetails, appDownloadCTA }) => {

  const onPlayClickHandler = () => {
    trackEventOnGA('clicked video', 'homepage');
    setVideoPlayer(true);
  }

  useEffect(() => {

    //Is more than half of the element(40 million stats) visible ?
    let $elem = document.querySelector(".learners-30m");
    let isStatsVisible = $elem && $elem.getBoundingClientRect() && ($elem.getBoundingClientRect().top + ($elem.offsetHeight / 2 ) + 1 <= window.innerHeight);

    if(isStatsVisible){
      typeof trackEventOnGA !== "undefined" && trackEventOnGA("viewed proof point",JSON.stringify(
        {
          'eventAction': '40 million learners' ,
          'nonInteraction': true
        }
      ))
    }
    checkTabRedirection();

    // deleting this key when user lands on homepage after signout
    deleteItemFromLocalStorage('isLogin');
  }, []);

    // Check tab redirection on the basis of query params

    const checkTabRedirection = () => {
      const tab = getQueryParamsObj(window.location.href).tabContext;
      const show_popup = getQueryParamsObj(window.location.href).show_popup;
      if(tab === 'live_class'){
        saveHeaderVariationInStorage(TypesOfModes['LiveClass']);
        sessionStorage.setItem('activeTabCode', 'live_class');
        setShowPopupInStorage(show_popup);
      }
    }

  return (

    <div className="video-variant-overflow-hidden">
      <div className="loggedout-home-top-section">
        {
          <DesktopVideoHead
            onPlayClickHandler={onPlayClickHandler}
            abTestsDetails={abTestsDetails}
          />

        }
      </div>
      <>
        <TopCurveBorderWrap className="no-margin-top">
          <ModularSection0 />
        </TopCurveBorderWrap>
        <UniverseSection>
          <SectionTitle className="loggedout-home-universesection-padding-top">Unlimited fun. Unlimited learning.</SectionTitle>
          <p>Dive into the Splashverse - a magical place where learning happens through stories and games.</p>
          <UniverseLearning abTestsDetails={abTestsDetails} />
        </UniverseSection>
        <CurveBordersWrap className="no-margin-top">
          <ModularSection1 />
        </CurveBordersWrap>
        <ModularCurveBordersWrap>
        <ModularSection2 />
        </ModularCurveBordersWrap>

        <CurveBordersWrap showPinkCurve={true}>
          <ModularSection3 />
        </CurveBordersWrap>
        <ModularSectionProgressReport />
        <ModularSectionFAQs />
      </>

      <ErrorBoundary>
        <TrySplashlearn
          eventProps={{
            'From Page': 'Home Page',
            'For Account Type': 'Parent',
          }}
          data={trySplashContentHome}
          abTestsDetails={abTestsDetails}
          appDownloadCTA={appDownloadCTA}
        />
      </ErrorBoundary>

    </div>
  );
};
const ModularCurveBordersWrap=styled.div`
@media (max-width:${breakpoints.md}px){


  position: relative;
  z-index: 1;
  padding:12rem 0 !important;
  background-color: ${white};

&:before {
  content: '';
  background-image: url(${assetPath(PinkEllipseShape)});
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 12.0rem;
  left: 0;
  right: 0;
  top: 78px;
  transform: rotate(180deg);
  position: absolute;
  z-index: -1;
}
&:after {
  content: '';
  background-image: url(${assetPath(PinkEllipseShape)});
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 12.0rem;
  left: 0;
  right: 0;
  bottom: 78px;
  position: absolute;
  z-index: -1;
}

}
`;

const LearningAndTutoring = styled.div`
  background-color: ${white};
  padding: 6rem 0;
  @media (max-width: ${breakpoints.md}px) {
    padding:0;
  }
`;
const Testimonials = styled.div`
  background-color: ${white};
  padding: 4rem 0 14rem;
  @media (max-width: 1279px) {
    padding:0;
  }
`;
const UniverseSection = styled.div`
  text-align: center;
  background: ${white};
  padding-bottom: 12rem;

  > p {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.8rem;
    text-align: center;
    margin-top: 2rem;
  }
  /* @media screen and (max-width: 1024px) {
    background: #EDEDF9;
    padding-bottom: 0;
  } */
  @media (max-width: ${breakpoints.md}px) {
    padding:12.5rem 1.7rem 0 1.7rem;
    background:${white};
    > p {
      font-size:1.6rem;
      line-height:2.4rem;
      padding:0 1.7rem 0 1.8rem;
    }
    .loggedout-home-universesection-padding-top{
      font-size:2.8rem;
      line-height:3.4rem;
      font-weight:700;
    }
  }

  @media screen and (min-width:${breakpoints.md}px) and (max-width:1279px){
    padding:10rem 2.5rem 0 2.5rem;
    .loggedout-home-universesection-padding-top{
      font-size:3rem;
      line-height:4rem;
      font-weight:700;
    }
    > p{
      font-size:1.6rem;
      line-height:2.4rem;
      font-weight:400;
    }
  }

`;

// Modular verient home page Styles

const CurveBordersWrap = styled.div`
${(props)=>{
  return props.showPinkCurve ?
  css`
  position: relative;
 z-index: 1;
 background-color: ${white};
 margin-top: 12rem;
 &:after {
   content: '';
   background-image: url(${assetPath(WhiteCurveImage)});
   background-size: 100% auto;
   background-repeat: no-repeat;
   background-position: center top;
   height: 12.0rem;
   left: 0;
   right: 0;
   bottom: 100%;
   position: absolute;
   z-index: -1;
 }
 &:before {
   content: '';
   background-image: url(${assetPath(WhiteCurveImage)});
   background-size: 100% auto;
   background-repeat: no-repeat;
   background-position: center bottom;
   height: 12.0rem;
   left: 0;
   right: 0;
   top: 100%;
   position: absolute;
   z-index: -1;
 }
 &+div
 {
   padding-top: 12.0rem;
 }
 @media (max-width: ${breakpoints.md}px) {
   margin-top:0;
   &:before{
     display:none;
   }
   &:after{
     display:none;
   }
   &+div{
     padding-top: 0;
   }
 }
 @media screen  and (min-width:${breakpoints.md+1}px) and (max-width:1279px){
   &:after{
    content: '';
   background-image: url(${assetPath(WhiteCurveImage)});
   background-size: 100% auto;
   background-repeat: no-repeat;
   background-position: center top;
   height: 12.0rem;
   left: 0;
   right: 0;
   bottom: 100%;
   position: absolute;
   z-index: -1;
   }
 }
 &.no-margin-top {
   margin-top: 0;
 }
  `
  :
  css`
 position: relative;
 z-index: 1;
 background-color: ${white};
 margin-top: 12rem;
 &:after {
   content: '';
   background-image: url(${assetPath(WhiteCurveImage)});
   background-size: 100% auto;
   background-repeat: no-repeat;
   background-position: center top;
   height: 12.0rem;
   left: 0;
   right: 0;
   bottom: 100%;
   position: absolute;
   z-index: -1;
 }
 &:before {
   content: '';
   background-image: url(${assetPath(WhiteCurveImage)});
   background-size: 100% auto;
   background-repeat: no-repeat;
   background-position: center bottom;
   height: 12.0rem;
   left: 0;
   right: 0;
   top: 100%;
   position: absolute;
   z-index: -1;
 }
 &+div
 {
   padding-top: 12.0rem;
 }
 @media (max-width: ${breakpoints.md}px) {
   margin-top:0;
   &:before{
     display:none;
   }
   &:after{
     display:none;
   }
   &+div{
     padding-top: 0;
   }
 }
 @media screen  and (min-width:${breakpoints.md+1}px) and (max-width:1279px){
   &:after{
     display: none;
   }
 }
 &.no-margin-top {
   margin-top: 0;
 }
 `;
}}
`;

const TopCurveBorderWrap = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${white};
  margin-top: 12rem;
  &:after {
    content: '';
    background-image: url(${assetPath(WhiteCurveImage)});
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center top;
    height: 12.0rem;
    left: 0;
    right: 0;
    bottom: 100%;
    position: absolute;
    z-index: -1;
  }
  &.no-margin-top {
    margin-top: 0;
  }
  @media (max-width: ${breakpoints.sm}px) {
    &:after{
      bottom:90%;
      background-image:url(${assetPath(ModularCurve)});
      z-index:1;
      height:8.5rem;
  }
}
@media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md}px) {
  &:after{
    bottom:90%;
    background-image:url(${assetPath(ModularCurveLg)});
    z-index:1;
    height:8.5rem;
  }
}
@media (min-width:${breakpoints.md}px ) and (max-width:1023px) {
  &:after{
    bottom:88%;
    background-image:url(${assetPath(ModularCurveLg)});
  }
}
@media (min-width:1023px) and (max-width:1279px){
  &:after{
    bottom:80%;
    background-image:url(${assetPath(ModularCurveXl)});

  }
}
`;

export default Home;
