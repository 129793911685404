import React from 'react';
import { createStore } from "redux";
import { connect } from "react-redux";
import { checkIfMFIsEnabledForGrade } from '../utils/web-helper';
import { mapByCode } from '../utils/content-mapper';

const DefaultSubject = 'math';

// Actions
const INITIALIZE = "INITIALIZE";
const SET_ACTIVE_STUDENT = "SET_ACTIVE_STUDENT";
const RELOAD_CALLBACK = "RELOAD_CALLBACK";
const ENABLE_PASSCODE = "ENABLE_PASSCODE";
const DISABLE_PASSCODE = "DISABLE_PASSCODE";
const SET_ACTIVE_COURSE = "SET_ACTIVE_COURSE";
const MF_WHATS_NEW_DISMISS = 'MF_WHATS_NEW_DISMISS';
const UPDATE_STUDENT_LIST = "UPDATE_STUDENT_LIST";
const TRIGGER_TOASTER = "TRIGGER_TOASTER";
const SET_WEEKLY_GOAL_DATA = "SET_WEEKLY_GOAL_DATA";
const SET_MF_STATUS_BY_GRADE = 'SET_MF_STATUS_BY_GRADE';
const SET_SUBSCRIPTION_KEYS = 'SET_SUBSCRIPTION_KEYS';
const REVERT_DASHBOARD_NOTIFICATION_SHOWN = 'REVERT_DASHBOARD_NOTIFICATION_SHOWN';
const SET_HAS_ELA_ACCESS = 'SET_HAS_ELA_ACCESS';
const SET_AB_TESTS = 'SET_AB_TESTS';
const MATH_FACT_NOTIFICATION_DOT_SHOWN = 'MATH_FACT_NOTIFICATION_DOT_SHOWN';
const SET_APP_THEME = 'SET_APP_THEME';
const SET_LIVE_TUTORING = 'SET_LIVE_TUTORING';
const SHOW_C1_PITSTOP = 'SHOW_C1_PITSTOP';
const SET_VIA_CLASS_REFERRAL = 'SET_VIA_CLASS_REFERRAL';
const SET_STUDENT = 'SET_STUDENT';
const SET_MODIFIED_STUDENTS = 'SET_MODIFIED_STUDENTS';

// Initial State
const initialState = {
  settings: {},
  currentUser: {},
  student: {},
  students: [],
  studentCourses: [],
  studentCourseContentGroups: {},
  addedStudentContentGroups: {},
  currentCourse: {},
  grades: [],
  showPasscode: false,
  avatars: [],
  reloadStudent: false,
  isClassroomLinked:false,
  gradeCode: '',
  existingUserPtlFlow: false,
  isFinalRenderDone: false,
  currentSessionId: '',
  whatsNewMFDismissed: false,
  weeklySchedule: null,
  showToaster: false || window.localStorage.getItem("toasterTrigger"),
  newMathFactEnabled: false,
  subscriptionKeys: {},
  revertDashboardNotificationShow: true,
  hasElaAccess: false,
  abTests: {},
  appTheme: null,
  liveTutoring: false,
  c1PitstopOptions : {},
  viaClassReferral: false,
  klassCode: '',
}

// Root Reducer
export const rootReducer = (state = initialState, action) => {
  var payload = action.payload;
  switch (action.type) {
    case INITIALIZE:
      localStorage.setItem('skipSignUp', payload.settings.skipSignUp);
      return {
        ...state,
        settings: payload.settings,
        currentUser: payload.current_user,
        student: payload.student,
        students: payload.students,
        grades: payload.grades,
        gradesByCode: mapByCode(payload.grades),
        gradesById: payload.grades_by_id,
        reloadStudent: true,
        isClassroomLinked:payload.is_classroom_linked,
        gradeCode: payload.grade_code,
        existingUserPtlFlow: payload.existing_user_ptl_flow,
        analyticsSettings: payload.analytics_settings,
        currentSessionId: payload.session_id,
        weeklySchedule: payload.weekly_schedule,
        klassCode: payload.klass_code,
      }
    case SET_ACTIVE_STUDENT:
      return {
        ...state,
        student: payload.student,
        reloadStudent: true
      }
    case UPDATE_STUDENT_LIST:
      return {
        ...state,
        students: payload.updatedStudentList
      }
    case SET_ACTIVE_COURSE:
      let subjectCode = payload.subject_code
      let reloadStudent = payload.reloadStudent
      return {
        ...state,
        settings: {...state.settings, subject_code: subjectCode},
        reloadStudent: reloadStudent,
        isFinalRenderDone: false
      }
    case RELOAD_CALLBACK:
      return {
        ...state,
        reloadStudent: false,
        isFinalRenderDone: true
      }
    case ENABLE_PASSCODE:
      return {...state, showPasscode: true}
    case DISABLE_PASSCODE:
      return {...state, showPasscode: false}
    case MF_WHATS_NEW_DISMISS:
      return {...state, whatsNewMFDismissed: true}
    case TRIGGER_TOASTER:
      return { ...state, showToaster: payload}
    case SET_WEEKLY_GOAL_DATA:
      return { ...state, weeklySchedule: payload }
    case SET_MF_STATUS_BY_GRADE: {
      SPWidget.currentSubject = (payload && payload.subject_code) || DefaultSubject;
      let gradeOfActiveStudent = state.gradeCode || payload && payload.grade_code;
      const newMathFactEnabled = checkIfMFIsEnabledForGrade(gradeOfActiveStudent, state.settings.newMathFactsActiveGrades, state.gradesByCode);
      return {
        ...state,
        currentCourse: payload,
        newMathFactEnabled
      }
    }
    case SET_SUBSCRIPTION_KEYS: {
      return {
        ...state,
        subscriptionKeys: payload,
      }
    }
    case REVERT_DASHBOARD_NOTIFICATION_SHOWN: {
      return {
        ...state,
        revertDashboardNotificationShow: false,
      }
    }
    case SET_HAS_ELA_ACCESS: {
      return {
        ...state,
        hasElaAccess: payload
      }
    }
    case SET_AB_TESTS: {
      return {
        ...state,
        abTests: payload
      }
    }
    case MATH_FACT_NOTIFICATION_DOT_SHOWN: {
      const settings = {...state.settings, showMathFactNotificationDot: false};
      return {
        ...state,
        settings
      }
    }
    case SET_APP_THEME: {
      return {
        ...state,
        appTheme: payload
      }
    }
    case SET_LIVE_TUTORING: {
      return {
        ...state,
        liveTutoring: payload
      }
    }
    case SHOW_C1_PITSTOP: {
      return {
        ...state,
        c1PitstopOptions: payload
      }
    }
    //To differentiate between PTL Signup through common link and non common link 
    case SET_VIA_CLASS_REFERRAL: {
      return { 
        ...state, 
        viaClassReferral: action.payload 
      };
    }
    case SET_STUDENT: {
      return { 
        ...state, 
        student: action.payload,
      };
    }
    case SET_MODIFIED_STUDENTS: {
      return { 
        ...state, 
        students: action.payload,
      };
    }
    
    default:
      return state;
  }
};


export const Store = createStore(rootReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default function ConnectWithStore(mapStateToProps, dispatchStateToProps, component){
  var ConnectedComponent = connect(mapStateToProps, dispatchStateToProps)(component);
  return function(props){
    return <ConnectedComponent {...props} store={Store}/>
  }
}
