import { PRINTABLES } from "../constant-data/constants";
import { hasAccessToFlutterDRS, isDRSWithCategoryExpansion } from "../utils/feature_mapping";
import { getDeviceType, isMultiplayerGamesLockedAbTestAssignedToTeacher, fetchFreeWsDownloadCount, getDefaultTabPrintablesFromStorage, removeDefaultTabPrintablesFromStorage, getMetaSessionUserId, sendErrorToSentry, getItemFromLocalStorage, setItemInLocalStorage } from "../utils/web-helper";
import { FLUTTER_SETTINGS } from "./constants";
var PlatformSettings = /** @class */ (function () {
    //Singleton class
    function PlatformSettings() {
        this.platform = getDeviceType();
        var tokenElement = document.querySelector('[name=csrf-token]');
        var csrf_token = tokenElement ? tokenElement.getAttribute('content') : '';
        var Store = require('../stores/student-dashboard').Store;
        var _a = Store.getState(), settings = _a.settings, current_course = _a.current_course;
        var wsCountLeft = fetchFreeWsDownloadCount();
        var isGameLocked = (settings && settings.is_classroom_student && settings.restriction_info.selected_mode === 'school') && ((isMultiplayerGamesLockedAbTestAssignedToTeacher().teacherMultiplayerGamesLockedAbTestAssignedSegment) || isDRSWithCategoryExpansion());
        var currentOfferingCode = settings === null || settings === void 0 ? void 0 : settings.current_tiered_offering;
        var setDefaultPrintables = getDefaultTabPrintablesFromStorage();
        var b2bMode = settings.is_classroom_student ? settings.restriction_info.selected_mode : '';
        var skipCoqSurvey = getItemFromLocalStorage('skip_coq_survey_call') || false;
        var metaUserSessionId = getMetaSessionUserId();
        var viaTeacherStudentCenter = (settings === null || settings === void 0 ? void 0 : settings.via_teacher_student_center) || false;
        var settingsSignupFlow = (settings === null || settings === void 0 ? void 0 : settings.user_signup_flow) || '';
        var current_subject_code = (current_course === null || current_course === void 0 ? void 0 : current_course.subject_code) ? current_course.subject_code : 'math';
        var show3PMPopup = (settings && settings.is_classroom_student && settings.restriction_info.show_3pm_popup) || false;
        if (!metaUserSessionId) {
            var errorInfo = {
                extraMsg: 'MetaUserSessionId not found',
            };
            sendErrorToSentry(new Error("MetaUserSessionId not found"), errorInfo);
        }
        // Init flutter settings
        if (!window[FLUTTER_SETTINGS]) {
            window[FLUTTER_SETTINGS] = {
                platformSettings: {
                    platform: this.platform,
                    csrf_token: csrf_token,
                    access_to_flutter_drs: "".concat(hasAccessToFlutterDRS()),
                    multiplayer_games_locked: "".concat(isGameLocked),
                    current_tiered_offering: currentOfferingCode,
                    ws_download_count: wsCountLeft,
                    b2b_mode: b2bMode,
                    skip_coq_survey_call: skipCoqSurvey,
                    meta_user_session_id: metaUserSessionId,
                    via_teacher_student_center: viaTeacherStudentCenter,
                    settings_signup_flow: settingsSignupFlow,
                    current_subject_code: current_subject_code,
                    show_3pm_popup: show3PMPopup
                },
            };
        }
        else {
            window.flutterSetting = {
                platformSettings: {
                    platform: this.platform,
                    csrf_token: csrf_token,
                    access_to_flutter_drs: "".concat(hasAccessToFlutterDRS()),
                    multiplayer_games_locked: "".concat(isGameLocked),
                    current_tiered_offering: currentOfferingCode,
                    ws_download_count: wsCountLeft,
                    b2b_mode: b2bMode,
                    skip_coq_survey_call: skipCoqSurvey,
                    meta_user_session_id: metaUserSessionId,
                    via_teacher_student_center: viaTeacherStudentCenter,
                    settings_signup_flow: settingsSignupFlow,
                    current_subject_code: current_subject_code,
                    show_3pm_popup: show3PMPopup
                },
            };
        }
        if (setDefaultPrintables) {
            this.setDefaultTabPrintables();
        }
        if (!PlatformSettings._instance) {
            PlatformSettings._instance = this;
        }
        return PlatformSettings._instance;
    }
    PlatformSettings.prototype.loadTab = function (tabName) {
        if (window.flutterSetting && window.flutterSetting.platformSettings) {
            window.flutterSetting.platformSettings.tab_code = tabName;
        }
    };
    // resets tab_code field
    PlatformSettings.prototype.resetTabCode = function () {
        if (window.flutterSetting && window.flutterSetting.platformSettings) {
            window.flutterSetting.platformSettings.tab_code = "";
        }
    };
    // Sets default tab to be opened in Flutter Dashboard container
    PlatformSettings.prototype.setDefaultTab = function (tabName) {
        if (window.flutterSetting && window.flutterSetting.platformSettings) {
            window.flutterSetting.platformSettings.default_tab_code = tabName;
        }
    };
    // Sets the value to open pop up
    PlatformSettings.prototype.setShowPopupValue = function (value) {
        if (window.flutterSetting && window.flutterSetting.platformSettings) {
            window.flutterSetting.platformSettings.show_popup = value !== null && value !== void 0 ? value : '';
        }
    };
    // sets default tab code to Printables if signed up from WS
    PlatformSettings.prototype.setDefaultTabPrintables = function () {
        if (window.flutterSetting && window.flutterSetting.platformSettings) {
            window.flutterSetting.platformSettings.default_tab_code = PRINTABLES;
            removeDefaultTabPrintablesFromStorage();
        }
    };
    // Sets the value true when coq survey answers api call has to be skipped
    PlatformSettings.prototype.skipCoqSurveyCallApi = function (value) {
        if (window.flutterSetting && window.flutterSetting.platformSettings && !getItemFromLocalStorage('skip_coq_survey_call')) {
            window.flutterSetting.platformSettings.skip_coq_survey_call = value !== null && value !== void 0 ? value : false;
            setItemInLocalStorage('skip_coq_survey_call', true);
        }
    };
    /// Sets is it a login
    PlatformSettings.prototype.setIsLogin = function (value) {
        if (window.flutterSetting && window.flutterSetting.platformSettings) {
            window.flutterSetting.platformSettings.is_login = value;
        }
    };
    PlatformSettings.prototype.updateSettings = function () {
        var _a, _b;
        var Store = require('../stores/student-dashboard').Store;
        var settings = Store.getState().settings;
        if (settings) {
            this.tieredOffering = settings.current_tiered_offering;
        }
        if ((_a = window.flutterSetting) === null || _a === void 0 ? void 0 : _a.platformSettings) {
            window.flutterSetting.platformSettings.meta_user_session_id = getMetaSessionUserId();
            window.flutterSetting.platformSettings.access_to_flutter_drs = hasAccessToFlutterDRS().toString();
            window.flutterSetting.platformSettings.via_teacher_student_center = (_b = settings === null || settings === void 0 ? void 0 : settings.via_teacher_student_center) !== null && _b !== void 0 ? _b : false;
            window.flutterSetting.platformSettings.current_tiered_offering = this.tieredOffering;
            window.flutterSetting.platformSettings.b2b_mode = settings.is_classroom_student ? settings.restriction_info.selected_mode : '';
            var isGameLocked = false;
            isGameLocked = (settings && settings.is_classroom_student && settings.restriction_info.selected_mode === 'school') && ((isMultiplayerGamesLockedAbTestAssignedToTeacher().teacherMultiplayerGamesLockedAbTestAssignedSegment) || isDRSWithCategoryExpansion());
            window.flutterSetting.platformSettings.multiplayer_games_locked = "".concat(isGameLocked);
        }
    };
    //resets default tab code
    PlatformSettings.prototype.resetDefaultTabCode = function () {
        if (window.flutterSetting && window.flutterSetting.platformSettings) {
            window.flutterSetting.platformSettings.default_tab_code = "";
        }
    };
    return PlatformSettings;
}());
export default PlatformSettings;
