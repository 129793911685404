export const HOME_PATH = '/';
export const EDUCATOR_PATH = '/features/teachers';
export const SCHOOL_ADMIN_INVITATION_RESPONSE_PREFIX_PATH = '/teachers/school_admin_invitation/:id';
export const PARENT_PATH = '/features/parents';
export const SUCCESS_STORIES_PATH = '/success-stories';
export const PRIVACY_PATH = '/privacy';
export const TERMS_OF_USE_PATH = '/terms-of-use';
export const EDUCATOR_MEMBERSHIP_PATH = '/educator-membership';
export const SIGNUP_PATH = '/register';
export const SIGNIN_PATH = '/signin';
export const STUDENT_PATH = '/student';
export const STUDENT_SIGNIN_PATH = '/students/signin';
export const PARENT_SIGNIN_PATH = '/parents/signin';
export const TEACHER_SIGNIN_PATH = '/teachers/signin';
export const FORGOT_PASSWORD_PATH = '/forgot_password';
export const TEACHER_PAGE_PATH = '/features/teachers';
export const ADMINISTRATORS_PAGE_PATH = '/administrators';
export const SPRINGBOARD_LANDING_PAGE_PATH = '/springboard/teachers';
export const HOW_TO_VIDEOS_PAGE_PATH = '/how-to-videos-for-teachers';
export const TEACHER_SUPPORT_PAGE_PATH = 'https://support.splashlearn.com/hc/en-us';
export const ONLINE_TUTORING_PAGE_PATH = '/tutoring/courses';
export const ONLINE_TUTORING_PAGE_PATH_V2 = '/tutoring';

export const CMS_ALL_GAMES = '/games';
export const CMS_MATH_GAMES = '/math-games';
export const CMS_ELA_GAMES  = '/ela-games';
export const CMS_ALL_WORKSHEETS  = '/worksheets';
export const CMS_MATH_WORKSHEETS = '/math-worksheets';
export const CMS_ELA_WORKSHEETS  = '/ela-worksheets';

export const NEW_ONLINE_TUTORING_PAGE_PATH = 'https://games.splashlearn.com/tutoring';

//external urls
export const PARENT_SIGNUP_PATH = false ? '/parents/register?select_account_type=1' : '/parents/register';

// Dashboard Routes
export const PARENT_DASHBOARD_BASE_PATH = '/parent-dashboard';

// Tutoring Parent Dashboard Routes
export const TUTORING_PARENT_DASHBOARD_PATH = '/parent-dashboard#/tutoring-dashboard';

// tutoring cms page
export const TUTORING_COURSES_LIST_PATH = '/tutoring/courses';

// signout Route
export const SIGNOUT_PATH = '/signout';
