export var footerUsData = [
    {
        mainLink: 'Explore Games',
        sublinks: [
            {
                title: 'Preschool Math Games',
                link: '/math-games-for-preschoolers',
            },
            {
                title: 'Grade K Math Games',
                link: '/math-games-for-kindergarteners',
            },
            {
                title: 'Grade 1 Math Games',
                link: '/math-games-for-1st-graders',
            },
            {
                title: 'Grade 2 Math Games',
                link: '/math-games-for-2nd-graders',
            },
            {
                title: 'Grade 3 Math Games',
                link: '/math-games-for-3rd-graders',
            },
            {
                title: 'Grade 4 Math Games',
                link: '/math-games-for-4th-graders',
            },
            {
                title: 'Grade 5 Math Games',
                link: '/math-games-for-5th-graders',
            },
            {
                title: 'Preschool English Games',
                link: '/ela-games-for-preschoolers',
            },
            {
                title: 'Grade K English Games',
                link: '/ela-games-for-kindergarteners',
            },
            {
                title: 'Grade 1 English Games',
                link: '/ela-games-for-1st-graders',
            },
            {
                title: 'Grade 2 English Games',
                link: '/ela-games-for-2nd-graders',
            },
            {
                title: 'Grade 3 English Games',
                link: '/ela-games-for-3rd-graders',
            },
            {
                title: 'Grade 4 English Games',
                link: '/ela-games-for-4th-graders',
            },
            {
                title: 'Grade 5 English Games',
                link: '/ela-games-for-5th-graders',
            },
        ],
    },
    {
        mainLink: 'Explore Worksheets',
        sublinks: [
            {
                title: 'Preschool Math Worksheets',
                link: '/math-worksheets-for-preschoolers',
            },
            {
                title: 'Grade K Math Worksheets',
                link: '/math-worksheets-for-kindergarteners',
            },
            {
                title: 'Grade 1 Math Worksheets',
                link: '/math-worksheets-for-1st-graders',
            },
            {
                title: 'Grade 2 Math Worksheets',
                link: '/math-worksheets-for-2nd-graders',
            },
            {
                title: 'Grade 3 Math Worksheets',
                link: '/math-worksheets-for-3rd-graders',
            },
            {
                title: 'Grade 4 Math Worksheets',
                link: '/math-worksheets-for-4th-graders',
            },
            {
                title: 'Grade 5 Math Worksheets',
                link: '/math-worksheets-for-5th-graders',
            },
            {
                title: 'Preschool English Worksheets',
                link: '/ela-worksheets-for-preschoolers',
            },
            {
                title: 'Grade K English Worksheets',
                link: '/ela-worksheets-for-kindergarteners',
            },
            {
                title: 'Grade 1 English Worksheets',
                link: '/ela-worksheets-for-1st-graders',
            },
            {
                title: 'Grade 2 English Worksheets',
                link: '/ela-worksheets-for-2nd-graders',
            },
            {
                title: 'Grade 3 English Worksheets',
                link: '/ela-worksheets-for-3rd-graders',
            },
            {
                title: 'Grade 4 English Worksheets',
                link: '/ela-worksheets-for-4th-graders',
            },
            {
                title: 'Grade 5 English Worksheets',
                link: '/ela-worksheets-for-5th-graders',
            },
        ],
    },
    {
        mainLink: 'Explore Lesson Plans',
        sublinks: [
            {
                title: 'Grade K Math Lesson Plans',
                link: '/math-lesson-plans-for-kindergarteners',
            },
            {
                title: 'Grade 1 Math Lesson Plans',
                link: '/math-lesson-plans-for-1st-graders',
            },
            {
                title: 'Grade 2 Math Lesson Plans',
                link: '/math-lesson-plans-for-2nd-graders',
            },
            {
                title: 'Grade 3 Math Lesson Plans',
                link: '/math-lesson-plans-for-3rd-graders',
            },
            {
                title: 'Grade 4 Math Lesson Plans',
                link: '/math-lesson-plans-for-4th-graders',
            },
            {
                title: 'Grade 5 Math Lesson Plans',
                link: '/math-lesson-plans-for-5th-graders',
            },
            {
                title: 'Grade K English Lesson Plans',
                link: '/ela-lesson-plans-for-kindergarteners',
            },
            {
                title: 'Grade 1 English Lesson Plans',
                link: '/ela-lesson-plans-for-1st-graders',
            },
            {
                title: 'Grade 2 English Lesson Plans',
                link: '/ela-lesson-plans-for-2nd-graders',
            },
            {
                title: 'Grade 3 English Lesson Plans',
                link: '/ela-lesson-plans-for-3rd-graders',
            },
            {
                title: 'Grade 4 English Lesson Plans',
                link: '/ela-lesson-plans-for-4th-graders',
            },
            {
                title: 'Grade 5 English Lesson Plans',
                link: '/ela-lesson-plans-for-5th-graders',
            },
        ],
    },
    {
        mainLink: 'SplashLearn Content',
        sublinks: [
            {
                title: 'For Parents',
                link: '/features/parents',
            },
            {
                title: 'For Classrooms',
                link: '/features/teachers',
            },
            {
                title: 'For HomeSchoolers',
                link: 'https://www.splashlearn.com/homeschool-program',
            },
            {
                title: 'Blog',
                link: 'https://www.splashlearn.com/blog/',
            },
            {
                title: 'About Us',
                link: 'https://www.splashlearn.com/about',
            },
            {
                title: 'Careers',
                link: 'https://www.splashlearn.com/careers/',
            },
            {
                title: 'Success Stories',
                link: 'https://www.splashlearn.com/success-stories',
            },
        ],
    },
    {
        mainLink: 'Help & Support',
        sublinks: [
            {
                title: 'Parents',
                link: 'https://support.splashlearn.com/hc/en-us/categories/12271293255570-I-am-a-Parent',
            },
            {
                title: 'Teachers',
                link: 'https://support.splashlearn.com/hc/en-us/categories/12271297576722-I-am-a-Teacher',
            },
            {
                title: 'Download SplashLearn',
                link: 'https://www.splashlearn.com/apps',
            },
            {
                title: 'Contact Us',
                link: 'https://www.splashlearn.com/about/contact-us',
            },
            {
                title: 'Gift SplashLearn',
                link: 'https://crmlp.splashlearn.com/gift',
            },
        ],
    },
];
