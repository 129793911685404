import { convertToUUIDFormat, sendErrorToSentry, toSnakeCase } from '../utils/web-helper';
import { queryCourses, queryStudentAssignmentPlayData, queryStudentAssignments } from './graphql-mytodo-apis';

export function GetStudentTasksFromGraphql(params) {
    return new Promise((resolve, reject) => {
        queryStudentAssignments({ "studentId": convertToUUIDFormat(params?.student_id) }).then((res) => {
            const studentAssignments = res?.studentAssignments || {};
            const userLoTaskIds = (studentAssignments?.studentTasks || []).map((item) => item?.userLoTaskId);
            queryStudentAssignmentPlayData({ "studentId": convertToUUIDFormat(params?.student_id), "userLoTaskIds": userLoTaskIds }).then((playDataRes) => {
                const studentAssignmentPlayData = playDataRes?.studentAssignmentPlayData || {};
                const userLpStatuses = studentAssignmentPlayData?.userLpStatuses || [];
                const userPlayableAttempts = studentAssignmentPlayData?.userPlayableAttempts || [];
                const userLoTasks = playDataRes?.userLoTasks || [];
                const learningObjectives = playDataRes?.learningObjectives || [];
                const courseIds = learningObjectives?.map((item) => item?.courseId);
                const playables = playDataRes?.playables || [];

                queryCourses({ courseIds }).then((courseData) => {
                    const milestones = {};
                    const studentAssignmentData = (studentAssignments?.studentTasks || []).map((studentAssignment) => {
                        const task = studentAssignments?.tasks?.find((a) => a.id === studentAssignment?.taskId) || {};
                        const taskGrouping = studentAssignments?.taskGroupings?.find((a) => a.taskId === task?.id) || {};
                        const taskGroup = studentAssignments?.taskGroups?.find((a) => a.id === taskGrouping?.taskGroupId) || {};
                        const userLoTaskData = userLoTasks.find((item) => item.id === studentAssignment.userLoTaskId);
                        const learningObjective = learningObjectives?.find((lo) => lo.id === userLoTaskData?.learningObjectiveId);
                        if (taskGroup?.groupableId && learningObjective?.milestone?.id) {
                            milestones[learningObjective?.milestone?.id] = learningObjective?.milestone;
                        }
                        const course = courseData?.courses?.find((item) => item.id === learningObjective?.courseId)
                        const taskPlaybles = playables?.filter((a) => a.learningObjectiveId === userLoTaskData?.learningObjectiveId);
                        const taskPlayableAttempts = taskPlaybles.map((item) => {
                            const attempt = userPlayableAttempts?.filter((a) => (a.playableId === item.id) && ((a?.userLoTaskIds || [])?.includes(studentAssignment?.userLoTaskId)))?.sort((a, b) => new Date(a?.completedAt) - new Date(b?.completedAt))?.pop();
                            return {
                                ...item,
                                attempt
                            }
                        }).sort((a, b) => a?.ordering - b?.ordering);
                        if (!(userLoTaskData && learningObjective && course)) {
                            sendErrorToSentry(new Error("GetStudentTasksFromGraphql"), { studentAssignment, userLoTaskData });
                            return null;
                        }
                        return {
                            "learning_objective_id": userLoTaskData?.learningObjectiveId,
                            "learning_objective_code": learningObjective?.code,
                            "learning_objective_title": learningObjective?.title,
                            "content_group_code": learningObjective?.contentGroup?.code,
                            "milestone_code": learningObjective?.milestone?.code,
                            "playables": toSnakeCase(taskPlayableAttempts || []),
                            "attempt_count": taskPlayableAttempts?.filter(a => a?.attempt)?.length || 0,
                            "user_lo_task_id": studentAssignment.userLoTaskId,
                            "task_id": studentAssignment.taskId,
                            "course_code": course?.code,
                            "ordering": learningObjective?.ordering,
                            "thumbnail_code": learningObjective?.thumbnailCode,
                            "groupable_type": taskGroup?.groupableType,
                            "groupable_id": taskGroup?.groupableId,
                            "task_group_id": taskGroup?.id,
                            "created_at": studentAssignment?.createdAt,
                            "updated_at": studentAssignment?.updatedAt
                        }
                    });

                    const groupables = studentAssignments?.taskGroups?.map((taskGroup) => {
                        const milestone = milestones?.[taskGroup?.groupableId] || {};
                        return {
                            ...milestone,
                            task_group_id: taskGroup?.id,
                            created_at: taskGroup?.createdAt
                        }
                    })

                    const response = {
                        assignment_ever_assigned: studentAssignments?.assignmentEverAssigned,
                        groupables: toSnakeCase(groupables || []),
                        student_assignments: (studentAssignmentData || []).filter(a => a)
                    };
                    resolve(response);
                })
            })
        }).catch((error) => {
             // @ts-ignore global function
             trackAnalyticsEvent('Error Occured!', {
                'methodName': 'GetStudentTasksFromGraphql',
                'from': window.location.pathname,
                'hash': window.location.hash,
                'error': error?.message,
                'stack': error?.stack,
                params: params,
            });
            reject(error);
        })
    })
}
