import styled from 'styled-components';
import {getWidthRange, breakpoints} from '../../../utils/responsive_utilities';

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  & > a:nth-child(2) {
    margin: 0 0 0 80px;
  }
  @media ${getWidthRange(0, breakpoints.lg)} {
    flex-direction: column;
    align-items: center;
    & > a:nth-child(2) {
      margin: 40px 0 0 0;
    }
  }
`