import summerAnimationGameZone from '../../images/summer-campaign/game_zone_anim.json';
import summerAnimationLiveClass from '../../images/summer-campaign/live_class_anim.json';
import { dateBetweenTwoDates, isAUCountry } from '../utils/web-helper';
import { GAME_ZONE, LIVE_CLASS } from "./constants";
export var Campaigns;
(function (Campaigns) {
    Campaigns["summerSpecial"] = "summer-special";
    Campaigns["christmas"] = "christmas";
})(Campaigns || (Campaigns = {}));
var campaignsInfo = [
    {
        name: Campaigns.summerSpecial,
        startDate: new Date('2023/05/01'),
        endDate: new Date('2023/08/31'),
    },
    {
        name: Campaigns.christmas,
        startDate: new Date('2022/10/20'),
        endDate: new Date('2022/12/25'),
    }
];
export var getSummerCampaignAnimMapping = function (code) {
    if (code == GAME_ZONE) {
        return summerAnimationGameZone;
    }
    else if (code == LIVE_CLASS) {
        return summerAnimationLiveClass;
    }
    else {
        return '';
    }
};
export var isSummerCampaignEnabled = function () {
    var summerCampaign = campaignsInfo.find(function (item) { return item.name == Campaigns.summerSpecial; });
    if (summerCampaign && dateBetweenTwoDates(summerCampaign.startDate, summerCampaign.endDate) && !isAUCountry()) {
        return true;
    }
    return false;
};
export var getCurrentCampaign = function () {
    for (var i = 0; i < campaignsInfo.length; i++) {
        var campaign = campaignsInfo[i];
        if (dateBetweenTwoDates(campaign.startDate, campaign.endDate)) {
            return campaign.name;
        }
    }
    return null;
};
