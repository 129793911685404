import React, {useRef} from 'react';
import * as S from './style';
import useClickOutside from '../../custom-hooks/useClickOutside';
import Image from '../Image';
import CrossIcon from '~images/loggedout/icons/cross.png';

const Popup = (props) => {
  const boxRef = useRef();
  useClickOutside(boxRef, props.onClose);
  return (
    <S.PopupOverlay>
      <S.PopupBox ref={boxRef} minHeight={props.minHeight || 500}>
        {props.children}
        <Image src={CrossIcon} alt='cross_icon' className="cross-icon" onClick={props.onClose}/>
      </S.PopupBox>
    </S.PopupOverlay>
  )
}

export default Popup;