import React from 'react';
import styled from 'styled-components';
import Image from '../../components/loggedout/common/Image';
import ThankYouImage from '../../../images/loggedout/thank-you-page/thank-you-image.png'

const ThankYouPage = () => {
  return (
    <ThankYouBody>
      <Image className= "thank-you"
        src={ThankYouImage}
      ></Image>
    </ThankYouBody>
  )
}

const ThankYouBody = styled.div`
  margin: 10% 0;
`

export default ThankYouPage