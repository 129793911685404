import React from 'react';
import {Route} from 'react-router-dom';

class CPCRoute extends Route {
  constructor(props){
    if(props.path){
      props.path = "/(cpc)?" + props.path;
    }
    super(props);
  }
}
export default CPCRoute;