import React from 'react';
import styled from 'styled-components';
import Grants from '../../components/loggedout/educator-membership/Grants';

const EducatorMembership = () => {
  return (
    <>
      <GrantsSection>
        <Grants/>
      </GrantsSection>
    </>
  )
}

const GrantsSection = styled.div`
  margin-top: 14rem;
`
export default EducatorMembership