import i18n from "../utils/i18n";
const isAUOrUK = ['en-GB', 'en-AU'].includes(i18n.language);

const HomepageFaqs = [
  {
    "questionText": "What is SplashLearn?",
    "answerText": `SplashLearn is a game-based and curriculum-aligned learning experience for ${i18n.t('loggedout_homepage.math')} and ${i18n.t('loggedout_homepage.English')}. It offers over 8,000 learning games, activities and printable worksheets for ${i18n.t('loggedout_homepage.faq_a1_grade_range')}. ${isAUOrUK ? '' : `SplashLearn also offers live classes for both ${i18n.t('loggedout_homepage.math')} and ${i18n.t('loggedout_homepage.English')} by experienced and certified teachers.`}`
  },
  {
    "questionText": "What ages is SplashLearn for?",
    "answerText": `SplashLearn works great for ${i18n.t('loggedout_homepage.faq_a2_children_of_ages')} 2 to 11. It is also a great tool for older children with special needs who may want to catch up to ${i18n.t('loggedout_homepage.faq_a2_grade_level_and_master_basics')} in ${i18n.t('loggedout_homepage.math')} and ${i18n.t('loggedout_homepage.English')}.`
  },
  {
    "questionText": "What grades are covered by SplashLearn?",
    "answerText": `SplashLearn covers both ${i18n.t('loggedout_homepage.math')} and ${i18n.t('loggedout_homepage.English')} for ${i18n.t('loggedout_homepage.grade_range')}.`,
  },
  {
    "questionText": "What subjects does SplashLearn cover?",
    "answerText": `SplashLearn offers both ${i18n.t('loggedout_homepage.math')} and ${i18n.t('loggedout_homepage.English')}. ${i18n.t('loggedout_homepage.faq_a4_line_2')} Besides, you can find many more fun games that help build critical thinking, problem solving, social-emotional, and fine motor skills in toddlers.`

  },
  {
    "questionText": "How to get started with SplashLearn?",
    "answerText": "If you’re a parent and want to use SplashLearn for your children at home, all you need to do is visit <a href='https://www.splashlearn.com'>splashlearn.com</a> or download the SplashLearn iOS and Android app. You can then sign up and start your 7-day free trial to access the complete curriculum library for free up to 7 days. After the trial period, you can choose to subscribe to any of its premium plans. For teachers, SplashLearn is free to use to in schools. Just sign up as a teacher with your school email address, create a class and add students. Your students can learn and play on SplashLearn for free in class."
  },
  {
    "questionText": "How does SplashLearn work?",
    "answerText": `SplashLearn is a subscription-based ${i18n.t('loggedout_homepage.math')} and ${i18n.t('loggedout_homepage.English')} program for ${i18n.t('loggedout_homepage.grade_range')}. You can sign up and start a 7-day free trial to experience the ${i18n.t('loggedout_homepage.program')}. For teachers, SplashLearn is 100% free to use in class.`
  },
  {
    "questionText": "How much does SplashLearn cost?",
    "answerText": `For families, SplashLearn offers a free 7-day trial${isAUOrUK ? '. You can then choose' : ' post which parents can choose'} from its different pricing plans (starting ${i18n.t('loggedout_homepage.faq_a7_price')} when billed annually) that give access to the complete curriculum library across ${i18n.t('loggedout_homepage.grade_range')} ${isAUOrUK ? '' : `and free access to its premium Live Classes`}. For teachers, SplashLearn is 100% free to use in school. Any practice assigned by teachers as homework is also free for students to access at home.`
  },
  {
    "questionText": "What skills will my child learn with SplashLearn?",
    "answerText": `${i18n.t('loggedout_homepage.faq_a8')}`
  },
  {
    "questionText": "Is SplashLearn completely free?",
    "answerText": `SplashLearn is completely free for teachers. Students can practice over 4,000 curriculum-aligned ${i18n.t('loggedout_homepage.math')} and ${i18n.t('loggedout_homepage.English')} skills for free in school and all teacher-assigned activities for free at home. For families, SplashLearn offers a free 7-day trial.`
  },
  {
    "questionText": "I’m an admin, how can I get SplashLearn for my school?",
    "answerText": "SplashLearn is free for teachers and schools. School admins can contact a SplashLearn representative to set up a school-wide SplashLearn dashboard by registering here <a href='https://www.splashlearn.com/administrators'>https://www.splashlearn.com/administrators</a>"
  },
  {
    "questionText": "Which devices are compatible with SplashLearn?",
    "answerText": "SplashLearn can be experienced on a vast range of devices - everything from iPads, iPhones, laptops, desktops, and Chromebooks."
  }
]

export default HomepageFaqs;