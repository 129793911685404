import apiCaller from '../utils/api-caller';

export function SigninApi(params, source) {
  return apiCaller({method: 'post', path: '/users/sign_in.json?source=' + source + '&send_to_mixpanel=true', params: params});
}

export function ForgotPasswordApi(params){
  return apiCaller({method: 'post', path: '/users/password', params: params, restrictConversionToJson: true});
}

export function KlassCodeLoginApi(params){
  return apiCaller({method: 'post', path: '/klass_code_login', params: params});
}