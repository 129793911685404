export const TYPES = {
  HOME: 'home',
  PARENT: 'parent',
}

export const trySplashContentHome = {
  type: TYPES.HOME,
  point1: "Makes learning fun",
  point2: "Covers the curriculum",
  point3: "Safe and easy to use"
}

export const trySplashContentParent = {
  type: TYPES.PARENT,
  point1: "Fun, game-based learning",
  point2: "4,000+ math and reading lessons",
  point3: "Dedicated Parent Connect App"
}