import React from 'react';
import Image from '../../../common/Image';
import ExperianceGame from '~images/loggedout/home/modularPage/experiance_game.png';

const ScrollingText = () => {
  return (
    <div className="scrolling-txt-wrap">
      <div className="section-top scrolling-txt" style={{display:"none"}}>
        <div class="box">
          <ul>
            <li>engage</li>
            <li>educate</li>
            <li>enrich</li>
            <li>engage</li>
          </ul>
        </div>
      </div>

      <div className="scrolling-txt">
        <div class="box">
          <ul>
            <li>educate</li>
            <li>enrich</li>
            <li>engage</li>
            <li>educate</li>
          </ul>
        </div>
      </div>

      <div className="section-bottom scrolling-txt" style={{display:"none"}}>
        <div class="box">
          <ul>
            <li>enrich</li>
            <li>engage</li>
            <li>educate</li>
            <li>enrich</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const ModularSection1 = () => {
  return (
    <div className="clearfix modular-section-1-wrapper">
      <h3 className="head-text">
        Experience games that <ScrollingText/>
      </h3>
      <div className="main-wrapper">
        <div className="left-img-wrap">
          <Image src={ExperianceGame} alt="Experience games that engage, educate, enrich and engage" className="section-1-img" />
        </div>
        <div className="right-text-wrap">
          <ul className="ul-list">
            <li className="li-list-item">
              As your child explores new worlds and befriends magical creatures, they <strong>discover more than one way to solve a problem</strong>
            </li>
            <li className="li-list-item">
              With no timed challenges or overbearing skill progression, our games offer a safe space for your child to <strong>explore and make mistakes</strong>
            </li>
            <li className="li-list-item">
              Whether it's extra practice or a fun challenge, SplashLearn's games deliver <strong>real learning outcomes, always!</strong>
            </li>

          </ul>
          <p className="bottom-txt">
            Children who play SplashLearn more than 4 times a week show learning improvement of more than<span> 77% within the first 2 months</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModularSection1;
