import Lottie from 'lottie-web';
import FreemiumLoader from '../../json/freemium_loader.json';
var GlobalLoader = /** @class */ (function () {
    function GlobalLoader() {
        var loader = document.getElementById('js-ajax-spinner');
        var subEl = document.createElement('div');
        subEl.classList.add('freemium-spinner-sub');
        loader === null || loader === void 0 ? void 0 : loader.appendChild(subEl);
        Lottie.loadAnimation({
            container: subEl,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: FreemiumLoader,
        });
    }
    GlobalLoader.prototype.show = function (value) {
        var loader = document.getElementById('js-ajax-spinner');
        if (loader) {
            if (value) {
                loader.style.display = 'block';
            }
            else {
                loader.style.display = 'none';
            }
        }
    };
    return GlobalLoader;
}());
var GlobalSpinner = new GlobalLoader();
export default GlobalSpinner;
