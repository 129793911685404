import React from 'react';
import ReactDOM from 'react-dom';
import LoggedoutRoutes from '../routes/loggedout-app';

// global css loaded
import '../../css/dist/loggedout.scss';
// import ErrorBoundary from '../utils/error-boundary';
import { StyleSheetManager } from 'styled-components';

var prerender = navigator.userAgent && navigator.userAgent.includes("Prerender");
// inject css in DOM only when google bot, or any crawler tries to access the site.
// otherwise keep it false (default behaviour of styled components)
const App = () => {
  return (
    <StyleSheetManager disableCSSOMInjection={prerender}>
      <LoggedoutRoutes />
    </StyleSheetManager>
  );
};

ReactDOM.render(<App />, document.getElementById('loggedout-app'));
