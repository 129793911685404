import React from 'react';
import * as S from './style';
import Image from '../common/Image';
import GiftImage from '~images/loggedout/home/gift_image.png';
import { lightWhite } from '../shared-styles/Colors';
import { breakpoints, MediaQuery } from '../../../utils/responsive_utilities';
import { trackToGtmFE } from '../../../utils/gtm-event-common';
import { getCookie } from '../../../utils/web-helper';
import { footerUsData } from './footer_us_data';
import { footerUkData } from './footer_uk_data';
import { footerAuData } from './footer_au_data';

const Footer = ({showOddbodsTrademark}) => {
  const onClickGTMFooter = (eventParams) => {
      trackToGtmFE('clicked_footer',eventParams);
  }
  
  return (
    <S.Wrapper>
      <div className="content-container">
        <S.Flex1>
          {linksArr.map((linkset, index) => {
            return (
              <S.List key={index}>
                <S.ListItem>{linkset.mainLink}</S.ListItem>
                {linkset.sublinks.map((sublink, subindex, links) => {
                  return (
                    <S.ListItem sublink key={sublink.title}>
                      <a
                        href={sublink.link}
                        target={sublink.target ? '_target' : ''}
                        onClick={() => onClickGTMFooter({'Click Text': sublink.title,'Click URL': sublink.link})}
                      >
                        {sublink.title}
                      </a>
                    </S.ListItem>
                  );
                })}
              </S.List>
            );
          })}
          <S.KidsafeDiv className='gift'>
            <div style={{ flexGrow: 1 }}></div>
            <a href="https://crmlp.splashlearn.com/gift" aria-label='Gift a subscription' onClick={() => onClickGTMFooter({'Click Text': 'Gift','Click URL': 'https://crmlp.splashlearn.com/gift'})}>
              <Image src={GiftImage} alt="gift-image" />
            </a>
          </S.KidsafeDiv>
        </S.Flex1>
        <MediaQuery query={`(min-width: ${breakpoints.xl}px)`}>
          <S.Line></S.Line>
        </MediaQuery>
        <S.Flex2>
          <div>
            <S.ListItem as="span">
              <a href="/terms-of-use" onClick={() => onClickGTMFooter({'Click Text': 'Terms of use','Click URL': '/terms-of-use'})}>Terms of use</a>
            </S.ListItem>
            <span style={{ color: lightWhite }}>{' | '}</span>
            <S.ListItem as="span">
              <a href="/privacy" onClick={() => onClickGTMFooter({'Click Text': 'Privacy Policy','Click URL': '/privacy'})} >Privacy Policy</a>
            </S.ListItem>
            <S.Trademarks>
              StudyPad & SplashLearn are registered Trademarks of StudyPad, Inc.{' '}
            </S.Trademarks>
            {showOddbodsTrademark &&
              <S.OddbodsTrademark>
                <S.Trademarks>
                  © One Animation.
                </S.Trademarks>
                <S.Trademarks>
                  ODDBODS (Stylised) is a U.S. Registered Trademark of One Animation Pte Ltd.
                </S.Trademarks>
              </S.OddbodsTrademark>
            }
          </div>
          <S.KidsafeDiv>
            <div style={{ flexGrow: 1 }}></div>
            <a href="https://www.kidsafeseal.com/certifiedproducts/splashlearn.html" aria-label='Read about Kidsafe seal program' onClick={() => onClickGTMFooter({'Click Text': 'Kid Safe','Click URL': 'https://www.kidsafeseal.com/certifiedproducts/splashlearn.html'})}>
              <img src="https://www.kidsafeseal.com/sealimage/5098186132059937251/splashlearn_large_whitetm.png" alt="kidsafe" width="100%" height="100%" />

            </a>
          </S.KidsafeDiv>
        </S.Flex2>
      </div>
    </S.Wrapper>
  );
};

const countryCode = localStorage.getItem('user_preferred_language') || getCookie('user_preferred_language') || 'en';

const linksArr = countryCode == 'en-GB' ? footerUkData : (countryCode == 'en-AU' ? footerAuData : footerUsData);

export default Footer;
