import { FLUTTER_SETTINGS, screenRoute } from "../constants";
import { getQueryParamsObj, getUserCountryCode } from "../../utils/web-helper";
import CallableMessages from "../CallableMessages";
var RouteService = /** @class */ (function () {
    //Singleton class
    function RouteService() {
        // Init flutter settings
        if (!window.flutterSetting) {
            window[FLUTTER_SETTINGS] = {
                web_url: "".concat(window.location.origin, "/"),
                route_info: {
                    route_to: "home" ///route_to value tell whether to open onboarding or dashboard in flutter
                },
            };
        }
        else {
            window.flutterSetting.web_url = "".concat(window.location.origin, "/");
            window.flutterSetting.route_info = {
                route_to: "home" ///route_to value tell whether to open onboarding or dashboard in flutter
            };
        }
        if (!RouteService._instance) {
            RouteService._instance = this;
        }
        return RouteService._instance;
    }
    RouteService.prototype.updateRoute = function (value) {
        var queryParams = getQueryParamsObj(window.location.hash, true);
        if (!value)
            return;
        this.routeTo = value;
        if (window.flutterSetting) {
            window.flutterSetting.route_info = {
                route_to: value,
                route_data: {
                    preferred_language: localStorage.getItem('user_preferred_language') || 'en',
                    country_code: getUserCountryCode(),
                    onboarding_purpose: 'onboarding',
                    query_params: queryParams || {} // for MyToDo screen
                },
            };
        }
        new CallableMessages().flutterRouteChange(screenRoute[value]);
    };
    RouteService.prototype.reloadFlutterBuild = function () {
        new CallableMessages().reloadFlutterBuild();
    };
    return RouteService;
}());
export default RouteService;
