const FallbackFonts = `'Helvetica', 'Arial', 'sans-serif'`;

export const styledFont = (fontStyle, lh, fs=1.2, fw=5, per=0, unit='rem') =>{
  return `
      font-size: ${per ? fs*((unit == "px" ? 10 : 1) + per) : fs*(unit == "px" ? 10 : 1)}${unit};
      line-height: ${lh ? lh*(unit == "px" ? 10 : 1)+unit: "normal"};
      font-weight: ${fw*100};
      font-family: ${fontStyle}, ${FallbackFonts};
      font-style: normal;
    `
}

export const fontQuicksand = (lh, fs=1.2, fw=5, per = 0, unit='rem') => {
    return styledFont('Quicksand', lh, fs, fw, per, unit,);
}

export const fontGraphie = (lh, fs=1.2, fw=5, per = 0, unit='rem') => {
    return styledFont('Graphie', lh, fs, fw, per, unit);
}

export const fontPoppins = (lh, fs=1.2, fw=5, per = 0, unit='rem') => {
    return styledFont('Poppins', lh, fs, fw, per, unit);
}