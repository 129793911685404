import React, { useState } from 'react'
import Image from '../../../common/Image';
import { getCDNAssetURL } from '../../../../../utils/web-helper';
import { trackToGtmFE } from '../../../../../utils/gtm-event-common';

const cdnAssetUrl = getCDNAssetURL();

//both images need to be uploaded on cdn
const ExpandImage = `${cdnAssetUrl}/assets/homepage_images/Expand_Plus.svg`;
const CollapseImage = `${cdnAssetUrl}/assets/homepage_images/Collapse_Minus.svg`;

const SingleQA = ({index, item}) => {
  const [isOpen, setIsOpen] = useState(index === 0)
  const onClickGTMFAQ = (eventParams) => {
    if(!isOpen) {
      trackToGtmFE('interated_faq_expanded',eventParams);
    }else{
      trackToGtmFE('interated_faq_collapsed',eventParams);
    }
  }
  return (
    <li className="faq-item">
        <div className="faq-question" onClick={() => 
          {
            setIsOpen((prevOpen) => !prevOpen);
            onClickGTMFAQ({'Click Text':`${item.questionText}`});
          }
        }>
            <Image className="expand_collapse_icon" isCdnImage={true} src={isOpen?CollapseImage:ExpandImage}  width = "16px" height= "16px"  alt={isOpen?"collapse":"expand"} style={{display:"none"}}/>
            <h3 className="question-text">{item.questionText}</h3>
        </div>
        <div className={`faq-answer ${isOpen?"":"hide"}`} dangerouslySetInnerHTML={{__html: item.answerText}}></div>
    </li>
  )
}


export default SingleQA;