import React from 'react';
import Image from '../../common/Image';
import { PARENT_SIGNUP_PATH } from '../../../../routes/route-paths';
import TickIconDarkPurple from '~images/loggedout/home/tick-icon-dark-purple.svg';
import { getCDNAssetURL } from '../../../../utils/web-helper';
import { trackToGtmFE } from '../../../../utils/gtm-event-common';
const cdnAssetUrl = getCDNAssetURL();
const GirlCharacterImage = `${cdnAssetUrl}/assets/homepage_images/girl-character.png`;

const TrySplashlearn = ({ data, appDownloadCTA }) => {
  let ctaLink = PARENT_SIGNUP_PATH;
  let linkTarget = "_self"
  let sendAppDownloadCTAEvents = false;

  if (appDownloadCTA && appDownloadCTA.showAppDownloadCTA) {
    linkTarget = "_blank";
    sendAppDownloadCTAEvents = true;
  }

  const ctaClickHandler = async () => {
    const eventProperties ={'Click Text': 'Sign up for free','Click URL':ctaLink};
    trackToGtmFE('clicked_other_signup_button',eventProperties);
    if (sendAppDownloadCTAEvents) {
      trackEventOnGA('Download Button Tapped', JSON.stringify({ "From Page": "HomePage", "Link Location": "Scroll End" }))
    }
    window.open(ctaLink, linkTarget);
  }

  return (
    <div className="trysplashlearn-freebox">
      <div className='head-text'>Try SplashLearn for free</div>
      <div className="trysplashlearn-main-wrapper">
        <div className="left-img-wrap">
          <Image isCdnImage={true} src={GirlCharacterImage} alt="Try SplashLearn for free" />
        </div>
        <div className="right-text-wrap">
          <ul className="ul-list">
            <li className="li-list-item">
              <Image className="tick-icon" src={TickIconDarkPurple} alt="tick-icon-purple" style={{display:"none"}}/>
              <span>{data?.point1}</span>
            </li>
            <li className="li-list-item">
              <Image className="tick-icon" src={TickIconDarkPurple} alt="tick-icon-purple" style={{display:"none"}}/>
              <span>{data?.point2}</span>
            </li>
            <li className="li-list-item">
              <Image className="tick-icon" src={TickIconDarkPurple} alt="tick-icon-purple" style={{display:"none"}}/>
              <span>{data?.point3}</span>
            </li>
          </ul>
          <div className="trysplashlearn-primary-cta-button" onClick={() => ctaClickHandler()}>Sign up for free</div>
        </div>
      </div>
    </div>
  );
};

export default TrySplashlearn;
