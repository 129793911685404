import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
const secrets = require('../public_keys.json');

let DateToday = new Date();
let releaseVersionForSentry =
  secrets.sentry.env +
  '-' +
  DateToday.getFullYear() +
  '-' +
  DateToday.getMonth().toString().padStart(2, '0') +
  '-' +
  DateToday.getDate().toString().padStart(2, '0');

try {
  Sentry.init({
    dsn: secrets.sentry.dsn,
    attachStacktrace: true,
    integrations: [new BrowserTracing()],
    sampleRate: secrets.sentry.sampleRate,
    tracesSampleRate: secrets.sentry.tracesSampleRate,
    release: releaseVersionForSentry,
  });
} catch {
  console.log('sentry key mismatch');
}
