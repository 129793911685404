import i18n from './i18n';

export function mapById(items) {
  var result = new Map();
  items.map(function (item) {
    result.set(item.id, item);
  })
  return result;
}

export const mapByCode = (items) => {
  let res = new Map();
  items.map((item) => res.set(item.code, item));
  return res;
}

export function groupByKey(items, key) {
  var result = new Map();
  items.map(function (item) {
    if (!!result.get(item[key])) {
      result.get(item[key]).push(item);
    } else {
      result.set(item[key], [item]);
    }
  })
  return result;
}

export function CourseStatusMap(items) {
  let result = {};
  let playbaleStatus = {};
  items.map((item) => {
    let loStatus = playbaleStatus[item.learning_objective_id] || {}
    loStatus[item.playable_id] = item;
    playbaleStatus[item.learning_objective_id] = loStatus;
  })
  return playbaleStatus;
}

export function collateStarAndStatusInfo(playableStatusList, coursePlayables, learningObjectives) {
  var studentStars = 0;
  var playableStatusMap = new Map;

  playableStatusList.map((item) => {
    studentStars += item.reward_count;
    var loId = item.learning_objective_id;
    var loStatusMap = playableStatusMap.get(loId) || new Map;
    loStatusMap.set(item.playable_id, item);
    playableStatusMap.set(loId, loStatusMap);
  });

  var cgStarInfoMap = new Map;
  var loStarInfoMap = new Map;
  var cgRecentItemMap = new Map;
  var cgRecentLoMap = new Map;

  var loPlayableMap = groupByKey(coursePlayables, 'learning_objective_id');
  var loByIdMap = mapById(learningObjectives);

  learningObjectives.forEach(function (lo) {
    var stars = 0;
    var totalStars = (loPlayableMap.get(lo.id) || []).length * 3;
    var loItems = [...(playableStatusMap.get(lo.id) || new Map).values()];

    // update star info for learning objective
    loItems.map((i) => { stars += i.reward_count });
    loStarInfoMap.set(lo.id, { totalStars: totalStars, stars: stars });

    // update star info for content group
    var cgInfo = cgStarInfoMap.get(lo.content_group_id) || { totalStars: 0, stars: 0 };
    cgInfo.totalStars += totalStars;
    cgInfo.stars += stars;
    cgStarInfoMap.set(lo.content_group_id, cgInfo);

    // update recent playable for content group.
    if (loItems.length > 0) {
      var recentLoItem = loItems.reduce((x, y) => {
        return x.last_completed_at > y.last_completed_at ? x : y
      });

      if (!!recentLoItem && !!recentLoItem.last_completed_at) {
        var recentCgItem = cgRecentItemMap.get(lo.content_group_id);
        if (recentCgItem && !!recentCgItem.last_completed_at) {
          if (recentCgItem.last_completed_at < recentLoItem.last_completed_at) {
            recentCgItem = recentLoItem
          }
        } else {
          recentCgItem = recentLoItem;
        }
        cgRecentItemMap.set(lo.content_group_id, recentCgItem);
        var activeLO = loByIdMap.get(recentCgItem.learning_objective_id);
        cgRecentLoMap.set(lo.content_group_id, activeLO);
      }
    }
  });

  return {
    stars: studentStars,
    loStarInfoMap: loStarInfoMap,
    cgStarInfoMap: cgStarInfoMap,
    cgRecentLoMap: cgRecentLoMap,
    playableStatusMap: playableStatusMap
  };
}

export function collateCurriculumPlayables(learningObjectives, playableItems) {
  var result = new Map();
  var playableMap = new Map();

  playableItems.map(function (item) {
    if (!playableMap.get(item.learning_objective_id)) {
      playableMap.set(item.learning_objective_id, []);
    }
    playableMap.get(item.learning_objective_id).push(item);
  });

  learningObjectives.map(function (lo) {
    if (!!result.get(lo.content_group_id)) {
      if (!result.get(lo.content_group_id).get(lo.milestone_id)) {
        result.get(lo.content_group_id).set(lo.milestone_id, []);
      }
    } else {
      result.set(lo.content_group_id, new Map());
      result.get(lo.content_group_id).set(lo.milestone_id, []);
    }
    result.get(lo.content_group_id).get(lo.milestone_id).push({ ...lo, items: playableMap.get(lo.id) });
  });
  return result;
}

export function ContentGroupPlayableItemsMap(learningObjectives, playableItems) {
  var loMap = mapById(learningObjectives);
  var result = new Map();

  playableItems.map(function (item) {
    var lo = loMap.get(item.learning_objective_id);
    if (!!result.get(lo.content_group_id)) {
      result.get(lo.content_group_id).push(item)
    } else {
      result.set(lo.content_group_id, [item]);
    }
  });

  return result;
}

export function collateGamesInfo(games, items, contentGroups) {
  var gameMap = mapById(games),
    contentGroupMap = mapById(contentGroups),
    gameItemsMap = new Map;

  items.forEach(function (item) {
    if (!!gameItemsMap.get(item.game_id)) {
      gameItemsMap.get(item.game_id).push(item);
    }
    else {
      gameItemsMap.set(item.game_id, [item]);
    }
  });

  var gamesInfo = [];
  [...gameItemsMap.keys()].forEach(function (game_id) {
    var info = gameMap.get(game_id);
    var contentTagsMap = new Map;
    var items = gameItemsMap.get(game_id).sort((a, b) => { return a.ordering - b.ordering });

    items.forEach((i) => {
      i.content_tags && i.content_tags.forEach((tag) => {
        if (!contentTagsMap.get(tag)) {
          contentTagsMap.set(tag, true);
        }
      })
    });
    gamesInfo.push({ ...info, items: items, contentTags: [...contentTagsMap.keys()] });
  });
  return gamesInfo;
}

export function localUnitTranslator(learning_objectives, settings) {
  var result = [];
  var currency = settings.preferred_currency;

  if (currency && currency != 'US') {
    learning_objectives.forEach((lo) => {
      var key = "learning_objective." + lo.code;
      if (i18n.exists(key)) {
        lo.title = i18n.t(key + ".title");
      }
      result.push(lo);
    });
    return result;
  } else {
    return learning_objectives;
  }
}

export function contentGroupLOMap(curriculumPlayableMap, userLPStatus) {
  let result = {};
  let playableItems;
  let courseStatus = CourseStatusMap(userLPStatus);
  let tempPlayableMap;
  let playablesCompleted, playableCompletedPerLO;
  if (curriculumPlayableMap && userLPStatus) {
    curriculumPlayableMap.forEach((cg, cgkey) => {
      result[cgkey] = {
        learning_objectives: {},
        playable_items: {}
      };
      playableItems = {};
      playablesCompleted = 0
      tempPlayableMap = new Map();

      cg.forEach((ms) => {
        ms.forEach((lo) => {
          playableCompletedPerLO = 0
          result[cgkey].learning_objectives[lo.id] = lo
          lo.items && lo.items.forEach((playable) => {
            tempPlayableMap.set(String(playable.playable_id), playable);
          });
          result[cgkey].playable_items = tempPlayableMap;
          if (Object.keys(courseStatus).length > 0) {
            playableCompletedPerLO = courseStatus[lo.id] ? Object.keys(courseStatus[lo.id]).length : 0;
            playablesCompleted += playableCompletedPerLO;
          }
          result[cgkey].learning_objectives[lo.id].playableCompletedPerLO = playableCompletedPerLO;
          result[cgkey].learning_objectives[lo.id].learningObjectiveCompleted = lo.items && lo.items.length === playableCompletedPerLO;
        })
      })
      result[cgkey].totalCompleted = playablesCompleted;
    })
  }

  return result;
}

export function convertToSentenceCase(str) {
  return str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase();
}

export const totalCardsCompleted = (userLPStatus) => {
  let res = CourseStatusMap(userLPStatus);
  return Object.values(res).reduce((sum, val) => sum + Object.values(val).length, 0);
}