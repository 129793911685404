import React from 'react';
import { createStore } from 'redux';
import { connect } from 'react-redux';
import {
  mapById,
  collateGamesInfo,
  collateCurriculumPlayables,
  collateStarAndStatusInfo,
  CourseStatusMap,
  localUnitTranslator,
  contentGroupLOMap,
  totalCardsCompleted
} from '../utils/content-mapper';
import i18n from '../utils/i18n';

// Actions
const SET_FIRST_LOAD = 'SET_FIRST_LOAD';
const INITIALIZE = 'INITIALIZE';
const SET_PROFILE = 'SET_PROFILE';
const SET_PLAYLIST = 'SET_PLAYLIST';
const SET_LEARNING_PATH_ITEMS = 'SET_LEARNING_PATH_ITEMS';
const SET_COURSE_STATUS = 'SET_COURSE_STATUS';
const SET_STUDENT_ASSESSMENT = 'SET_STUDENT_ASSESSMENT';
const SET_STUDENT_ASSIGNMENT = 'SET_STUDENT_ASSIGNMENT';
const SET_STUDENT_FACT_FLUENCIES = 'SET_STUDENT_FACT_FLUENCIES';
const SET_STUDENT_FACT_FLUENCIES_PROGRESS = 'SET_STUDENT_FACT_FLUENCIES_PROGRESS';
const SET_STUDENT_LEARNING_PLAN = 'SET_STUDENT_LEARNING_PLAN';
const DASHBOARD_TOUR_ENABLE = 'DASHBOARD_TOUR_ENABLE';
const DASHBOARD_TOUR_DISABLE = 'DASHBOARD_TOUR_DISABLE';
const SHOW_ELA_POPUP = 'SHOW_ELA_POPUP';
const REWARD_SHOWN = 'REWARD_SHOWN';
const LEARNING_PATH_LOADED = 'LEARNING_PATH_LOADED';
const POPUP_SHOWN = 'POPUP_SHOWN';
const SAVE_PARENT_AGE = 'SAVE_PARENT_AGE';
const SET_ACTIVE_CONTENT_GROUP = 'SET_ACTIVE_CONTENT_GROUP';
const SHOWN_ACTIVE_SLIDE_ANIMATION = 'SHOWN_ACTIVE_SLIDE_ANIMATION';
const MF_ANIMATION_SHOWN = 'MF_ANIMATION_SHOWN';
const MF_CARD_GLOW_SHOWN = 'MF_CARD_GLOW_SHOWN';
const SET_CONTENT_GROUP_DETAILS = 'SET_CONTENT_GROUP_DETAILS';
const SET_REDO_ITEMS = 'SET_REDO_ITEMS';
const UPDATE_CURRENT_STUDENT_INFO = 'UPDATE_CURRENT_STUDENT_INFO';
const CURRICULUM_PLAYABLE_CLICKED = 'CURRICULUM_PLAYABLE_CLICKED';
const LP_PLAYABLE_CLICKED = 'LP_PLAYABLE_CLICKED';
const LP_TILE_CLICKED = 'LP_TILE_CLICKED';
const TOPIC_TILE_CLICKED = 'TOPIC_TILE_CLICKED';
const ALL_TOPIC_TILE_CLICKED = 'ALL_TOPIC_TILE_CLICKED';
const TOPIC_SCROLL_SHOWN = 'TOPIC_SCROLL_SHOWN';
const MAIL_LEARNING_PATH_DATA_FETCHED = 'MAIL_LEARNING_PATH_DATA_FETCHED';
const CLEAR_MAIL_LEARNING_PATH_DATA_FETCHED = 'CLEAR_MAIL_LEARNING_PATH_DATA_FETCHED';
const SET_MAIL_PLYABLE_DETAILS = 'SET_MAIL_PLYABLE_DETAILS';
const SET_MAIL_PLYABLE_LAUNCHED = 'SET_MAIL_PLYABLE_LAUNCHED';
const WEEKLY_REPORT_LOADED = 'WEEKLY_REPORT_LOADED';
const MILESTONE_LO_STATUS_LOADED = 'MILESTONE_LO_STATUS_LOADED';
const SET_SUBJECT_TOGGLE_POINTER_FALSE = 'SET_SUBJECT_TOGGLE_POINTER_FALSE';
const SET_GRADE_DROPDOWN_TOAST_DETAILS = 'SET_GRADE_DROPDOWN_TOAST_DETAILS';
const PARENT_DASHBOARD_ICON_CLICKED = 'PARENT_DASHBOARD_ICON_CLICKED';
const PARENT_DASHBOARD_TOUR_DISABLE = 'PARENT_DASHBOARD_TOUR_DISABLE';
const PARENT_DASHBOARD_NUDGE_SHOWN = 'PARENT_DASHBOARD_NUDGE_SHOWN';
const BACK_TO_STUDENT_DASHBOARD_CLICKED = 'BACK_TO_STUDENT_DASHBOARD_CLICKED';
const SET_MILESTONE_LO_STATUS = 'SET_MILESTONE_LO_STATUS';
const SET_META_DATA = 'SET_META_DATA';
const SET_WEEKLY_WIDGET_DATA = 'SET_WEEKLY_WIDGET_DATA';
const WEEKLY_REPORT_UNLOADED = 'WEEKLY_REPORT_UNLOADED';
const MILESTONE_LO_STATUS_UNLOADED = 'MILESTONE_LO_STATUS_UNLOADED';
const SET_ANIMATIONS = 'SET_ANIMATIONS';
const SET_AB_TESTS = 'SET_AB_TESTS';
const SET_FOCUS_AREAS = 'SET_FOCUS_AREAS';
const SET_APP_THEME = 'SET_APP_THEME';
const WEEKLY_GOAL_PROMPT_SHOWN = 'WEEKLY_GOAL_PROMPT_SHOWN';
const SET_LARGE_BANNER_VISIBILITY = 'SET_LARGE_BANNER_VISIBILITY';
const SET_UPDATE_STUDENT_DASHBOARD = "SET_UPDATE_STUDENT_DASHBOARD";
const SET_GAMIFICATION_USER_PROFILE = 'SET_GAMIFICATION_USER_PROFILE';
const SET_MULTIPLAYER_SEED_DATA = "SET_MULTIPLAYER_SEED_DATA";
const SET_PLAYERS_DATA = "SET_PLAYERS_DATA";
const SET_PLAYERS_SEED_DATA = "SET_PLAYERS_SEED_DATA";
const SET_GAME_INVITE_TIMEOUT_ID = "SET_GAME_INVITE_TIMEOUT_ID";
const SET_MULTIPLAYER_FTUE = "SET_MULTIPLAYER_FTUE";
const SET_USER_COINS = "SET_USER_COINS";
const SET_ACTIVE_COURSE_CLASS = "SET_ACTIVE_COURSE_CLASS";
const TUTORING_DASHBOARD_TOUR_DISABLE = "TUTORING_DASHBOARD_TOUR_DISABLE";
const SHOW_C1_PITSTOP = 'SHOW_C1_PITSTOP';
const SET_LEARNING_PARTNER_ABTEST = 'SET_LEARNING_PARTNER_ABTEST';
const SET_BEHAVIOUR_QUEST_PROFILE = 'SET_BEHAVIOUR_QUEST_PROFILE';
const SET_REWARD_CONFIGURATION = 'SET_REWARD_CONFIGURATION';
const SET_ACTIVITY_INFORMATION = 'SET_ACTIVITY_INFORMATION';
const SET_FREEMIUM_TAB_DATA = 'SET_FREEMIUM_TAB_DATA';
const SET_ACTIVE_GAME_INVITE = 'SET_ACTIVE_GAME_INVITE';
const SET_ACTIVE_FESTIVAL_THEME = 'SET_ACTIVE_FESTIVAL_THEME';
const SET_IS_CHRISTMAS_THEME = 'SET_IS_CHRISTMAS_THEME';
const SET_HOME_DASHBOARD_THEME = 'SET_HOME_DASHBOARD_THEME';
const SET_WON_SB_CERTIFCATE = 'SET_WON_SB_CERTIFCATE';
const SET_POPUP_GAME_INVITE = 'SET_POPUP_GAME_INVITE';
const SET_CONTEST_DATA = 'SET_CONTEST_DATA';

const SET_CHANGED_GRADE = 'SET_CHANGED_GRADE';

// Initial State
const initialState = {
  firstLoad: false,
  student: {},
  coins: 0,
  stars: 0,
  settings: {},
  current_focus_areas: [],
  current_course: {},
  courses: [],
  learning_path_items: [],
  reward_item: null,
  content_groups: [],
  milestones: [],
  learning_objectives: [],
  user_lp_statuses: [],
  criticalGames: [],
  enrichmentGames: [],
  bedTimeStories: [],
  oddBodsBooks: [],
  activity_games: [],
  hash_for_course_status: {},
  studentAssessment: [],
  assignmentListApi: [],
  learningPlansApi: [],
  sightWordsAssignments: [],
  isMyToDoApiLoaded: false,
  showDashboardTour: true,
  elaPopupShown: false,
  lpLoaded: false,
  parentDobYear: '',
  ptlParent: null,
  activeLPCardContentGroup: '',
  wasActiveContentGroupSet: false,
  showLPSlideAnimation: true,
  assigned_ab_tests: {},
  contentGroupDetails: new Map(),
  curriculumPlayableMap: {},
  mathFactAnimationShown: false,
  mathFactCardGlowShown: false, // this will remain false until the user clicks on the card.
  mailPlayableDetails: {},
  redo_items: [],
  gradeToastDetails: {},
  contentLOMap: {},
  milestoneLoStatusDataLoaded: false,
  weeklyReportDataLoaded: false,
  milestoneLoStatus: {},
  weeklyWidgetData: null,
  totalCardsCompleted: 0,
  initialAnimations: {},
  assessmentEverAssigned: false,
  assignmentEverAssigned: false,
  abTests: {},
  appTheme: null,
  updateStudentDashboard: false,
  gamificationUserProfile: null,
  multiplayerSeedData: {},
  playersData: null,
  playersSeedData: null,
  gameInviteTimeoutId: null,
  showMultiPlayerGames: true,
  multiplayerFtue: [],
  activeCourseClass: {},
  c1PitstopOptions: {},
  parentDetails: {},
  learningPartnerABTest: false,
  behaviourQuestProfile: null,
  badgeConfigUrl: null,
  weeklyGoalCoins: null,
  current_user: {},
  activityInformation: {
    totalFreeCardCount: 0,
    remainingCardCount: 0,
    isDataLoaded: false
  },
  freemiumTabData: {},
  activeGameInvites: [],
  activeFestivalTheme: { active: false, festival: '' },
  isChristmasTheme: undefined,
  homeDashboardTheme: [],
  wonSBCertificate: [],
  teacher: {},
  popupGameInvite: [],
  clientId: '',
  contestData: null,
  courseId: ''
};

// Root Reducer
export const rootReducer = (state = initialState, action) => {
  let payload = action.payload;
  switch (action.type) {
    case SET_FIRST_LOAD:
      return { ...state, firstLoad: action.payload };
    // INITIALIZE and SET_PROFILE are same for now.
    // Note: INITIALIZE is just temporary until codes gets cleaned.
    case INITIALIZE:
      let settings = payload.settings;
      if (settings.tileDashboardVersion === 'tileddashboard_2_0') {
        settings = { ...settings, td_coachmark_details: {} };
      }
      return {
        ...state,
        student: action.payload.student,
        coins: action.payload.coins,
        settings,
        current_focus_areas: action.payload.course_focus_areas,
        current_course: action.payload.current_course,
        teacher: action.payload.teacher
      };
    case SET_PROFILE: {
      if (payload.settings.preferred_language) {
        i18n.changeLanguage(payload.settings.preferred_language);
        localStorage.setItem('user_preferred_language', payload.settings.preferred_language);
      }
      let settings = payload.settings;
      if (settings.tileDashboardVersion === 'tileddashboard_2_0') {
        settings = { ...settings, td_coachmark_details: {} };
      }
      const defaultSubject = 'math';
      // setting the game theme to subject for new iframe
      SPWidget.currentSubject = (payload.current_course && payload.current_course.subject_code) || defaultSubject;
      return {
        ...state,
        student: payload.student,
        coins: payload.coins,
        settings: settings,
        current_focus_areas: payload.course_focus_areas,
        current_course: payload.current_course,
        courses: payload.courses,
        ptlParent: payload.parent,
        wasActiveContentGroupSet: false,
        showLPSlideAnimation: true,
        assigned_ab_tests: payload.assigned_ab_tests,
        showMultiPlayerGames: payload.show_multiplayer_games,
        multiplayerFtue: payload.multiplayer_ftue,
        parentDetails: payload.parent,
        current_user: payload.current_user,
        teacher: payload.teacher,
        clientId: payload.client_id
      };
    }
    case SET_AB_TESTS: {
      return {
        ...state,
        abTests: payload
      }
    }
    case UPDATE_CURRENT_STUDENT_INFO: {
      return {
        ...state,
        student: payload.student
      }
    }
    case LEARNING_PATH_LOADED:
      return {
        ...state,
        lpLoaded: true,
      };
    case SET_PLAYLIST:
      let criticalGames = collateGamesInfo(
        payload.games,
        payload.critical_game_playable_items,
        payload.content_groups
      );
      let enrichmentGames = collateGamesInfo(
        payload.games,
        payload.enrichment_game_playable_items,
        payload.content_groups
      );
      let bedTimeStories = payload.bed_time_stories;
      let translatedLOs = localUnitTranslator(
        payload.learning_objectives,
        state.settings
      );

      let curriculumPlayableMap = collateCurriculumPlayables(
        translatedLOs,
        payload.curriculum_playable_items
      );
      let oddBodsBooks = payload.oddbods_books || []
      let activity_games = payload.activity_games || [];
      return {
        ...state,
        content_groups: payload.content_groups,
        milestones: payload.milestones,
        learning_objectives: translatedLOs,
        curriculum_playable_items: payload.curriculum_playable_items,
        games: payload.games,
        criticalGames: criticalGames,
        enrichmentGames: enrichmentGames,
        bedTimeStories: bedTimeStories,
        contentGroupMap: mapById(payload.content_groups),
        milestoneMap: mapById(payload.milestones),
        learningObjectiveMap: mapById(translatedLOs),
        gameMap: mapById(payload.games),
        curriculumPlayableMap: curriculumPlayableMap,
        oddBodsBooks: oddBodsBooks,
        activity_games: activity_games,
        // allContentGroupPlayables: allContentGroupPlayables
      };
    case SET_LEARNING_PATH_ITEMS:
      var rewardItem = null;
      if (window.__LEARNING_PATH_COMPLETE__) {
        rewardItem = payload.reward_item;
      }
      return {
        ...state,
        learning_path_items: payload.items,
        reward_item: rewardItem,
      };
    case REWARD_SHOWN:
      window.__LEARNING_PATH_COMPLETE__ = false;
      return {
        ...state,
        reward_item: null,
      };
    case SET_COURSE_STATUS:
      var info = collateStarAndStatusInfo(
        payload.user_lp_statuses,
        state.curriculum_playable_items,
        state.learning_objectives
      );
      
      let contentLOMap = contentGroupLOMap(
        state.curriculumPlayableMap,
        payload.user_lp_statuses,
      );
      // Todo: use playableStatusMap instead of hash_for_course_status
      // since we should be using new Map instead of {} in all the mappings
      return {
        ...state,
        coins: payload.coins,
        stars: info.stars,
        loStarInfoMap: info.loStarInfoMap,
        cgStarInfoMap: info.cgStarInfoMap,
        cgRecentLoMap: info.cgRecentLoMap,
        playableStatusMap: info.playableStatusMap,
        hash_for_course_status: CourseStatusMap(payload.user_lp_statuses),
        cardsCompletedToday: payload.cards_completed_today,
        canPlayFreeCardsFromAnywhere: payload.can_play_free_cards_from_anywhere,
        contentLOMap,
        totalCardsCompleted: totalCardsCompleted(payload.user_lp_statuses),
        user_lp_statuses: payload.user_lp_statuses,
      };

    case SET_STUDENT_ASSESSMENT:
      return {
        ...state,
        studentAssessment: action.payload.assessments,
        assessmentEverAssigned: action.payload.assessment_ever_assigned
      };
    case SET_STUDENT_ASSIGNMENT:
      return {
        ...state,
        assignmentListApi: action.payload.student_assignments,
        sightWordsAssignments: action.payload.groupables,
        assignmentEverAssigned: action.payload.assignment_ever_assigned
      };
    case SET_STUDENT_FACT_FLUENCIES:
      action.payload.student_fact_fluencies?.forEach(sff => {
        // sff.setting.practice_days comes null in case of custom mode
        if (sff.setting && sff.setting.practice_days) {
          sff.setting.practice_days = sff.setting.practice_days.sort((a, b) => a - b);
        }
      })
      return {
        ...state,
        fact_fluencies: action.payload.fact_fluencies,
        student_fact_fluencies: action.payload.student_fact_fluencies
      };
    case SET_STUDENT_FACT_FLUENCIES_PROGRESS:
      return {
        ...state,
        student_fact_fluencies_progress: action.payload
      };
    case SET_STUDENT_LEARNING_PLAN:
      return {
        ...state,
        learningPlansApi: action.payload.learning_plans,
        isMyToDoApiLoaded: true,
      };
    case DASHBOARD_TOUR_ENABLE:
      return {
        ...state,
        showDashboardTour: true,
      };
    case DASHBOARD_TOUR_DISABLE:
      return {
        ...state,
        showDashboardTour: false,
      };
    case SHOW_ELA_POPUP:
      return {
        ...state,
        elaPopupShown: true,
      };
    case POPUP_SHOWN: {
      const settings = { ...state.settings }
      return {
        ...state,
        settings
      }
    }
    case SAVE_PARENT_AGE: {
      return { ...state, parentDobYear: payload }
    }

    case SET_ACTIVE_CONTENT_GROUP: {
      return {
        ...state,
        activeLPCardContentGroup: action.payload,
        wasActiveContentGroupSet: true,
      }
    }
    case SHOWN_ACTIVE_SLIDE_ANIMATION: {
      return {
        ...state,
        showLPSlideAnimation: false
      }
    }
    case SET_CONTENT_GROUP_DETAILS: {
      return {
        ...state,
        contentGroupDetails: mapById(action.payload.content_group_details)
      }
    }
    case MF_ANIMATION_SHOWN: {
      return {
        ...state,
        mathFactAnimationShown: true,
      }
    }
    case MF_CARD_GLOW_SHOWN: {
      return {
        ...state,
        mathFactCardGlowShown: true,
      }
    }
    case SET_REDO_ITEMS: {
      return {
        ...state,
        redo_items: action.payload.items,
      }
    }
    case CURRICULUM_PLAYABLE_CLICKED: {
      const td_coachmark_details = { ...state.settings.td_coachmark_details, curriculum_playable_clicked: true }
      const settings = { ...state.settings, td_coachmark_details };
      return {
        ...state,
        settings
      }
    }
    case LP_PLAYABLE_CLICKED: {
      const td_coachmark_details = { ...state.settings.td_coachmark_details, lp_playable_clicked: true }
      const settings = { ...state.settings, td_coachmark_details };
      return {
        ...state,
        settings
      }
    }
    case LP_TILE_CLICKED: {
      let settings;
      if (state.settings.td_coachmark_details) {
        const td_coachmark_details = { ...state.settings.td_coachmark_details, lp_tile_clicked: true };
        settings = { ...state.settings, td_coachmark_details };
      } else if (state.settings.parent_dashboard_tour) {
        const parent_dashboard_tour = { ...state.settings.parent_dashboard_tour, lp_tile_clicked: true };
        settings = { ...state.settings, parent_dashboard_tour };
      }
      return {
        ...state,
        settings
      }
    }
    case TOPIC_TILE_CLICKED: {
      const td_coachmark_details = { ...state.settings.td_coachmark_details, topic_tile_clicked: true }
      const settings = { ...state.settings, td_coachmark_details };
      return {
        ...state,
        settings
      }
    }
    case ALL_TOPIC_TILE_CLICKED: {
      const td_coachmark_details = { ...state.settings.td_coachmark_details, all_topic_tile_clicked: true }
      const settings = { ...state.settings, td_coachmark_details };
      return {
        ...state,
        settings
      }
    }
    case TOPIC_SCROLL_SHOWN: {
      const td_coachmark_details = { ...state.settings.td_coachmark_details, topic_scroll_shown: true }
      const settings = { ...state.settings, td_coachmark_details };
      return {
        ...state,
        settings
      }
    }
    case SET_MAIL_PLYABLE_DETAILS: {
      return {
        ...state,
        mailPlayableDetails: {
          ...action.payload,
          isMailPlayableLaunched: false,
        }
      }
    }
    case SET_MAIL_PLYABLE_LAUNCHED: {
      return {
        ...state,
        mailPlayableDetails: {
          ...state.mailPlayableDetails,
          isMailPlayableLaunched: true
        }
      }
    }
    case MAIL_LEARNING_PATH_DATA_FETCHED: {
      return {
        ...state,
        mailPlayableDetails: {
          ...state.mailPlayableDetails,
          fetchedDashboardLearningPathData: true
        }
      }
    }
    case CLEAR_MAIL_LEARNING_PATH_DATA_FETCHED: {
      return {
        ...state,
        mailPlayableDetails: {
          ...state.mailPlayableDetails,
          fetchedDashboardLearningPathData: null
        }
      }
    }

    case SET_META_DATA: {
      return {
        ...state,
        meta_data: action.payload,
      }
    }

    case SET_SUBJECT_TOGGLE_POINTER_FALSE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_subject_toggle_pointer: false
        }
      }
    }
    case SET_GRADE_DROPDOWN_TOAST_DETAILS: {
      return {
        ...state,
        gradeToastDetails: { ...payload }
      }
    }
    case WEEKLY_REPORT_LOADED:
      return {
        ...state,
        weeklyReportDataLoaded: true
      }
    case MILESTONE_LO_STATUS_LOADED:
      return {
        ...state,
        milestoneLoStatusDataLoaded: true
      }
    case PARENT_DASHBOARD_ICON_CLICKED: {
      return {
        ...state,
        settings: {
          ...state.settings,
          parent_dashboard_tour: {
            ...state.settings.parent_dashboard_tour, parents_icon_clicked: true
          }
        },
      }
    }
    case PARENT_DASHBOARD_TOUR_DISABLE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          parent_dashboard_tour: {
            ...state.settings.parent_dashboard_tour, show_parent_dashboard_tour: true
          }
        }
      }
    }
    case TUTORING_DASHBOARD_TOUR_DISABLE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          parent_dashboard_tour: {
            ...state.settings.parent_dashboard_tour, tutoring_dashboard_tour_shown: true
          }
        }
      }
    }
    case PARENT_DASHBOARD_NUDGE_SHOWN: {
      return {
        ...state,
        settings: {
          ...state.settings,
          parent_dashboard_tour: {
            ...state.settings.parent_dashboard_tour, report_generated_nudge: true
          }
        }
      }
    }
    case BACK_TO_STUDENT_DASHBOARD_CLICKED: {
      return {
        ...state,
        settings: {
          ...state.settings,
          parent_dashboard_tour: {
            ...state.settings.parent_dashboard_tour, back_to_student_dashboard: true
          }
        }
      }
    }
    case SET_MILESTONE_LO_STATUS: {
      return {
        ...state,
        milestoneLoStatus: payload
      }
    }
    case SET_WEEKLY_WIDGET_DATA: {
      return {
        ...state,
        weeklyWidgetData: payload
      }
    }
    case WEEKLY_REPORT_UNLOADED:
      return {
        ...state,
        weeklyReportDataLoaded: false
      }
    case MILESTONE_LO_STATUS_UNLOADED:
      return {
        ...state,
        milestoneLoStatusDataLoaded: false
      }

    case SET_CHANGED_GRADE:
      return {
        ...state,
        courseId: action.payload
      }

    // Holds the animations for chasing mastery
    case SET_ANIMATIONS:
      return {
        ...state,
        initialAnimations: payload
      }
    case SET_FOCUS_AREAS:
      return {
        ...state,
        current_focus_areas: payload
      }
    case SET_APP_THEME: {
      return {
        ...state,
        appTheme: payload
      }
    }
    case WEEKLY_GOAL_PROMPT_SHOWN:
      return {
        ...state,
        settings: {
          ...state.settings, show_weekly_goals_popup: false
        }
      }

    case SET_LARGE_BANNER_VISIBILITY:
      let updatedSettings = { ...state.settings };
      if (payload === false)
        delete updatedSettings["app_download_banner_details"];

      return {
        ...state,
        settings: {
          ...updatedSettings
        },
      }

    case SET_UPDATE_STUDENT_DASHBOARD:
      return {
        ...state,
        updateStudentDashboard: payload
      }

    case SET_GAMIFICATION_USER_PROFILE:
      return {
        ...state,
        gamificationUserProfile: payload
      }

    case SET_MULTIPLAYER_SEED_DATA:
      return {
        ...state,
        multiplayerSeedData: payload
      }

    case SET_PLAYERS_DATA:
      return {
        ...state,
        playersData: payload
      }
    case SET_PLAYERS_SEED_DATA:
      return {
        ...state,
        playersSeedData: payload
      }
    case SET_GAME_INVITE_TIMEOUT_ID:
      return {
        ...state,
        gameInviteTimeoutId: payload
      }
    case SET_MULTIPLAYER_FTUE:
      return {
        ...state,
        multiplayerFtue: payload
      }
    case SET_USER_COINS:
      return {
        ...state,
        coins: payload
      }

    case SET_ACTIVE_COURSE_CLASS:
      return {
        ...state,
        activeCourseClass: payload || {}
      }
    case SHOW_C1_PITSTOP: {
      return {
        ...state,
        c1PitstopOptions: payload
      }
    }
    case SET_LEARNING_PARTNER_ABTEST: {
      return {
        ...state,
        learningPartnerABTest: payload
      }
    }
    case SET_BEHAVIOUR_QUEST_PROFILE: {
      return {
        ...state,
        behaviourQuestProfile: payload
      }
    }
    case SET_REWARD_CONFIGURATION: {
      return {
        ...state,
        badgeConfigUrl: payload.badge_images_base_url,
        weeklyGoalCoins: payload.coins_weekly_goal
      }
    }
    case SET_ACTIVITY_INFORMATION: {
      return {
        ...state,
        activityInformation: payload
      }
    }
    case SET_FREEMIUM_TAB_DATA: {
      return {
        ...state,
        freemiumTabData: payload
      }
    }
    case SET_ACTIVE_GAME_INVITE: {
      return {
        ...state,
        activeGameInvites: payload
      }
    }
    case SET_ACTIVE_FESTIVAL_THEME: {
      return {
        ...state,
        activeFestivalTheme: payload
      }
    }
    case SET_IS_CHRISTMAS_THEME: {
      return {
        ...state,
        isChristmasTheme: payload
      }
    }
    case SET_HOME_DASHBOARD_THEME: {
      return {
        ...state,
        homeDashboardTheme: payload
      }
    }
    case SET_WON_SB_CERTIFCATE: {
      return {
        ...state,
        wonSBCertificate: payload
      }
    }
    case SET_POPUP_GAME_INVITE: {
      return {
        ...state,
        popupGameInvite: payload
      }
    }
    case SET_CONTEST_DATA: {
      return {
        ...state,
        contestData: payload
      }
    }
    default:
      return state;
  }
};

export const Store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

/***----- Redux store connector -------***/

export default function connectWithStore(
  mapStateToProps,
  dispatchStateToProps,
  component
) {
  var ConnectedComponent = connect(
    mapStateToProps,
    dispatchStateToProps
  )(component);
  return function (props) {
    return <ConnectedComponent {...props} store={Store} />;
  };
}
