var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CanPlayFromCurriculum, CourseStatusApi, GetLearningPlans, SetActiveCourse } from "../../services/student-api";
import { fontGraphie } from "../../../fonts/mixin";
import { createUserDashboard, queryFreemiumDashboardConfig, userDashboard, querySeedData, liveClassQuery, studentKlassDetails } from "../../services/graphql-freemium-api";
import { calculateDaysBetweenTwoDates, convertToUUIDFormat, getDeviceType, sendErrorToSentry, getItemFromSessionStorage, getUserCountryCode, canShowCatexMigrationScreenValue, getStartingMondayOfWeekDateTimeinUtc, getItemFromLocalStorage } from "../../utils/web-helper";
import { Store } from "../../stores/student-dashboard";
import { getDRSLiveClassAbTestAssigned, getFreemiumAbTestAssigned, isDRSWithCategoryExpansion, isDynamicPositioningAssigned, isNewCoqAssigned, isDashboardCachingEnabled } from "../../utils/feature_mapping";
import { tabNames } from "../../flutter-integration/constants";
import { GetStudentTasksFromGraphql } from "../../services/student-api-graphql";
export var GAME_ZONE_HEADER_CODE = 'game_zone';
export var getAction = function (actions, actionCode) {
    if (actionCode)
        return actions === null || actions === void 0 ? void 0 : actions.find(function (item) { return item.code == actionCode; });
    else
        return null;
};
export var getTheme = function (themes, themeCode) {
    if (themeCode)
        return themes === null || themes === void 0 ? void 0 : themes.find(function (item) { return item.code == themeCode; });
    else
        return themes === null || themes === void 0 ? void 0 : themes.find(function (item) { return item.code == "white_theme"; });
};
export var getTabName = function (elements, sectionTabName) {
    if (sectionTabName)
        return elements.find(function (item) { return item.sectionTabName == sectionTabName; });
    else
        return null;
};
export var getAllPossibleImageKeys = [
    "left_img_code",
    "bg_img_code",
    "play_button_img_code",
    "tick_img_code",
    "right_img_code",
    "active_icon",
    "inactive_icon",
    "header_icon",
    "right_img_icon_code",
    "playable_img_code"
];
export var setActiveCourse = function (props, shouldReloadDashboard) { return __awaiter(void 0, void 0, void 0, function () {
    var params, dataToUpdate;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((_a = props === null || props === void 0 ? void 0 : props.actionData) === null || _a === void 0 ? void 0 : _a.subject_name)) return [3 /*break*/, 6];
                params = { subject_code: props.actionData.subject_name };
                return [4 /*yield*/, SetActiveCourse(params)];
            case 1:
                _b.sent();
                if (!!shouldReloadDashboard) return [3 /*break*/, 3];
                return [4 /*yield*/, CourseStatusApi()];
            case 2:
                dataToUpdate = _b.sent();
                Store.dispatch({ type: 'SET_COURSE_STATUS', payload: dataToUpdate });
                _b.label = 3;
            case 3:
                if (!props.actionParams.parentLogin) return [3 /*break*/, 4];
                props.actionParams.setActiveCourse && props.actionParams.setActiveCourse(__assign(__assign({}, params), { reloadStudent: shouldReloadDashboard }));
                return [3 /*break*/, 6];
            case 4:
                if (!shouldReloadDashboard) return [3 /*break*/, 6];
                return [4 /*yield*/, props.actionParams.reloader(false)];
            case 5: return [2 /*return*/, _b.sent()];
            case 6: return [2 /*return*/];
        }
    });
}); };
export var setMyToDoInitialData = function (props) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        try {
            switch ((_a = props.actionData) === null || _a === void 0 ? void 0 : _a.click_source) {
                case 'assignment':
                    GetStudentTasksFromGraphql({
                        student_id: (_c = (_b = props.actionParams) === null || _b === void 0 ? void 0 : _b.student) === null || _c === void 0 ? void 0 : _c.id,
                    }).then(function (data) {
                        Store.dispatch({ type: 'SET_STUDENT_ASSIGNMENT', payload: data });
                    });
                    return [2 /*return*/];
                case 'assessment_learning_plan':
                    GetLearningPlans({ student_id: (_e = (_d = props.actionParams) === null || _d === void 0 ? void 0 : _d.student) === null || _e === void 0 ? void 0 : _e.id }).then(function (data) {
                        Store.dispatch({ type: 'SET_STUDENT_LEARNING_PLAN', payload: data });
                    });
            }
        }
        catch (error) {
            // @ts-ignore global function
            trackAnalyticsEvent('Error Occured!', {
                'methodName': 'setMyToDoInitialData',
                'from': window.location.pathname,
                'hash': window.location.hash,
                'click_source': (_f = props.actionData) === null || _f === void 0 ? void 0 : _f.click_source,
                'error': error === null || error === void 0 ? void 0 : error.message,
                'stack': error === null || error === void 0 ? void 0 : error.stack,
            });
        }
        return [2 /*return*/];
    });
}); };
export var breakpoints = {
    xxl: 1441,
    xl: 1279,
    lg: 1024,
    md: 767,
    sm: 480,
    xs: 0,
};
export var widthSizes = {
    'mobile': {
        xxxl: 956,
        xxl_1: 478,
        xxl: 466,
        xl: 312,
        lg_2: 226,
        lg_1: 222,
        lg: 272,
        md_1: 180,
        md: 192,
        sm: 172,
    },
    'tablet': {
        xxxl: 956,
        xxl_1: 478,
        xxl: 456,
        xl: 306,
        lg_2: 226,
        lg_1: 216,
        lg: 272,
        md_1: 180,
        md: 186,
        sm: 172,
    },
    'desktop': {
        xxxl: 956,
        xxl_1: 478,
        xxl: 466,
        xl: 312,
        lg_2: 226,
        lg_1: 222,
        lg: 272,
        md_1: 180,
        md: 192,
        sm: 172,
    },
};
export var heightSizes = {
    xxxl: 112,
    xxl: 152,
    lg_1: 150,
    xl: 180,
    lg: 180,
    md: 180,
    sm: 180
};
export var titleFontSizes = {
    'mobile': {
        xxxl: fontGraphie(2.8, 2.4, 7),
        xxl: fontGraphie(2.2, 1.8, 7),
        xl: fontGraphie(2.2, 1.7, 7),
        lg: fontGraphie(2.2, 1.7, 7),
        lg_1: fontGraphie(1.2, 1.4, 6),
        md: fontGraphie(1.6, 1.4, 7),
        sm: fontGraphie(1.6, 1.4, 7)
    },
    'tablet': {
        xxxl: fontGraphie(2.8, 2.4, 7),
        xxl: fontGraphie(2.2, 1.8, 7),
        xl: fontGraphie(2.2, 1.7, 7),
        lg: fontGraphie(2.2, 1.7, 7),
        lg_1: fontGraphie(1.2, 1.4, 6),
        md: fontGraphie(1.6, 1.4, 7),
        sm: fontGraphie(1.6, 1.4, 7)
    },
    'desktop': {
        xxxl: fontGraphie(2.8, 2.4, 7),
        xxl: fontGraphie(2.2, 1.8, 7),
        xl: fontGraphie(2.2, 1.8, 7),
        lg: fontGraphie(2.2, 1.8, 7),
        lg_1: fontGraphie(1.2, 1.4, 6),
        md: fontGraphie(1.6, 1.4, 7),
        sm: fontGraphie(1.6, 1.4, 7)
    }
};
export var descriptionFontSizes = {
    'mobile': {
        xxxl: fontGraphie(2, 1.6, 6),
        xxl: fontGraphie(2.2, 1.4, 6),
        xl: fontGraphie(1.6, 1.3, 6),
        lg: fontGraphie(1.6, 1.3, 6),
        lg_1: fontGraphie(1.6, 1.3, 6),
        md: fontGraphie(1.4, 1.1, 4),
        sm: fontGraphie(1.4, 1.1, 4)
    },
    'tablet': {
        xxxl: fontGraphie(2, 1.6, 6),
        xxl: fontGraphie(2.2, 1.4, 6),
        xl: fontGraphie(1.6, 1.3, 6),
        lg_1: fontGraphie(1.6, 1.3, 6),
        lg: fontGraphie(1.6, 1.3, 6),
        md: fontGraphie(1.4, 1.1, 4),
        sm: fontGraphie(1.4, 1.1, 4)
    },
    'desktop': {
        xxxl: fontGraphie(2, 1.6, 6),
        xxl: fontGraphie(2.2, 1.4, 6),
        xl: fontGraphie(1.6, 1.4, 6),
        lg_1: fontGraphie(1.6, 1.4, 6),
        lg: fontGraphie(1.6, 1.4, 6),
        md: fontGraphie(1.4, 1.1, 4),
        sm: fontGraphie(1.4, 1.1, 4)
    }
};
// Create User Dashboard Config
export var findOrCreateUserDashboardConfig = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var userDashboardData;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, userDashboard({
                    "studentId": convertToUUIDFormat((_a = params === null || params === void 0 ? void 0 : params.student) === null || _a === void 0 ? void 0 : _a.id)
                })];
            case 1:
                userDashboardData = _c.sent();
                if (!(!(userDashboardData === null || userDashboardData === void 0 ? void 0 : userDashboardData.userDashboard) || (userDashboardData === null || userDashboardData === void 0 ? void 0 : userDashboardData.userDashboard.dashboardCode) !== params.dashboardCode)) return [3 /*break*/, 3];
                return [4 /*yield*/, createUserDashboard({
                        "attributes": {
                            "studentId": convertToUUIDFormat((_b = params === null || params === void 0 ? void 0 : params.student) === null || _b === void 0 ? void 0 : _b.id),
                            "dashboardCode": params === null || params === void 0 ? void 0 : params.dashboardCode
                        },
                    })];
            case 2:
                _c.sent();
                _c.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
// Create User Dashboard Config
export var getUserDashboardConfig = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, userDashboard({
                    "studentId": convertToUUIDFormat((_a = params === null || params === void 0 ? void 0 : params.student) === null || _a === void 0 ? void 0 : _a.id),
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}); };
export var fetchDashboardFeaturesValue = function (activeTabCode) {
    var values = [];
    var isNewCurriculumUi = true; // Currently new Curriculum UI design is set for all users
    if (isDRSWithCategoryExpansion()) {
        values.push("category_expansion");
    }
    if (isNewCurriculumUi) {
        values.push("new_curriculum_ui");
    }
    if (activeTabCode === tabNames.home && isDynamicPositioningAssigned()) {
        values.push("dynamic_position_of_rails");
    }
    if (canShowCatexMigrationScreenValue('get') == 'true') {
        values.push("migrate_to_catex");
    }
    if (isNewCoqAssigned()) {
        values.push("coq_based_recommendation");
    }
    if (isDashboardCachingEnabled()) {
        values.push("enable_dashboard_caching");
    }
    values.push("time_wise_class");
    values.push("upcoming_live_class"); // to get live class banner on home tab
    values.push("remove_home_live_class"); // remove live classes list from home tab
    values.push("card_progress_on_home"); // To show progress of CGs on home tab
    values.push("new_math_fact_ui"); // New feature name for math_fact ui change
    values.push("summer-special");
    values.push("new_gamezone_categories");
    values.push("enable_prek_live_class");
    values.push("enable_school_hour_parent_live_class");
    values.push("enable_monthly_mashup_qa");
    return values;
};
//Get Freemium Dashboard Config
export var fetchDashboardElementsData = function (params, queryCode, useDefaultStaleTime) { return __awaiter(void 0, void 0, void 0, function () {
    var now, createdAt, daySinceStudentCreation, searchedKeywordIds, searchedKeywords, signupPageUrl, coqAnswers, queryFreemiumDashboardConfigParams, promises, data, err_1, errorInfo;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 2, , 3]);
                now = new Date(new Date().toUTCString());
                createdAt = new Date((_a = params === null || params === void 0 ? void 0 : params.current_user) === null || _a === void 0 ? void 0 : _a.created_at);
                daySinceStudentCreation = calculateDaysBetweenTwoDates(now, createdAt);
                searchedKeywordIds = getItemFromSessionStorage('ad_group_id') || [];
                searchedKeywords = getItemFromSessionStorage('landing_page_url') || [];
                signupPageUrl = getItemFromSessionStorage('signup_page_url') || '';
                coqAnswers = getItemFromSessionStorage('coq_answers') ? [getItemFromSessionStorage('coq_answers')] : [];
                queryFreemiumDashboardConfigParams = {
                    "studentId": (_b = params === null || params === void 0 ? void 0 : params.student) === null || _b === void 0 ? void 0 : _b.id,
                    "isClassroomLinked": params.settings.is_classroom_linked,
                    "preferredLanguage": params.current_user.preferred_language || "en",
                    "daySinceStudentCreation": daySinceStudentCreation,
                    "isClassroomStudent": params.settings.is_classroom_student,
                    "loggedInGuardianType": params.settings.logged_in_guardian_type,
                    "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
                    "tabCode": params.activeHeaderTabId || "",
                    "dynamicHeader": params.dynamicHeader || false,
                    "platform": getDeviceType(),
                    'countryCode': getUserCountryCode() || "IN",
                    "parentId": params.parentId,
                    "isPaidUser": params.isPaidUser,
                    "email": params.email,
                    "dashboardFeatures": fetchDashboardFeaturesValue(params.activeHeaderTabId || ""),
                    "searchedKeywordIds": searchedKeywordIds,
                    "searchedKeywords": searchedKeywords,
                    "signupPageUrl": signupPageUrl,
                    "coqAnswers": coqAnswers,
                    "loginMode": ((_d = (_c = params.settings) === null || _c === void 0 ? void 0 : _c.restriction_info) === null || _d === void 0 ? void 0 : _d.selected_mode) || "",
                    "startDateTimeUtc": getStartingMondayOfWeekDateTimeinUtc()
                };
                promises = [
                    querySeedData(),
                    queryFreemiumDashboardConfig(queryFreemiumDashboardConfigParams, queryCode, useDefaultStaleTime)
                ];
                return [4 /*yield*/, Promise.all(promises)];
            case 1:
                data = _e.sent();
                return [2 /*return*/, __assign(__assign({}, data[0]), data[1])];
            case 2:
                err_1 = _e.sent();
                console.log(err_1);
                errorInfo = {
                    extraMsg: 'queryFreemiumDashboardConfig or querySeedData failed',
                };
                sendErrorToSentry(err_1, errorInfo);
                return [2 /*return*/, {}];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var removeHeaderTabSessionData = function () {
    sessionStorage.removeItem('activeTabCode');
    sessionStorage.removeItem('headerTabOrdering');
};
//Update activity status data in store
export var updateActivityStatusData = function (studentId, time_zone) { return __awaiter(void 0, void 0, void 0, function () {
    var params, data, dataToUpdate, _a, dataToUpdate;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                params = {
                    user_id: studentId,
                    timezone: time_zone
                };
                return [4 /*yield*/, CanPlayFromCurriculum(params)];
            case 1:
                data = _b.sent();
                dataToUpdate = { 'totalFreeCardCount': data.total_free_card_count, 'remainingCardCount': data.remaining_card_count, 'isDataLoaded': true };
                Store.dispatch({ type: 'SET_ACTIVITY_INFORMATION', payload: dataToUpdate });
                sessionStorage.setItem("".concat(studentId, "_free_cards_left"), data.remaining_card_count);
                return [3 /*break*/, 3];
            case 2:
                _a = _b.sent();
                dataToUpdate = { 'totalFreeCardCount': 0, 'remainingCardCount': 0, 'isDataLoaded': true };
                Store.dispatch({ type: 'SET_ACTIVITY_INFORMATION', payload: dataToUpdate });
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
//Generate unique query code
export var getQueryCode = function (code, studentId, mathGrade, elaGrade) {
    return "".concat(code + studentId + mathGrade + elaGrade);
};
// Get dashboard code
export var getDashboardCode = function () {
    var dashboardCode;
    if (getItemFromLocalStorage("is_classroom_student") || localStorage.getItem('isPtlSignupFlow') == 'classroom_linked') {
        dashboardCode = 'Topics_Out_On_DRS';
    }
    else if (getDRSLiveClassAbTestAssigned() == 'DRSWithLiveClasses') {
        dashboardCode = 'DRSWithLiveClasses';
    }
    else {
        dashboardCode = getFreemiumAbTestAssigned();
    }
    return dashboardCode;
};
export var getLiveClassesData = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var queryFreemiumDashboardConfigParams, liveClassData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                queryFreemiumDashboardConfigParams = {
                    "studentId": params === null || params === void 0 ? void 0 : params.studentId,
                    "isPaidUser": params.isPaidUser,
                    "parentId": params.parentId,
                    "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
                    "email": params.email,
                    "dashboardFeatures": ["time_wise_class"],
                    "startDateTimeUtc": getStartingMondayOfWeekDateTimeinUtc()
                };
                return [4 /*yield*/, liveClassQuery(queryFreemiumDashboardConfigParams)];
            case 1:
                liveClassData = _a.sent();
                return [2 /*return*/, liveClassData];
        }
    });
}); };
/**
 * Fetches student class details from the GraphQL API.
 * @param {any} params - Parameters for fetching student details.
 * @returns {Promise<any>} - Promise resolving to the fetched data.
 */
export var getStudentklassDetails = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, studentKlassDetails({
                    "studentId": convertToUUIDFormat(params),
                })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
