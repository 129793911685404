import React from 'react';
import * as S from './style';
import { Heading2 } from '../../shared-styles/Font';

const grantlist = [
  'READ the grantor’s guidelines and instructions carefully.  Your program must be in line with the funding agency’s priorities.',
  'Grantors usually invest in supplemental programs. Try proposing a project that puts a fresh spin on an existing idea.',
  'Keep your goals realistic! It is important to have an evaluation plan.',
  'Do your homework on costs prior to submitting your application and be sure to explain your budget.',
];
const Grants = () => {
  return (
    <div className="content-container">
      <S.GrantFlex>
        <div>
          <S.GrantTitle>
            <Heading2>Tips for grants</Heading2>
            <p>
              The most important thing for grant-writers to remember is that
              they might submit a perfect application and still receive a
              rejection. Most foundations have limited resources with which to
              fund projects. Do not get discouraged if you get a rejection from
              a possible funding source.
            </p>
          </S.GrantTitle>
        </div>
        <div>
          <S.List>
            {grantlist.map((item) => {
              return (
                <S.ListItem>
                  <p>{item}</p>
                </S.ListItem>
              );
            })}
          </S.List>
          <S.ExtraInfo>
            At Splash Math, we can help you proof read the grant application and
            provide all the help interms of customer testimonials, product
            evaluation, pricing and all the necessities required to apply and
            get the funding. You can follow the link for more tips on grant
            writing.
          </S.ExtraInfo>
        </div>
      </S.GrantFlex>
    </div>
  );
};
export default Grants;
