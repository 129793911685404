export const kMessageMinimizeHeader = 'minimize_header';
export const kMessageSendScrollEvent = 'send_scroll_event';
export const kMessageBuildLoaded = 'flutter_build_loaded';
export const kMessageRedirect = 'redirect_to';
export const kMessageRedirectUsingHref = 'redirect_with_href';
export const kMessageOpenInTab = 'open_link_on_new_tab';
export const kSendMessageString = 'send_event';
export const kMessageListenFlutterEvent = 'listenFlutterEvent';
/// Open settings page
export const kMessageOpenSettingsPage = 'open_settings_page';
/// Open child profile page
export const kMessageOpenChildProfilePage = 'open_child_profile_page';
/// Open reports page
export const kMessageOpenReportsPage = 'open_reports_page';
/// Open Menu
export const kMessageOpenMenu = 'open_menu';
/// Open purchase page
export const kMessageOpenPurchasePage = 'open_purchase_page';
// Click handler for tiles
export const kMessageTileClickHandler = 'tile_click_handler';
// close class invites popup
export const kMessageClosePopup = 'close_popup';
// reload screen
export const kMessageReloadScreen = 'reload_screen';
export const kMessageBlurHeader = 'blur_header';
// Click handler for game mode
export const kMessageMultiplayerGameLauncher = 'multiplayer_game_launcher';
// Click handler for game invite
export const kJoinGameInvite = 'join_game_invite';
// click handler for click on live class in school hours
export const kMessageLiveClassPopupLauncher = "show_play_at_home_popup";
//resets show popup value
export const kResetShowPopup = 'resetShowPopup';

//search result redirect
export const kMessageSearchResultRedirect = "search_result_redirect";
/// Open help page
export const kMessageOpenHelpPage = 'open_help_page';

/// send live class visible event
export const KMessageSendLiveClassVisibleEvent = 'liveClassTabVisible';

/// set dashboard loaded
export const kSkipCoqSurveyCall = 'skipCoqSurveyCall';

/// set window key as true to close preview popup in school ui service
export const kMessageCloseWsPreviewPopup = 'closeWsPreviewPopup';

export const kResetIsLogin = 'kResetIsLogin';

export const KMessageSignout = 'sign_out';

export const KMessageSpinnerStatus = 'spinner_status';

export const kKeyboardFocus = 'keyboard_focus_on';
export const kMessageGoToVerifyEmail = 'goToVerifyEmail';

//resets show default tab value
export const kResetDefaultTabValue = 'resetDefaultTabValue';

//update course status and hash
export const kUpdateHash = 'update_hash';
export const kShowGamesUnsupportedScreen = 'show_games_unsupported_screen';