import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Heading2} from '../../components/loggedout/shared-styles/Font';
import {whiteLilac} from '../../components/loggedout/shared-styles/Colors';
import {device} from '../../utils/responsive_utilities';
import { assetPath } from '../../utils/web-helper';

const TermsOfUse = () => {
  const [html, setHtml] = useState('');
  useEffect(() => {
    fetch(assetPath('public/tnc.html'))
      .then((res) => {
        return res.text();
      })
      .then((html) => setHtml(html));
  }, []);
  return (
    <>
      <TitleWrapper className="container">
        <Title>
          <Heading2>Terms of Use</Heading2>
        </Title>
      </TitleWrapper>
      <div dangerouslySetInnerHTML={{ __html: html }}/>
    </>
  );
};

const TitleWrapper = styled.div`
  padding-top: 25px;
`
const Title = styled.div`
  width: 30rem;
  height: 6rem;
  background-color: ${whiteLilac};
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.PhoneTablet} {
    margin: auto;
  }
`

export default TermsOfUse;
