import React from 'react';
import * as S from './style';
import Image from '../Image';
import { Heading2 } from '../../shared-styles/Font';

const SignupCard = ({ text, icon, ...props}) => {
  return (
    <S.Card {...props} className="signup-a">
      <Image src={icon} alt="signup-icon"/>
      <Heading2>{text}</Heading2>
    </S.Card>
  );
};

export default SignupCard;
