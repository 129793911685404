import React from 'react';
import styled from 'styled-components';
import { Heading3 } from '../components/loggedout/shared-styles/Font';
import { sendErrorToSentry } from './web-helper';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({error:error, errorInfo: errorInfo });
    console.log('error', errorInfo);

    //Reporting error to sentry
    const eventId = sendErrorToSentry(error, errorInfo);
    this.setState({ eventId, errorInfo });

  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Wrapper>
          <Heading3 style={{ display: 'inline-block' }}>
            Something went wrong.
          </Heading3>
          &nbsp;&nbsp;<a href="mailto:help@splashlearn.com">Contact Support</a>
          {/* <details style={{ whiteSpace: 'pre-wrap', marginTop: '30px' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details> */}
        </Wrapper>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const Wrapper = styled.div`
  margin: 100px;
  border: 1px solid #cacad8;
  padding: 1.5rem;
`;

export default ErrorBoundary;
