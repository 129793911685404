import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Switch,
  BrowserRouter,
  withRouter,
} from 'react-router-dom';
import Route from './cpc-route';
import Home from '../screens/loggedout/home';
import ThankYouPage from '../screens/loggedout/thank-you-page';
import TermsOfUse from '../screens/loggedout/terms-of-use';
import Privacy from '../screens/loggedout/privacy';
import EducatorMembership from '../screens/loggedout/educator-membership';
import NewSigninPage from '../screens/loggedout/new_signin';
import ForgotPassword from '../screens/loggedout/forgot_password';
import Footer from '../components/loggedout/Footer';
import Loader from '../components/common/loader';
const Parent = React.lazy(() => import('../screens/loggedout/parent'));
const Educator = React.lazy(() => import('../screens/loggedout/educator'));
const SuccessStories = React.lazy(() => import('../screens/loggedout/success-stories'));
const SignupPage = React.lazy(() => import('../screens/loggedout/signup'));
import {
  HOME_PATH,
  PARENT_PATH,
  EDUCATOR_PATH,
  SUCCESS_STORIES_PATH,
  PRIVACY_PATH,
  TERMS_OF_USE_PATH,
  EDUCATOR_MEMBERSHIP_PATH,
  SIGNUP_PATH,
  SIGNIN_PATH,
  STUDENT_PATH,
  STUDENT_SIGNIN_PATH,
  PARENT_SIGNIN_PATH,
  TEACHER_SIGNIN_PATH,
  FORGOT_PASSWORD_PATH,
  SCHOOL_ADMIN_INVITATION_RESPONSE_PREFIX_PATH
} from './route-paths';

import { ABTestDetails } from '../services/loggedout-api' ;
import { isIOSorMacDevice, isMobileDevice, isTabletDevice, sendErrorToSentry, assetPath } from '../utils/web-helper';
import DarkBlueCurveImage from '~images/loggedout/home/modularPage/dark_blue_curve.svg';
import DarkBlueEllipseImage from '~images/loggedout/home/modularPage/modular_ellipse.png';
import DarkBlueEllipseImageMd from '~images/loggedout/home/modularPage/modular_ellipse2.png';
import { breakpoints } from '../utils/responsive_utilities';

const LoggedoutRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={<Loader/>}>
          <Switch>
            <Route component={MainLayout} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
};

const MainLayout = (props) => {
  const { location } = props;
  const notAHomePage = location.pathname !== HOME_PATH;
  const noFooterCheck = location.pathname === SIGNUP_PATH || location.pathname === SIGNIN_PATH;
  const [videoVariantEnabled, setVideoVariantEnabled] = useState({});
  const [abTestDataLoaded, setAbTestDataLoaded] = useState(false);

  const fetchABTestDetailsData = async () => {
    try {
      let abTests = await ABTestDetails();
      setVideoVariantEnabled(abTests);
      setAbTestDataLoaded(true);
    }
    catch(err) {
      console.log('Error loading data.');
      let errorInfo = {
        extraMsg: 'ABTestDetails Api failed',
      };
      sendErrorToSentry(err,errorInfo)
      setAbTestDataLoaded(true);
    }
  }

  useEffect(() => {
    fetchABTestDetailsData();
    let searchedKeywordIds = getAdGroupID();
    if(searchedKeywordIds && !sessionStorage.getItem('ad_group_id')) {
        sessionStorage.setItem('ad_group_id', JSON.stringify([searchedKeywordIds]));
    }
  }, []);

  const isMobile = isMobileDevice();
  const isTablet = isTabletDevice();
  let isIos = isIOSorMacDevice();
  const showAppDownloadCTA = videoVariantEnabled && videoVariantEnabled.show_app_download_cta && (isMobile || isTablet)
  let downloadLink = showAppDownloadCTA && videoVariantEnabled.ad_links? (isIos ? videoVariantEnabled.ad_links.ios : videoVariantEnabled.ad_links.android) : "";
  const appDownloadCTA={showAppDownloadCTA, downloadLink} 

  return (
    <>
      {/* 
      /// Commenting this header import as header-v2 is rendered from Web repo
      { abTestDataLoaded && 
        <Header appDownloadCTA={appDownloadCTA} abTestsDetails={videoVariantEnabled} />
      } 
      */}
      <MainContent padding={notAHomePage}>
        <ScrollTop />
        
        <Switch>
          <>
            {
              abTestDataLoaded && (
              <>
                <Route exact path={HOME_PATH} component={(props) => <Home {...props} abTestsDetails={videoVariantEnabled} appDownloadCTA={appDownloadCTA}/>} />
                <Route path={PARENT_PATH} component={(props) => <Parent {...props} abTestsDetails={videoVariantEnabled}  appDownloadCTA={appDownloadCTA}/>} />
                <Route path={EDUCATOR_PATH} component={(props)=> <Educator {...props} abTestsDetails={videoVariantEnabled}/>}/>
              </>
              )
            }
            <Route path={SCHOOL_ADMIN_INVITATION_RESPONSE_PREFIX_PATH} component={ThankYouPage} />
            <Route path={SUCCESS_STORIES_PATH} component={SuccessStories} />
            <Route path={PRIVACY_PATH} component={Privacy} />
            <Route path={TERMS_OF_USE_PATH} component={TermsOfUse} />
            <Route
              path={EDUCATOR_MEMBERSHIP_PATH}
              component={EducatorMembership}
            />
            <Route path={SIGNUP_PATH} component={(props) => <SignupPage {...props} abTestsDetails={videoVariantEnabled}/>} />
            <Route path={SIGNIN_PATH} component={(props) => <NewSigninPage {...props} userType={new URL(window.location.href).searchParams.get('userType')} /> } />
            <Route path={FORGOT_PASSWORD_PATH} component={ForgotPassword} />
            <Route path={STUDENT_PATH} component={NewSigninPage} />
            <Route path={STUDENT_SIGNIN_PATH} component={NewSigninPage} />
            <Route path={PARENT_SIGNIN_PATH} component={(props) => <NewSigninPage {...props} userType='parent' />} />
            <Route path={TEACHER_SIGNIN_PATH} component={(props) => <NewSigninPage {...props} userType='teacher' />} />
          </>
        </Switch>
        
      </MainContent>
      {!noFooterCheck && abTestDataLoaded && (
        <TopBlueCurveBorderWrap className='no-margin-top'>
          <FooterSection>
            <Footer />
          </FooterSection>
        </TopBlueCurveBorderWrap>
      )}
    </>
  );
};

const ScrollTop = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scroll(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);
  return null;
});

const getAdGroupID = () => {
  let adGroupID = new URL(window.location.href).searchParams.get('adGroup');
  return adGroupID;
};


const MainContent = styled.div`
  padding-top: ${(props) => (props.padding ? '80px' : 0)};
`;

export const TopBlueCurveBorderWrap = styled.div`
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  margin-top: 12rem;
  &:after {
    content: '';
    background-image: url(${assetPath(DarkBlueCurveImage)});
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center top;
    height: 12.0rem;
    left: 0;
    right: 0;
    bottom: 100%;
    position: absolute;
    z-index: -1;
  }
  &.no-margin-top {
    margin-top: 0;
  }
  
  
  @media screen and (max-width:${breakpoints.md}px) {
    &:after {
      content: '';
      background-image: url(${assetPath(DarkBlueEllipseImage)});
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center top;
      height: 12.0rem;
      left: 0;
      right: 0;
      bottom: 97%;
      position: absolute;
      z-index: -1;
    }
  }
  @media screen and (min-width:${breakpoints.sm}px) and (max-width:600px) {
    &:after {
      background-image: url(${assetPath(DarkBlueEllipseImage)});
      bottom:96%;
    }
  }
  @media screen and (max-width:350px) {
    &:after {
      background-image: url(${assetPath(DarkBlueEllipseImage)});
      bottom:96.8%;
    }
  }
  @media screen and (min-width:${breakpoints.md}px) and (max-width:1280px) {
    &:after {
      content: '';
      background-image: url(${assetPath(DarkBlueEllipseImageMd)});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top;
      height: 12.0rem;
      left: 0;
      right: 0;
      bottom: 94%;
      position: absolute;
      z-index: -1;
    }
  }
  @media screen and (min-width:1175px) and (max-width:1280px) {
    &:after {
      bottom:92%
    }
  }
  `;

const FooterSection = styled.div`
  margin-top: 0;
`;

export default LoggedoutRoutes;