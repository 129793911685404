import GlobalSpinner from './GlobalLoader';

var apiCaller = function(config){

  const EXT_SERVICE_MODE = 'cors';
  const EXT_SERVICE_CREDENTIALS = 'include';

  var url = config.path;
  var methodType = config.method || 'get';
  const extServiceFlag = config.extServiceFlag;

  var params = config.params || {};
  var restrictConversionToJson = config.restrictConversionToJson;
  var requestHeaders = config.requestHeaders;
  function status(response) {
    if (!config.hideSpinner)
      GlobalSpinner.show(false);
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response)
    } else {
      // user is logged out.
      if (url.startsWith('/users/sign_in.json')) {
        return Promise.resolve(response)
      }
      if(response.status == 401){
        trackAnalyticsEvent('Api status 401', {'Api path': url});
      }
      if(response.status == 401 && !config.doNotRedirect){
        window.location = '/';
      }else{
        if (config.allow_non_ok_response) {
          return response;
        }
        else if(params.rejectWithErrorObject){
          return response.json()
          .then( resp=> Promise.reject({response: resp , json: true}))
          .catch(err=>{
            if(err.json && err.response){
              // this means successful json parsing of error
              return Promise.reject(err.response);
            }
            // json parsing failed hence error text will return
            return response.text().then(resp => Promise.reject(resp))
          })
        }
        else
          return Promise.reject(new Error(response.statusText))
      }
    }
  }

  function json(response) {
    if (!restrictConversionToJson)
      return response.json();
    return response;
  }

  var requestConfig = {
    method: methodType,
    credentials: "same-origin",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "X-Requested-With": "XMLHttpRequest",
      ...requestHeaders
    },
    // ...requestHeaders
  }

  if (extServiceFlag) {
    requestConfig['mode'] = EXT_SERVICE_MODE;
    requestConfig['credentials'] = EXT_SERVICE_CREDENTIALS;
  }

  if(methodType != 'get'){
    var tokenElement = document.querySelector('[name=csrf-token]');
    if(!!tokenElement){
      requestConfig.headers['X-CSRF-Token'] = tokenElement.getAttribute('content');
    }
  }

  if(methodType != 'get' && !!params){
    requestConfig.body = JSON.stringify(params);
  }

  if(methodType == 'get' && !!params){
    const esc = encodeURIComponent;
    var query = Object.keys(params).map(k => esc(k) + '=' + esc(params[k])).join('&');
    url += "?" + query;
  }
  // hideSpinner is deprecated
  if (!config.hideSpinner)
    GlobalSpinner.show(true);

  return fetch(url, requestConfig)
    .then(status)
    .then(json)
}

export default apiCaller;