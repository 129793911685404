import React from 'react';
import Image from "../common/Image";
import FBIcon from '~images/loggedout/icons/facebook_icon.png'
import GoogleIcon from '~images/loggedout/icons/google_icon.png'
import AppleIcon from '~images/loggedout/icons/apple_icon.png'
import CleverIcon from '~images/loggedout/icons/clever_icon.png'
 
const SocialLinks = ({apple, clever, facebook, userType}) => {
  return (
    <div className="social-login-button-container">
      <SocialBox text="Login with Google" icon={GoogleIcon} redirection={'/users/auth/google_oauth2?source=web&type=' + userType}/>
      {facebook && <SocialBox text="Login with Facebook" icon={FBIcon} redirection={'/users/auth/facebook?source=web&type=' + userType}/>}
      {apple && <SocialBox text="Login with Apple" icon={AppleIcon} redirection={'/users/auth/apple?source=web&type=' + userType}/>}
      {clever && <SocialBox text="Login with Clever" icon={CleverIcon} redirection='https://clever.com/oauth/authorize?response_type=code&amp;redirect_uri=https://splashmath.com/users/auth/clever&amp;client_id=5f1af460a51560d91249'/>}
      <p className="social-or-line">
        <span>OR</span>
      </p>
    </div>
  )
}

const SocialBox = ({text, icon, redirection}) => {
  return (
    <a href={redirection}>
      <Image src={icon} alt="social_icon"/>
      <p>{text}</p>
    </a>
  )
}

export default SocialLinks;