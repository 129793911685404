import styled from 'styled-components'
import {mischka, midGrayDark} from '../../shared-styles/Colors';
import { getWidthRange, breakpoints } from '../../../../utils/responsive_utilities';

export const GrantFlex = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    flex-basis: 50%;
  }
  @media ${getWidthRange(0, breakpoints.lg)} {
    flex-wrap: wrap;
    & > div {
      flex-basis: 100%;
    }
  }
`
export const GrantTitle = styled.div`
  width: 80%;
  & > p {
    margin-top: 24px;
  }
  @media ${getWidthRange(0, breakpoints.lg)} {
    width: 100%;
    text-align: center;
    & > p {
      margin-bottom: 24px;
    }
  }
`
export const List = styled.ol`
  counter-reset: number;
  list-style-type: none;
`
export const ListItem = styled.li`
  & > p {
    margin-left: 35px;
    padding: 2.6rem 0 2.7rem;
    border-bottom: 1px solid ${mischka};
    position: relative;
  }
  &:nth-of-type(1) > p {
    border-top: 1px solid ${mischka};
  }
  & > p:before {
    counter-increment: number;
    content: counter(number)"\\a0";
    position: absolute;
    left: -35px;
  }
  @media ${getWidthRange(0, breakpoints.lg)} {
    border-bottom: 1px solid ${mischka};
    &:nth-of-type(1) {
      border-top: 1px solid ${mischka};
    }
    && > p {
      border: 0;
    }
  }
`
export const ExtraInfo = styled.p`
  margin-top: 34px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.28px;
  color: ${midGrayDark};
  @media ${getWidthRange(0, breakpoints.lg)} {
    text-align: center;
  }
`