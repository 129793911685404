import { FetchABTestsOfUsers } from '../services/student-api';
import { TEACHER_AB_TEST } from '../constant-data/constants';

export function setNewABTests(response) {
  const abTests = {};

  for (const key in response) {
    const [typeName, abTestName] = key.split('::');

    if (abTests.hasOwnProperty(typeName)) {
      abTests[typeName][abTestName] = response[key];
    } else {
      abTests[typeName] = { [abTestName]: response[key] };
    }
  }
  saveInLS(abTests);
}

function saveInLS(resp) {
  try {
    localStorage.setItem('newABTests', JSON.stringify(resp));
  } catch (err) {
    return err;
  }
}

export function getABTestValue(userType, abTestName) {
  const abTests = JSON.parse(localStorage.getItem('newABTests'));

  if (abTests && abTests[userType] && abTests[userType][abTestName]) {
    return abTests[userType][abTestName];
  }
  return null;
}


export async function abTestsOfUsers(userIds) {
  const requestBody={users:userIds.filter((user)=>user.id!=="")}
 
try {
  const abTestsResponse = await FetchABTestsOfUsers(requestBody);
  const abTestsDetails = {};
  for (let key of userIds) {
    abTestsDetails[key.type] = abTestsResponse[key.id];
  }
  if(abTestsDetails.teacher){
    localStorage.setItem(TEACHER_AB_TEST, JSON.stringify(abTestsDetails.teacher));
  }
  else{
    localStorage.setItem(TEACHER_AB_TEST, null);
  }
  setNewABTests({...abTestsDetails.teacher, ... abTestsDetails.parent})
  return abTestsDetails;
} catch (err) {
  return err
}
}

export function getTeacherABTestValue(abTestName) {
  const abTests = JSON.parse(localStorage.getItem(TEACHER_AB_TEST) || null);
  if (abTests && abTests[abTestName]) {
    return abTests[abTestName];
  }
  return null;
}