import styled from 'styled-components';
import {deepCoveDark, lightWhite, cadetBlue} from '../shared-styles/Colors';
import {device, getWidthRange, breakpoints} from '../../../utils/responsive_utilities';

export const Wrapper = styled.div`
  width: 100%;
  background: ${deepCoveDark};
  padding: 0 0 12rem;
  .content-container {
    position: relative;
  }
`
export const Flex1 = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const List = styled.ul`
  padding: 0 10px 1rem 0;
  max-width: calc(100% / 6);
  min-width: 220px;
  @media ${getWidthRange(0, breakpoints.lg)} {
    max-width: calc(100% / 4);
  }
  @media ${device.Phone} {
    margin-bottom: 20px;
    padding: 0;
    max-width: none;
    width: 100%;
    text-align: center;
  }
  > li:first-child {
      cursor: default;
  }
`

export const ListItem = styled.li`
  font-size: 14px;
  color: ${props => props.sublink ? cadetBlue : lightWhite};
  cursor: pointer;
  margin-bottom: ${props => props.sublink ? "6px" : "8px"};
  > a {
    color: ${props => props.sublink ? cadetBlue : lightWhite};
    text-decoration: none;
     &:hover {
      text-decoration: underline;
    }
  }
`
export const Trademarks = styled.p`
  font-size: 14px;
  color: ${lightWhite};
  margin-top: 6px;
`

export const Flex2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  align-items: center;
  & span {
    display: inline;
  }
  @media ${getWidthRange(0, breakpoints.xl)} {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    & > div:nth-child(1) {
      width: 80%;

    }
    & > div:nth-child(2) {
      width: 100%;
      margin-bottom: 3rem;
    }
  }
`

export const Line = styled.div`
  height: 1px;
  background: ${lightWhite};
  margin-top: 20px;
`
export const KidsafeDiv = styled.div`
  display: flex;
  flex-direction: column;
  &.gift {
    position: absolute;
    bottom: 12.4rem;
    right: 0;
  }
  img {
    width: 16rem;
  }
  @media ${getWidthRange(0, breakpoints.xl)} {
    && {
      width: 100%;
      justify-content: center;
      margin: 0;
      margin-top: 4rem;
      &.gift {
        bottom: 23rem;
      }
    }
    & > a {
      margin: auto;
    }
  }
  @media ${device.Phone} {
    &.gift {
      position: initial;
    }
  }
`

export const OddbodsTrademark = styled.div`
  margin-top: 1rem;
`