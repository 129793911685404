import apiCaller from '../utils/api-caller';
import {mapByCode} from '../utils/content-mapper';
import {setCookieWithAge} from '../utils/web-helper';
export const fetchCoursesApi = async (request) => {
  const response = await apiCaller({
    method: 'post',
    path: '/web_api/common/v1/courses'
  });

  return {
    subjectGradeMap: formatResponse(response),
    gradeIdMap: makeMap(response.grades),
    gradeCodeMap: mapByCode(response.grades),
    subjectIdMap: makeMap(response.subjects),
    contentGroupIdMap: makeMap(response.content_groups),
    subjectCodeMap: mapByCode(response.subjects),
  };
};

export function SetWeeklyScheduleLoggedoutApi(params){
  return apiCaller({method: 'post', path: '/set_weekly_schedule', params:params});
}

export function getStudentWeeklyScheduleDetails(params){
  return apiCaller({method: 'get', path: '/student_weekly_schedule_details', params:params});
}


const formatResponse = (response) => {
  const { courses } = response;
  let subjectGradeMap = new Map();
  courses.forEach((course) => {
    const { subject_id } = course;
    if (subjectGradeMap.has(subject_id)) {
      let grades = subjectGradeMap.get(subject_id);
      grades.push(course);
      subjectGradeMap.set(subject_id, grades);
    } else {
      subjectGradeMap.set(subject_id, [course]);
    }
  });
  // sort the grade array corresponding to a subject based on the ordering
  const gradeIdMap = makeMap(response.grades);
  for (let [subject, gradeList] of subjectGradeMap) {
    gradeList.sort(
      (a, b) =>
        gradeIdMap.get(a.grade_id).ordering -
        gradeIdMap.get(b.grade_id).ordering
    );
  }
  return subjectGradeMap;
};

// This function makes maps from arrays
// eg [{id:1, name: 'poki'}, {id: 2, name: "poki2"}] will change to Map{1: {id: 1, name: "poki"}, 2: {id: 2, name: "poki2"}}
// This is done for better lookups and search
function makeMap(arr) {
  const map = new Map();
  arr.forEach((item) => {
    map.set(item.id, item);
  });
  return map;
}


export function ABTestDetails() {
  return apiCaller({method: 'get', path: '/abtest_details'});
}

export function GuestUserCreation(params, hideSpinner) {
  setCookieWithAge('signup_page_url',window.location.pathname);
  return apiCaller({method: 'post', path: '/users.json?form_source=Signup Page', params:{user: {type: 'guest_parent'}, ...params}, hideSpinner})
}

export function CatalogProducts(params) {
  return apiCaller({
    method: 'get',
    path: `${BASE_API_GATEWAY_URL}/catalog-service/catalog/product_list`,
    params: params,
    extServiceFlag: true
  });
}

export function OfferData(params) {
  return apiCaller({
    method: 'get',
    path: `${BASE_API_GATEWAY_URL}/catalog-service/catalog/offer`,
    params: params,
    extServiceFlag: true
  });
}

export function SemSettings(params) {
  return apiCaller({method: 'get', path: '/sem_settings', params});
}

export function GetOnboardingQuestions(params, hideSpinner) {
  return apiCaller({method: 'get', path: '/web_api/common/v1/onboarding_survey', params: params, hideSpinner})
}

export function TrackUserActionAnalytics(params, hideSpinner) {
  return apiCaller({method: 'post', path: '/track_user_action_analytics', params: params, hideSpinner})
}

export function FetchUserIntentionSurvey(params, hideSpinner) {
  return apiCaller({method: 'get', path: '/web_api/common/v1/intention_survey', params: params, hideSpinner})
}

export function TutoringOnbaordingDetails(params, hideSpinner) {
  return apiCaller({method: 'get', path: '/web_api/common/v1/tutoring_onbaording', params: params, hideSpinner})
}

export function ABTestDetailsAfterLogin() {
  return apiCaller({ method: 'get', path: '/abtest_details_mentor_flow' });
}

export function GetAgeBasedSurvey(params) {
  return apiCaller({method: 'get', path: '/age_based_survey',params: params})
}

export function GetLoadThroughScreenData(params) {
  return apiCaller({method: 'get', path: '/web_api/common/v1/loadthrough_screen',params: params})
}

export function GetSurveyDetailsData(params) {
  return apiCaller({method: 'get', path: '/web_api/common/v1/survey_details',params: params})
}

export function SubmitSurvey(params) {
  return apiCaller({method: 'post', path: '/web_api/common/student/v1/submit_survey',params: params})
}