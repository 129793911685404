import { getCoqAnswers, getItemFromSessionStorage } from "../utils/web-helper";
import { FLUTTER_SETTINGS } from "./constants";
var UserMarketingData = /** @class */ (function () {
    //Singleton class
    function UserMarketingData() {
        var computeLandingPageValue = getItemFromSessionStorage('landing_page_url');
        this.searchedKeywords = computeLandingPageValue ? [computeLandingPageValue] : [];
        this.searchedKeywordIds = getItemFromSessionStorage('ad_group_id') || [];
        this.coqAnswers = getCoqAnswers() ? [getCoqAnswers()] : [];
        this.signupPageUrl = getItemFromSessionStorage('signup_page_url') || '';
        // Init flutter settings
        if (!window.flutterSetting) {
            //@todo: Remove all this platform settings
            window[FLUTTER_SETTINGS] = {
                user_marketing_data: {
                    searched_keywords: this.searchedKeywords,
                    searched_keywords_ids: this.searchedKeywordIds,
                    coq_answers: this.coqAnswers,
                    signup_page_url: this.signupPageUrl,
                }
            };
        }
        else {
            window.flutterSetting.user_marketing_data = {
                searched_keywords: this.searchedKeywords,
                searched_keywords_ids: this.searchedKeywordIds,
                coq_answers: this.coqAnswers,
                signup_page_url: this.signupPageUrl,
            };
        }
        if (!UserMarketingData._instance) {
            UserMarketingData._instance = this;
        }
        return UserMarketingData._instance;
    }
    return UserMarketingData;
}());
export default UserMarketingData;
