import React from 'react';
import TickIconDarkPurple from '~images/loggedout/home/tick-icon-dark-purple.svg';
import { assetPath, getCDNAssetURL } from '../../../../../utils/web-helper';
import Image from '../../../common/Image';
import { PARENT_SIGNUP_PATH } from '../../../../../routes/route-paths';
import ModularImage from '~images/loggedout/home/reportstats_tablet.png'
import { breakpoints } from '../../../../../utils/responsive_utilities';
import { trackToGtmFE } from '../../../../../utils/gtm-event-common';
import i18n from '../../../../../utils/i18n';
const cdnAssetUrl = getCDNAssetURL();
const ProgressReportImage = `${cdnAssetUrl}/assets/homepage_images/progress-report.svg`;

const ModularSectionProgressReport = () => {

    const [showModularImage, setModularImage] = React.useState((window.innerWidth > breakpoints.xs && window.innerWidth <= 1279) ? ModularImage : ProgressReportImage);

    const handleCtaClick = (eventData) => {
        trackToGtmFE("clicked_other_signup_button",eventData);
    }
  
  function handleResize(){
    (window.innerWidth > breakpoints.xs && window.innerWidth <= 1279) ? setModularImage(ModularImage) : setModularImage(ProgressReportImage);
  }
  React.useEffect(()=>{
    //add resize listener
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
    
  },[])
    return (
        <div className="modular-section-progress-report-wrapper">
            <div className="head-text">
                The most comprehensive progress reports ever. Period.
            </div>
            <div className="progress-report-main-wrapper">
                <div className="left-text-wrap">
                    <ul className="progress-report-ul-list">
                        <li className="progress-report-li-list-item">
                            <img className = "tick-icon" src={assetPath(TickIconDarkPurple)} alt="tick-icon-dark-purple" style={{display:"none"}}/>
                            <h3>Weekly reports</h3>
                            <p>straight to your inbox</p>
                        </li>
                        <li className="progress-report-li-list-item">
                            <img className = "tick-icon" src={assetPath(TickIconDarkPurple)} alt="tick-icon-dark-purple" style={{display:"none"}}/>
                            <h3>Detailed progress</h3>
                            <p>broken down by subject, topic, skill and more</p>
                        </li>
                        <li className="progress-report-li-list-item">
                            <img className = "tick-icon" src={assetPath(TickIconDarkPurple)} alt="tick-icon-dark-purple" style={{display:"none"}}/>
                            <h3>{i18n.t('loggedout_homepage.Personalized')} practice goals & reminders</h3>
                            <p>to build a learning routine</p>
                        </li>
                    </ul>
                </div>
                <div className="right-img-wrap">
                    <Image isCdnImage={window.innerWidth > 1279 ? true : false}  src={showModularImage} alt="SplashLearn comprehensive progress reports for kids" className="progress-report-img" />
                </div>
                <div className="progress-report-primary-cta-button">
                    <a href={PARENT_SIGNUP_PATH} onClick={()=> {handleCtaClick({'Click Text': 'Try for free','Click URL': PARENT_SIGNUP_PATH})}}>
                        Try for free
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ModularSectionProgressReport;