var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { indigo, white, whiteLilac, royal, hollywoodCerise, deepCoveDark, fireFighter } from './Colors';
export var CTAPrimaryButton = styled.div.withConfig({ displayName: "CTAPrimaryButton", componentId: "sc-1hybg3c" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 600;\n  width: 30rem;\n  font-size: 1.8rem;\n  height: 56px;\n  line-height: 56px;\n  border-radius: 28px;\n  border: none;\n  cursor: pointer;\n  display: inline-block;\n  text-align: center;\n  ", ";\n  &:hover {\n    text-decoration: none;\n  }\n"], ["\n  font-weight: 600;\n  width: 30rem;\n  font-size: 1.8rem;\n  height: 56px;\n  line-height: 56px;\n  border-radius: 28px;\n  border: none;\n  cursor: pointer;\n  display: inline-block;\n  text-align: center;\n  ", ";\n  &:hover {\n    text-decoration: none;\n  }\n"])), function (props) {
    if (props.hollywoodCerise)
        return hollywoodCeriseButtonCss;
    else if (props.white)
        return whiteButtonCss;
    else if (props.darkBlue)
        return darkBlueButtonCss;
    else if (props.fireFighter)
        return fireFighterButtonCss;
    else
        return blueButtonCss;
});
export var whiteButtonCss = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  > a {\n    text-decoration: none;\n    width: 100%;\n    height: 100%;\n    display: block;\n    color: ", ";\n  }\n  &:hover {\n    background: ", "\n  }\n"], ["\n  background: ", ";\n  > a {\n    text-decoration: none;\n    width: 100%;\n    height: 100%;\n    display: block;\n    color: ", ";\n  }\n  &:hover {\n    background: ", "\n  }\n"])), white, function (props) { return props.isThankYou ? deepCoveDark : indigo; }, whiteLilac);
export var fireFighterButtonCss = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: transparent;\n  > a {\n    text-decoration: none;\n    color: ", ";\n    width: 100%;\n    height: 100%;\n    display: block;\n    border: 1px solid ", ";\n    border-radius: 28px;\n    &:hover {\n      border-color: ", ";\n    }\n  }\n"], ["\n  background: transparent;\n  > a {\n    text-decoration: none;\n    color: ", ";\n    width: 100%;\n    height: 100%;\n    display: block;\n    border: 1px solid ", ";\n    border-radius: 28px;\n    &:hover {\n      border-color: ", ";\n    }\n  }\n"])), white, fireFighter, white);
export var blueButtonCss = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  > a {\n    text-decoration: none;\n    color: ", ";\n    width: 100%;\n    height: 100%;\n    display: block;\n  }\n  &:hover {\n    background: ", "\n  }\n"], ["\n  background: ", ";\n  > a {\n    text-decoration: none;\n    color: ", ";\n    width: 100%;\n    height: 100%;\n    display: block;\n  }\n  &:hover {\n    background: ", "\n  }\n"])), indigo, white, royal);
export var darkBlueButtonCss = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: ", ";\n  > a {\n    text-decoration: none;\n    color: ", ";\n    width: 100%;\n    height: 100%;\n    display: block;\n  }\n  &:hover {\n    background: ", "\n  }\n"], ["\n  background: ", ";\n  > a {\n    text-decoration: none;\n    color: ", ";\n    width: 100%;\n    height: 100%;\n    display: block;\n  }\n  &:hover {\n    background: ", "\n  }\n"])), deepCoveDark, white, royal);
export var hollywoodCeriseButtonCss = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  > a {\n    text-decoration: none;\n    color: ", ";\n    width: 100%;\n    display: block;\n  }\n"], ["\n  background-color: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  > a {\n    text-decoration: none;\n    color: ", ";\n    width: 100%;\n    display: block;\n  }\n"])), hollywoodCerise, white);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
