import React, { useState } from 'react';
import {ForgotPasswordApi} from '../../services/login-api'
import isEmail from 'validator/lib/isEmail';


const ForgotPassword = () => {

  const inputFieldValueFocusedCorrect = 'FOCUSED_CORRECT';
  const inputFieldValueUnfocusedCorrect = 'UNFOCUSED_CORRECT';
  const inputFieldValueIncorrect = 'INCORRECT';

  const [email, setEmail] = useState('');
  const [emailState, setEmailState] = useState(inputFieldValueUnfocusedCorrect);
  const [errorMessage, setErrorMessage] = useState('');

  function clickOnForgotPassword() {
    if (isEmail(email)) {
      setErrorMessage(() => '');
      ForgotPasswordApi({user: {login: document.getElementsByClassName('forgot-password-input').item(0).value}})
      .then((response) => response.text())
      .then((resp) => {
        eval(resp);
      }).catch((exception) => {
        setErrorMessage('Something went wrong. Please try again.');
      });
    } else {
      setEmailState(inputFieldValueIncorrect);
      if (email.length === 0) {
        setErrorMessage("Can't be blank.");
      } else {
        setErrorMessage("Email is invalid");
      }
    }
  }

  function onEmailFocusOut(email) {
    if (email.length === 0) {
      setErrorMessage("Can't be blank.");
    } else {
      setEmailState(inputFieldValueUnfocusedCorrect);
    }
  }

  function checkEmailTextListener(e) {
    setEmail(e.target.value);
    setErrorMessage('');
    updateEmailInputFieldStyle(e.target.value);
  }

  function updateEmailInputFieldStyle(email) {
    if (email.length === 0) {
      setEmailState(inputFieldValueIncorrect);
    } else if (!isEmail(email)) {
      setEmailState(inputFieldValueIncorrect);
    } else {
      setEmailState(inputFieldValueFocusedCorrect);
    }
  }

    return (
        <div className="forgot_password_container">
          <h2 className="forgot-password-title">Reset Password</h2>
          <p className="forgot-password-content-description">You can only reset your password if you are parent or teacher. Students, please ask your parent/teacher to reset your password.</p>
          <div className="forgot-password-cta-container">
            <p className="content-identifier">Your Email</p>
            <input 
              className="forgot-password-input" 
              type="text"
              style={ (emailState === inputFieldValueFocusedCorrect) ? {border: '1px solid #4f52c3'} : (emailState === inputFieldValueUnfocusedCorrect) ? {border: '1px solid #cacad8'} : {border: '1px solid #d81430'} } 
              onFocus={(e) => updateEmailInputFieldStyle(e.target.value)}
              onBlur={(e) => onEmailFocusOut(e.target.value)}
              onChange={(e) => checkEmailTextListener(e)}
            />
            {
              errorMessage.length > 0 && <p className="js-error-login-form">{errorMessage}</p>
            }
            <button 
              id="reset-new-cta" 
              className="new-login-cta-button"
              onClick={(e) => clickOnForgotPassword()}>Reset Now</button>
          </div>
        </div>
    )
}

export default ForgotPassword;