import CallableMessages from "./CallableMessages";
import { FLUTTER_SETTINGS } from "./constants";
var UserService = /** @class */ (function () {
    //Singleton class
    function UserService() {
        // Init flutter settings
        if (!window.flutterSetting) {
            window[FLUTTER_SETTINGS] = {
                web_url: "".concat(window.location.origin, "/")
            };
        }
        else {
            window.flutterSetting.web_url = "".concat(window.location.origin, "/");
        }
        if (!UserService._instance) {
            UserService._instance = this;
        }
        return UserService._instance;
    }
    UserService.prototype.updateStudent = function (user) {
        if (!user)
            return;
        this.studentUser = user;
        this.studentUser.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (window.flutterSetting) {
            window.flutterSetting.student = this.studentUser;
            window.flutterSetting.web_url = "".concat(window.location.origin, "/");
        }
    };
    UserService.prototype.updateParent = function (user) {
        if (!user)
            return;
        this.parentUser = user;
        if (window.flutterSetting) {
            window.flutterSetting.parent = this.parentUser;
        }
    };
    UserService.prototype.reloadFlutterBuild = function () {
        new CallableMessages().reloadFlutterBuild();
    };
    return UserService;
}());
export default UserService;
