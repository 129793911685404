import { QueryClient } from "react-query";

const ReactQuerySingletonInstance = (function () {
  var instance;

  function createInstance() {
    return new QueryClient();
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    }
  };
})();

export default ReactQuerySingletonInstance;