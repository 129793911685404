import Lottie from 'lottie-web';
import FreemiumLoader from '../../json/freemium_loader.json';
//  a replacement for SPWidget to end the dependency from web 
var WidgetLoader = /** @class */ (function () {
    function WidgetLoader() {
        var loader = document.createElement('div');
        var subContainer = document.createElement('div');
        loader.id = 'widget-spinner';
        subContainer.id = 'widget-subcontainer';
        loader.classList.add('ajax_spinner');
        subContainer.classList.add('widget-subcontainer');
        var subEl = document.createElement('div');
        subEl.classList.add('freemium-spinner-sub');
        Lottie.loadAnimation({
            container: subEl,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: FreemiumLoader,
        });
        subContainer.appendChild(subEl);
        loader.appendChild(subContainer);
        loader.style.display = 'none';
        document.body.prepend(loader);
    }
    WidgetLoader.prototype.showSpinner = function () {
        var loader = document.getElementById('widget-spinner');
        if (loader) {
            loader.style.display = 'block';
        }
    };
    WidgetLoader.prototype.hideSpinner = function () {
        var loader = document.getElementById('widget-spinner');
        if (loader) {
            loader.style.display = 'none';
        }
    };
    return WidgetLoader;
}());
var WidgetSpinner = new WidgetLoader();
export default WidgetSpinner;
