import styled from 'styled-components';
import {alto, white, black} from '../../shared-styles/Colors';
import { getWidthRange, breakpoints } from '../../../../utils/responsive_utilities';

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${black};
    opacity: 0.6;
    z-index: -1;
  }
`
export const PopupBox = styled.div`
  position: absolute;
  max-width: 970px;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px ${alto};
  border-radius: 13px;
  padding: 40px;
  background-color: ${white};
  opacity: 1;
  @media (max-height: ${props => props.minHeight + 'px'}), ${getWidthRange(0, breakpoints.sm)} {
    padding: 18px;
    width: 100%;
    max-width: none;
    border-radius: 0;
    top: 0;
    left: 0;
    transform: none;
    position: fixed;
    height: 100vh;
  }
  & > .cross-icon {
    position: absolute;
    top: 17px;
    right: 17px;
    height: 17px;
    width: 17px;
    display: block;
    cursor: pointer;
  }
`