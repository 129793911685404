import i18n from 'i18next';

import defaultEnglish from "./translations/en/default.yml";
import learningObjectiveEnglish from "./translations/en/learning_objective.yml";

import deafultSpanish from "./translations/es/default.yml";
import learningObjectiveSpanish from "./translations/es/learning_objective.yml";

import deafultUk from "./translations/en_GB/default.yml";
import learningObjectiveUk from "./translations/en_GB/learning_objective.yml";

import deafultAu from "./translations/en_AU/default.yml";
import learningObjectiveAu from "./translations/en_AU/learning_objective.yml";

const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : null;
}

i18n.init({
  lng: localStorage.getItem('user_preferred_language') || getCookie('user_preferred_language') || 'en',
  debug: true,
  nsSeparator: '.',
  keySeparator: '.',
  resources: {
    en: {
      translation: {
        ...defaultEnglish,
        learning_objective: learningObjectiveEnglish
      }
    },
    es: {
      translation: {
        ...deafultSpanish,
        learning_objective: learningObjectiveSpanish
      }
    },
    'en-GB': {
      translation: {
        ...deafultUk,
        learning_objective: learningObjectiveUk
      }
    },
    'en-AU': {
      translation: {
        ...deafultAu,
        learning_objective: learningObjectiveAu
      }
    }
  }
});

export const initialiseWithLang = (language) => {
  i18n.init({
    lng: language || 'en',
    debug: true,
    nsSeparator: '.',
    keySeparator: '.',
    resources: {
      en: {
        translation: {
          ...defaultEnglish,
          learning_objective: learningObjectiveEnglish
        }
      },
      es: {
        translation: {
          ...deafultSpanish,
          learning_objective: learningObjectiveSpanish
        }
      },
      'en-GB': {
        translation: {
          ...deafultUk,
          learning_objective: learningObjectiveUk
        }
      },
      'en-AU': {
        translation: {
          ...deafultAu,
          learning_objective: learningObjectiveAu
        }
      }
    }
  });
}

export default i18n;