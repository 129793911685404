var secrets = require('../../../public_keys.json');
var cdnAssetUrl = secrets.cdn_asset_url;
var a1 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-1.png");
var a2 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-2.png");
var a3 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-3.png");
var a4 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-4.png");
var a5 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-5.png");
var a6 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-6.png");
var a7 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-7.png");
var a8 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-8.png");
var a9 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-9.png");
var a10 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-10.png");
var a11 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-11.png");
var a12 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-12.png");
var a13 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-13.png");
var a14 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-14.png");
var a15 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-15.png");
var a16 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-16.png");
var a17 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-17.png");
var a18 = "".concat(cdnAssetUrl, "/assets/images/avatars/child-18.png");
//Avatars for bots in multiplayer games
import ba1 from "~images/student/avatars/Blu.svg";
import ba2 from "~images/student/avatars/Eddy.svg";
import ba3 from "~images/student/avatars/Hoppy.svg";
import ba4 from "~images/student/avatars/Oolzoo.svg";
import ba5 from "~images/student/avatars/Uno.svg";
import ba1_png from "~images/student/avatars/Blu.png";
import ba2_png from "~images/student/avatars/Eddy.png";
import ba3_png from "~images/student/avatars/Hoppy.png";
import ba4_png from "~images/student/avatars/Oolzoo.png";
import ba5_png from "~images/student/avatars/Uno.png";
var Avatars = {
    '1': a1,
    '2': a2,
    '3': a3,
    '4': a4,
    '5': a5,
    '6': a6,
    '7': a7,
    '8': a8,
    '9': a9,
    '10': a10,
    '11': a11,
    '12': a12,
    '13': a13,
    '14': a14,
    '15': a15,
    '16': a16,
    '17': a17,
    '18': a18,
    "blu": ba1,
    "chi": ba2,
    "hoppy": ba3,
    "olzoo": ba4,
    "uno": ba5,
    "blu_cg": ba1_png,
    "chi_cg": ba2_png,
    "hoppy_cg": ba3_png,
    "olzoo_cg": ba4_png,
    "uno_cg": ba5_png,
};
var BotAvatars = {
    "Blu": ba1,
    "Chi": ba2,
    "Hoppy": ba3,
    "Olzoo": ba4,
    "Uno": ba5,
};
export default Avatars;
export { BotAvatars };
