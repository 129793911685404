import React, { useState } from 'react';
import {KlassCodeLoginApi} from '../../../services/login-api';
import { eventHandler } from '../../../utils/event-utils';

const ClassCode = (props) => {

    const [hasError, setError] = useState(false);
    const [classCodeArray, setClassCodeArray] = useState([null, null, null, null, null, null]);
    const classCodeCharacterRefs = [null, null, null, null, null, null];

    function submitClassCode(klass_code) {
        KlassCodeLoginApi({klass_code: klass_code})
        .then(() => {
            window.location.href="/klass/student_roster";
        }).catch(() => {
            setError(true);
            eventHandler('Login Failed', 'Class Login', true);
        });
    }

    const onClassCodeChange = (e, i) => {
        const code = e.target.value;
        setError(false);
        if (code.length === 1) {
            changeClassCodeValue(i, code[0]);
            if (code && [0, 1, 2, 3, 4].includes(i)) {
                classCodeCharacterRefs[i + 1].focus();
            } else {
                var klassCode = "";
                for (var index = 0; index < 5; index++ ) {
                    klassCode += classCodeArray[index];
                }
                klassCode += code[0];
                submitClassCode(klassCode);
            }
        } else if (code.length === 6 && i === 0) {
            changeClassCodeValue(null, code, true);
            classCodeCharacterRefs[classCodeCharacterRefs.length - 1].focus();
            submitClassCode(code);
        } else {
            changeClassCodeValue(i);
        }
    }

    const changeClassCodeValue = (i, val = '', isClassCodePasted = false) => {
        let tempClassCodeArr = [...classCodeArray];
        if (isClassCodePasted) {
        tempClassCodeArr = [...val];
        } else {
        tempClassCodeArr[i] = val;
        }
        setClassCodeArray([...tempClassCodeArr]);
    }

    const onClassCodeKeyDown = (e, i) => {
        var pattern = new RegExp("^[a-zA-Z0-9]$");
        if (e.key === 'Backspace') {
            setError(false);
            e.preventDefault();
            if (classCodeArray[i]) {
                changeClassCodeValue(i);
            } else {
                if (i) {
                    classCodeCharacterRefs[i - 1].focus();
                    changeClassCodeValue(i - 1);
                }
            }
        } else if (!pattern.test(e.key)) {
            return e.preventDefault();
        }
    }

    return (
        <>
            <p className="class-code-title">Enter Class Code</p>
            <div className="class-code-container" style={ hasError ? {border:'2px solid #fd825d'} : {border: '2px solid #4F52C3'} }>
                <div>
                    <input 
                        name="classCode1"
                        value={classCodeArray[0]}
                        onKeyDown={e => onClassCodeKeyDown(e, 0)} 
                        onChange={(e) => onClassCodeChange(e, 0)} 
                        ref={(input) => { classCodeCharacterRefs[0] = input; }} 
                        style={ hasError ? {border: '0px solid #fd825d', 'text-shadow': '0 0 0 #fd825d'} : ((classCodeArray[0] === null || classCodeArray[0] === "") ? {border: '0px solid #cacad8', 'text-shadow': '0 0 0 #4f52c3'} : {border: '0px solid #4f52c3', 'text-shadow': '0 0 0 #4f52c3'})}
                    />
                    <div style={ hasError ? {'background-color': '#fd825d'} : {'background-color': '#4f52c3'} } />
                </div>
                <div>
                    <input 
                        name="classCode2" 
                        value={classCodeArray[1]} 
                        onKeyDown={e => onClassCodeKeyDown(e, 1)} 
                        onChange={(e) => onClassCodeChange(e, 1)} 
                        ref={(input) => { classCodeCharacterRefs[1] = input; }} 
                        style={ hasError ? {border: '0px solid #fd825d', 'text-shadow': '0 0 0 #fd825d'} : ((classCodeArray[1] === null || classCodeArray[1] === "") ? {border: '0px solid #cacad8', 'text-shadow': '0 0 0 #4f52c3'} : {border: '0px solid #4f52c3', 'text-shadow': '0 0 0 #4f52c3'})}
                    />
                    <div style={ hasError ? {'background-color': '#fd825d'} : {'background-color': '#4f52c3'} } />
                </div>
                <div>
                    <input 
                        name="classCode3" 
                        value={classCodeArray[2]} 
                        onKeyDown={e => onClassCodeKeyDown(e, 2)} 
                        onChange={(e) => onClassCodeChange(e, 2)} 
                        ref={(input) => { classCodeCharacterRefs[2] = input; }}
                        style={ hasError ? {border: '0px solid #fd825d', 'text-shadow': '0 0 0 #fd825d'} : ((classCodeArray[2] === null || classCodeArray[2] === "") ? {border: '0px solid #cacad8', 'text-shadow': '0 0 0 #4f52c3'} : {border: '0px solid #4f52c3', 'text-shadow': '0 0 0 #4f52c3'})}
                    />
                    <div style={ hasError ? {'background-color': '#fd825d'} : {'background-color': '#4f52c3'} } />
                </div>
                <div>
                    <input 
                        name="classCode4" 
                        value={classCodeArray[3]} 
                        onKeyDown={e => onClassCodeKeyDown(e, 3)} 
                        onChange={(e) => onClassCodeChange(e, 3)} 
                        ref={(input) => { classCodeCharacterRefs[3] = input; }}
                        style={ hasError ? {border: '0px solid #fd825d', 'text-shadow': '0 0 0 #fd825d'} : ((classCodeArray[3] === null || classCodeArray[3] === "") ? {border: '0px solid #cacad8', 'text-shadow': '0 0 0 #4f52c3'} : {border: '0px solid #4f52c3', 'text-shadow': '0 0 0 #4f52c3'})}
                    />
                    <div style={ hasError ? {'background-color': '#fd825d'} : {'background-color': '#4f52c3'} } />
                </div>
                <div>
                    <input 
                        name="classCode5" 
                        value={classCodeArray[4]} 
                        onKeyDown={e => onClassCodeKeyDown(e, 4)} 
                        onChange={(e) => onClassCodeChange(e, 4)} 
                        ref={(input) => { classCodeCharacterRefs[4] = input; }}
                        style={ hasError ? {border: '0px solid #fd825d', 'text-shadow': '0 0 0 #fd825d'} : ((classCodeArray[4] === null || classCodeArray[4] === "") ? {border: '0px solid #cacad8', 'text-shadow': '0 0 0 #4f52c3'} : {border: '0px solid #4f52c3', 'text-shadow': '0 0 0 #4f52c3'})}
                    />
                    <div style={ hasError ? {'background-color': '#fd825d'} : {'background-color': '#4f52c3'} } />
                </div>
                <div>
                    <input 
                        name="classCode6" 
                        value={classCodeArray[5]} 
                        onKeyDown={e => onClassCodeKeyDown(e, 5)} 
                        onChange={(e) => onClassCodeChange(e, 5)} 
                        ref={(input) => { classCodeCharacterRefs[5] = input; }}
                        style={ hasError ? {border: '0px solid #fd825d', 'text-shadow': '0 0 0 #fd825d'} : ((classCodeArray[5] === null || classCodeArray[5] === "") ? {border: '0px solid #cacad8', 'text-shadow': '0 0 0 #4f52c3'} : {border: '0px solid #4f52c3', 'text-shadow': '0 0 0 #4f52c3'})}
                    />
                    <div style={ hasError ? {'background-color': '#fd825d'} : {'background-color': '#4f52c3'} } />
                </div>
            </div>
            {
                hasError && <p className="class-code-error-text">Class code is invalid or expired</p>
            }
        </>
    )
}

export default ClassCode;