import React, { useEffect } from 'react';
import {CTAPrimaryButton} from '../../shared-styles/CTAPrimaryButton';
import {PARENT_SIGNUP_PATH} from '../../../../routes/route-paths';
import { trackToGtmFE } from '../../../../utils/gtm-event-common';
import i18n from '../../../../utils/i18n';
import {getItemFromLocalStorage, sendErrorToSentry} from '../../../../utils/web-helper'

const SignupContainer = ({ appDownloadCTA }) => {

  useEffect(() => {
    if (
      document.referrer.includes('parent-dashboard') &&
      !getItemFromLocalStorage('from_signout')
    ) {
      sendErrorToSentry(new Error('LoggedOut Error: User logged out'));
      console.error('errror found ...........');
    }
    else{
      localStorage.removeItem('from_signout');
    }
  }, [])

  let parentCtaText = 'Parents, try for free';
  let teacherCtaText = 'Teachers, use for free';

  let ctaLink = PARENT_SIGNUP_PATH;
  let linkTarget = "_self"
  let sendAppDownloadCTAEvents = false;

  if(appDownloadCTA && appDownloadCTA.showAppDownloadCTA){
    linkTarget = "_blank";
    sendAppDownloadCTAEvents=true;
    ctaLink = appDownloadCTA.downloadLink;
    parentCtaText = 'Parents, Download App for Free';
  }

  const ctaClickHandler = async (e) => {
    const eventProperties ={'Click Text': parentCtaText,'Click URL':ctaLink};
    const eventName = 'clicked_parent_signup_button';
    trackToGtmFE(eventName,eventProperties);
    
    if(sendAppDownloadCTAEvents){
      trackEventOnGA('Download Button Tapped',JSON.stringify({"From Page":"HomePage","Link Location":"First Fold"}))
    }
    window.open(ctaLink, linkTarget);
  }
  const ctaClickHandlerTeacher = async (e) => {
      const eventProperties ={'Click Text': teacherCtaText,'Click URL':e?.target?.href};
      const eventName = 'clicked_teacher_signup_button';
      trackToGtmFE(eventName,eventProperties);
  }

  function HomePageFirstFoldText() {
      return (
        <>
          <h1 className="new-title">Kids see <em> fun.</em><br/>You see real <em> learning outcomes.</em></h1>
          <h3 className="new-subtitle">{`Watch your kids fall in love with ${i18n.t('loggedout_homepage.math')} & ${i18n.t('loggedout_homepage.English')} through our scientifically designed curriculum.`}
          </h3>
        </>
      );
    }

  function platformInfo(){
      return (
          <>
            <ul className="platformInfoWrapper">
              <li className="platformInfoLi">
                <span className="platformInfospan">{i18n.t('loggedout_homepage.banner_super_heading_1')}</span>
              </li>
              <li className="platformInfoLi"> | </li>
              <li className="platformInfoLi">
                <span className="platformInfospan">{i18n.t('loggedout_homepage.grade_range')}</span>
              </li>
            </ul>
          </>
      );

  }

  let tempBtnObj = {}
  tempBtnObj.fireFighter = true;

  return (

    <div className="signupcontainer-content-wrapper content-container">
      {platformInfo()}
      {HomePageFirstFoldText()}
      <div className="button-wrapper">
        <CTAPrimaryButton  white className="cta-primary-button">
          <a onClick={(e)=>ctaClickHandler(e)}>{parentCtaText}</a>
        </CTAPrimaryButton>
        <CTAPrimaryButton {...tempBtnObj} className="cta-primary-button cta-primary-button-2">
          <a href="/teachers/register" onClick={(e)=>ctaClickHandlerTeacher(e)}>{teacherCtaText}</a>
        </CTAPrimaryButton>
      </div>
    </div>
  );
};

export default SignupContainer;
