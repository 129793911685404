export var freemium_supported_grades = ["2", "3", "4", "5"];
export var MetaKeys;
(function (MetaKeys) {
    MetaKeys["BgImgURL"] = "[\"bg_img_code\"]";
})(MetaKeys || (MetaKeys = {}));
export var GamePlayModeCode;
(function (GamePlayModeCode) {
    GamePlayModeCode["Pwc"] = "pwc";
    GamePlayModeCode["Solo"] = "solo";
    GamePlayModeCode["The1_V1"] = "1_v_1";
})(GamePlayModeCode || (GamePlayModeCode = {}));
export var getFreemiumWidthInPercentage = function (value) {
    var percentage;
    switch (value) {
        case 'sm':
            percentage = 25;
            break;
        case 'md':
            percentage = 50;
            break;
        case 'lg':
            percentage = 100;
            break;
        default:
            percentage = 25;
    }
    return percentage;
};
export var MAX_CARD_IN_ROW = 4;
