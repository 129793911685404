export const titanWhite = '#f3f3ff';
export const indigo = '#4f52c3';
export const indigoDark = '#4b5ebd';
export const deepCove = '#08064b';
export const deepCoveDark = '#090a4a';
export const codGrey = '#1A1A1A';
export const white = '#ffffff';
export const black = '#1a1a1a';
export const whiteLilac = '#EDEDF9';
export const alto = '#dedede';
export const silverChalice = "#A5A5A5";
export const mischka = "#DCDCEA";
export const midGray = "#6c6c73";
export const midGrayDark = "#63636a";
export const amber = "#FFC108";
export const royal = "#141975";
export const lightBlueGrey = "#cacad8";
export const tangaroa = "#031148";
export const lightWhite = "#FDFDFD";
export const cadetBlue = "#B2B1C7";
export const lightPurple = "#B746E6";
export const darkOrange = "#FD825D";
export const babyPurple = "#9294F6";
export const lightPurpleTint = "#C2C4FF";
export const lightBlue = "#2284E2";
export const transparentBlack = "#1a1a1ae8";
export const orange = "#EB6D45";
export const blackRock = '#020533';
export const midGrayLight = '#6E6E73';
export const onahau = '#CFE8FF';
export const curiousBlue = '#2284E2'
export const brightSun = '#FFC543';
export const downRiver = '#09264D';
export const persianGreen = '#00B79F'
export const sunGlow = '#FFD324';
export const santasGray = '#A2A2AD';
export const hawkesBlue = '#DEDFFC';
export const zircon = '#F5F6FF';
export const magenta ='#B746E6';
export const pictonBlue = '#3B98EF';
export const hollywoodCerise = '#D400A1';
export const neonCarrot = '#FC902D';
export const crimson = '#E82828';
export const paintChip = '#CACAD8';
export const fireFighter = '#4F52C3';
export const cornflowerBlue = "#797CF5";
export const malibu = '#5BAFFF';
export const Downy = '#69D5C1';
export const Melrose = '#BCBEFF'
export const CornflowerBlue = '#797CF5';
export const mintGreen = "#A5F9E8";
export const darkBlue = "#141953";
export const lightPictonBlue = "#55B9E8";
export const Perano = "#A7CEF3";
export const Perfume = "#E2B5F5";
export const VividViolet = "#8535A9";
export const chetwodeBlue = "#797CDB";
export const Kournikova = "#FFD34D";
export const MelrosePurple = '#9D9FFC';
export const PaleNavy = '#F4F2FF';
export const OrangeTint = "#FD9B7D";
export const VioletBlue = "#7678DF";
export const Sapphire = "#34389F";
export const furiousPink = "#C56BEB";
export const Lochinvar = '#2B9B86';
export const JordyBlue = '#7AB5EE';
export const MontyGreen = '#B7FAED';
export const PaleBlue = '#D3E6F9';
export const PaleGray = '#e5e5e5';
export const GovernorBay = '#3A3DB6';
export const egyptianBlue = '#232997';
export const PalePink = '#F1DAFA';
export const Bruise = '#6f3a52';
export const pineappleSalmon = '#FD5D5D';
export const midBlack = '#565656';
export const blackLight = '#363639';
export const crystalPurple = '#bdbff9';
export const offWhite='#E9E7E7';
export const LightYellow = '#FFE8B4';
export const BlueMagenta = '#8383BE';
export const colonialWhite = '#DBFDF6';
export const dullLavendar = '#989BEC';
export const maid = '#FFE6DF';
export const humming = '#C9FBF1';
export const dogerBlue = "#034EAC"
export const hollywoodCeriseLight="#D701A1";
export const skyBlue = '#87C7FF';
export const lightSkyBlue = '#DEF0FF';
export const lightPinkGrey = '#F9F9FF';
export const ctaSkyBlue = '#4285F4';
export const pureBlack = '#000000';
export const lightBlack = '#4d4d4d';
export const errorRed = '#e94e4e';
export const buttonGrey = '#adadad';
export const approxPerano = '#A6A8FF';
export const transparentPureBlack = '#0000004a';
export const transparentVioletBlue = '#7678DF7A';
export const transparentBlackRock = '#020533b3';
export const jazzBlue = '#1A63A6';
export const blueCyan = '#88FCE8';
export const contrastSteelBlue = '#4E5AB7';
export const royalTransparent = "#14197533";
export const lightRed = '#FDF1F1';
export const maroon = '#B84040';
export const warmBlack = "#494949";
export const transparentDeepCoveDark = "#090A4AB2";
export const alertRed = '#EB4545';
export const highlightYellow = '#FFCC3E';
export const lightOrange = '#F9805D';
export const brown = '#BA6246'
export const tintedBlue = '#27DBFF';
export const floralWhite = "#FFFCED";
export const buttonWhite = "#EDEDF9";
export const deepBlue = "#131D70";
export const lightGray = "#484848";
export const backgroundTertiary = "#F7F7FA";
export const lightGrey = "#767676";