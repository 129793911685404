import { QueryClient } from "react-query";
import graphqlApiHandler from "../utils/graphql-api-handler";

const queryClient = new QueryClient();
export function queryStudentAssignments(variables) {
    return queryClient.fetchQuery(['studentAssignments'], graphqlApiHandler(`${BASE_API_GATEWAY_URL}/graphql/graphql`,
        `query ($studentId: String!) {
            studentAssignments(studentId: $studentId) {
                assignmentEverAssigned
                studentTasks {
                    completedAt
                    createdAt
                    deletedAt
                    id
                    studentId
                    taskId
                    updatedAt
                    userLoTaskId
                }
                taskGroupings {
                    createdAt
                    id
                    taskGroupId
                    taskId
                    updatedAt
                }
                taskGroups {
                    createdAt
                    deletedAt
                    groupableId
                    groupableType
                    id
                    klassId
                    updatedAt
                }
                tasks {
                    createdAt
                    deletedAt
                    finishedAt
                    id
                    klassId
                    objectiveId
                    objectiveType
                    updatedAt
                }
            }
        }`,
        variables
    ));
}

export function queryStudentAssignmentPlayData(variables) {
    return queryClient.fetchQuery(['studentAssignmentPlayData'], graphqlApiHandler(`${BASE_API_GATEWAY_URL}/graphql/graphql`,
        `query ($studentId: String!, $userLoTaskIds: [String!]!) {
            studentAssignmentPlayData(userId: $studentId, userLoTaskIds: $userLoTaskIds) {
                userLpStatuses {
                    askedCount
                    completionCount
                    correctCount
                    createdAt
                    id
                    lastCompletedAt
                    lastPlayableAttemptId
                    learningObjectiveId
                    playableId
                    rewardCount
                    statusCd
                    timeSpent
                    updatedAt
                    userId
                }
                userPlayableAttempts {
                    askedCount
                    clickSourceCd
                    clientId
                    completedAt
                    correctCount
                    courseId
                    createdAt
                    dateTz
                    deletedAt
                    id
                    playModeCd
                    playableId
                    playableParentId
                    playableParentTypeCd
                    previousStatusCd
                    questionCount
                    rewardCount
                    startedAt
                    timeSpent
                    updatedAt
                    userId,
                    userLoTaskIds
                }
            },
            userLoTasks(userLoTaskIds: $userLoTaskIds) {
                createdAt
                endedAt
                id
                learningObjectiveId
                statusCd
                updatedAt
                userId
            },
            learningObjectives(userLoTaskIds: $userLoTaskIds) {
                category
                code
                contentGroupId
                courseId
                createdAt
                deleted
                id
                metaDescription
                minVersion
                ordering
                thumbnailCode
                title
                updatedAt
                contentGroup {
                    code
                    createdAt
                    deleted
                    id
                    metaDescription
                    title
                    updatedAt
                }
                milestone {
                    cgPlayed
                    code
                    createdAt
                    deleted
                    elementAction
                    entityType
                    id
                    isLastPlayed
                    learningObjectiveId
                    metaDescription
                    playableId
                    progressData
                    shortTitle
                    title
                    updatedAt
                    viewCategories
                }
            },
            playables(userLoTaskIds: $userLoTaskIds) {
                allowPitstop
                code
                createdAt
                deleted
                entityId
                entityType
                id
                learningObjectiveId
                loCategory
                metaDescription
                minVersion
                playableViewCategory
                shortTitle
                title
                updatedAt
            }
       
        }`,
        variables
    ));
}

export function queryCourses(variables) {
    return queryClient.fetchQuery(['courses'], graphqlApiHandler(`${BASE_API_GATEWAY_URL}/graphql/graphql`,
        `query ($courseIds: [Int!]) {
            courses(courseIds: $courseIds) {
                code
                id
            }
        }`,
        variables
    ));
}

