import React from 'react';
import HomeBannerIconNew from '~images/loggedout/home/Header_image_new.png'
import TabletHomeBanner from '~images/loggedout/home/tablet_homebanner.png'
import SignupContainer from '../SignupContainer/index';
import Image from '../../common/Image';
import { breakpoints } from '../../../../utils/responsive_utilities';

export const DesktopVideoHead = ({ abTestsDetails}) => {
  const [showModularImage, setModularImage] = React.useState((window.innerWidth >= breakpoints.md+1 && window.innerWidth <= 1279) ? TabletHomeBanner : HomeBannerIconNew);
  
  function handleResize(){
    (window.innerWidth >= breakpoints.md+1 && window.innerWidth <= 1279) ? setModularImage(TabletHomeBanner) : setModularImage(HomeBannerIconNew);
  }
  React.useEffect(()=>{
    //add resize listener
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  },[])
  
    return (
      <>
        <div className="homepage-video-variant-gif-container">
          <div className = "static-image-wrapper">
          
            <Image src={showModularImage} className='home-banner' alt='home-banner' />
            
          </div>
        </div>
        <SignupContainer abTestsDetails={abTestsDetails}/>
      </>
    )
}
