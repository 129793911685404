import styled from 'styled-components';
import { CardWrapper } from '../signup/style';
import { Caption, Heading2 } from '../shared-styles/Font';
import { indigo, lightBlueGrey, white, whiteLilac, black, tangaroa, alto } from '../shared-styles/Colors';
import {
  getWidthRange,
  breakpoints,
} from '../../../utils/responsive_utilities';

export const ContentWrapper = styled.div`
  padding: 130px 0 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 940px;
  & > div:nth-child(1) {
    & > ${CardWrapper} {
      margin: 34px 0 50px;
    }
  }
  & > ${Caption} {
    margin-top: auto;
  }
  @media ${getWidthRange(breakpoints.sm, breakpoints.lg)} {
    padding: 88px 0 40px;
  }
  @media (max-height: ${breakpoints.md}px), ${getWidthRange(0, breakpoints.sm)} {
    padding: 36px 0 40px;
  }
`;

export const Link = styled.a`
  font-family: Graphie;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
  text-decoration-line: underline;
  color: ${indigo};
`

export const ClassCodeTitle = styled.p`
  font-family: Graphie;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`

export const ClassCodeDash = styled.ul`
  position: absolute;
  bottom: -2px;
  left: 10px;
  z-index: 1;
  padding-left: 10px;
  & > li {
    width: 56px;
    border: none;
    padding: 0;
    cursor: default;
    border-bottom: 2px solid #f79202;
    display: inline-block;
    margin-left: 40px;
  }
`

export const ClassCodeTooltip = styled.span`
  display: none;
  padding: 8px;
  background: #363639;
  font: normal 12px/16px Inter;
  color: white;
  position: absolute;
  left: 50px;
  top: -20px;
  border-radius: 4px;
  &:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: -7px;
    border-top: 7px solid #363639;
    left: 20px;
  }
`

export const ClassCodeFooterLink = styled.p`
  font-family: Graphie;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #1A1A1A;
  margin-top: 20px;
  & a {
    line-height: 14px;
    color: #4f52c3;
  }
`

export const ClassCodeEditor = styled.p`
  
`

export const BackButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
`
export const FormWrapper = styled.div`
  width: 90%;
  min-width: 300px;
  padding-top: 54px;
  margin: auto;
  text-align: center;
  @media ${getWidthRange(0, breakpoints.sm)} {
    padding-top: 36px;
  }
`
export const Form = styled.div`
  max-width: 384px;
  margin: auto;
  & p {
    font-family: Graphie;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #1A1A1A;
  }
  & input {
    border: 1px solid #CACAD8;
    width: 100%;
    height: 40px;
    margin-top: 8px;
    border: 1px solid ${lightBlueGrey};
    padding-left: 5px;
  }
`
export const PassWrapper = styled.div`
  margin-top: 18px;
  & > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`
export const ButtonWrapper = styled.div`  
  width: 100%;
  margin: 28px auto 0;
`
export const FormHeading = styled(Heading2)`
  max-width: 384px;
  text-align: center;
  margin: auto;
`
export const LoginButton = styled.button`
  width: 386px;
  height: 56px;
  border-radius: 28px;
  background: ${indigo};
  border: none;
  color: ${white};
  margin: auto;
  display: block;
  outline: none;
`
export const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 384px;
  width: 100%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 40px;
  & > button:nth-child(1) {
    border-radius: 23px 0 0 23px;
    border-right: none;
  }
  & > button:nth-child(3) {
    border-radius: 0 23px 23px 0;
    border-left: none;
  }
`
export const Tab = styled.button`
  display: block;
  flex-basis: 50%;
  height: 46px;
  border: 1px solid ${lightBlueGrey};
  background-color: ${props => props.selected ? indigo: whiteLilac};
  color: ${props => props.selected ? white: black};
  outline: none;
`
export const SocialLinkWrapper = styled.div`
  width: 90%;
  max-width: 992px;
  margin: auto;
  text-align: center;
`
export const OrLine = styled.p`
  position: absolute;
  top: -36px;
  width: 100%;
  border-bottom: 1px solid ${alto};
  text-align: center;
  line-height: 0.1em;
  & > span {
    padding:0 10px;
    background: ${whiteLilac};
  }
`
export const SocialContentWrapper = styled.div`
  display: inline-flex;
  position: relative;
  justify-content: center;
  margin: 68px auto 0;
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: ${white};
    min-width: 240px;
    margin: 0 24px 0 0;
    color: ${tangaroa};
    &:last-of-type {
      margin: 0;
    }
    & > img {
      height: 25px;
      width: 25px;
    }
    & > p {
      margin-left: 16px;
    }
  }
  @media ${getWidthRange(0, breakpoints.lg)} {
    display: block;
    max-width: 384px;
    min-width: 300px;
    & > a {
      margin: 0 0 12px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
`

