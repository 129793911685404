import { Store } from '../stores/parent-dashboard';
import { Store as StudentStore} from '../stores/student-dashboard';
import { isEligibleForDRSDashboard, hasAccessToTeacherFreemiumNoBlocker } from '../utils/feature_mapping';
import {addUniqueParamsToPath, getCookie, playPauseAudio } from '../utils/web-helper'
import { getABTestValue, getTeacherABTestValue } from '../utils/ab-tests';
import { getCurrentCampaign, Campaigns, isSummerCampaignEnabled } from '../constant-data/campaign';

function PlayableLauncher(item, clickSource, closeHandler, options = {}){
  playPauseAudio();

  let playableData = getCookie('game_playable_url')? JSON.parse(getCookie('game_playable_url')): {};
  let worksheetData = getCookie('worksheet_playable_url')?JSON.parse(getCookie('worksheet_playable_url')):{service_used : 'web'};
  let student = Store.getState().student.id ? Store.getState().student : StudentStore.getState().student;
  let current_course_code = item.current_course_code ? item.current_course_code : (StudentStore.getState().current_course ? StudentStore.getState().current_course.code : null);
  const topics_out_on_drs = isEligibleForDRSDashboard() ;
  const freemium_mode = hasAccessToTeacherFreemiumNoBlocker() ? 'timer' : 'count';
  const game_deep_link = item.game_deep_link;
  const game_deep_link_data = item.game_deep_link_data;
  const client_id = sessionStorage.getItem('client_id') || "";
  let currentCampaign = getCurrentCampaign();
  if (currentCampaign == Campaigns.summerSpecial) {
    if (!isSummerCampaignEnabled())
      currentCampaign = null;
  }

  localStorage.setItem('isPlayableLaunched', 1);
  const closeHandlerWithSettings = () => {
    localStorage.removeItem('isPlayableLaunched');
    playPauseAudio(false);
    closeHandler(options);
  }

  var loId = item.learning_objective_id
  var playableId = item.playable_id
  var userLoTaskId = item.user_lo_task_id
  var playableName = item.title;
  var controller, launcherFunction;
  var prefix = '';
  switch(item.entity_type) {
    case 'ExploratoryGame':
      controller = 'exploratory_games/' + item.game;
      launcherFunction = SPWidget.launchContentGame;
    break;
    case 'GameLevelCluster':
      if(playableData.service_used == 'playable'){
         prefix = PLAYABLE_SERVICE_URL;
      }
      controller = 'game_playable';
      launcherFunction = SPWidget.launchContentGame;
    break;
    case 'Worksheet':
      if(worksheetData.service_used == 'playable'){
        prefix = PLAYABLE_SERVICE_URL;
      }
      controller = 'worksheet_playable';
      launcherFunction = SPWidget.launchWorksheet;
    break;
  }
  var path = `${prefix}/iframes/${controller}?`;
  path += `topics_out_on_drs=${topics_out_on_drs}` //send whether topics_out_on_drs segment is assigned or not

  path += `&freemium_mode=${freemium_mode}` // sending it to continue autoplay after 2 free cards in timer flow

  path += `&client_id=${client_id}`; /// Append client_id in game path url

  var ctrEventProperties = {}; /// This will hold ctr event properties required in playable launcher to be passed to next playable

  if(loId){
    path += `&learning_objective_id=${loId}&playable_id=${playableId}`
  }
  if (clickSource) {
    path += `&click_source=${clickSource}`
  }
  if (item.student_fact_fluency_id) {
    path += `&student_fact_fluency_id=${item.student_fact_fluency_id}`
  }
  if (item.student_fact_fluency_settings) {
    path += `&student_fact_fluency_settings=${JSON.stringify(item.student_fact_fluency_settings)}`
  }
  if(userLoTaskId){
    path += `&user_lo_task_id=${userLoTaskId}`
  }
  if(clickSource === 'learning_path' && item.user_suggestion_set_id){
    path += `&user_suggestion_set_id=${item.user_suggestion_set_id}`
  }
  if(item.free_cards_count) {
    path += `&free_cards_count=${item.free_cards_count}`
  }
  if(item.entity_type === 'FunGame') {
    launcherFunction = typeof SPWidget != "undefined" && SPWidget.launchContentGame;
    path = `/student_games?game_type=${item.game_type}`;
    playableName = item.game_type;
  }
  if(item.entity_type === 'GameLevelCluster' && playableData.service_used == 'playable'){
      path +=  `&userId=${student.id}&guardian_id=${playableData.guardian_id}&meta_user_session_id=${playableData.meta_user_session_id}`
  }
  if(item.entity_type === 'Worksheet' && worksheetData.service_used == 'playable') {
    path +=  `&userId=${student.id}&guardian_id=${worksheetData.guardian_id}&meta_user_session_id=${worksheetData.meta_user_session_id}`
  }
  if(current_course_code && !item.from_flutter){ // do not add from flutter
    path += `&current_course_code=${current_course_code}`
  }
  if(item.active_tab_code){
    path += `&from_tab=${item.active_tab_code} Tab`
  }

  if(currentCampaign){
    path += `&theme=${currentCampaign}`
  }

  if(game_deep_link) {
    path += `&game_deep_link=${game_deep_link}`;
  }
  if(game_deep_link_data) {
    path += `&game_deep_link_data=${game_deep_link_data}`;
  }

  if(item.content_type) {
    path += `&content_type=${item.content_type}`;
  }

  if (JSON.parse(localStorage.getItem("is_classroom_student"))) {
    if (getTeacherABTestValue("Teacher::ServiceWorkerCaching") ==='Variant') {
      path += '&user_sw_enabled=1';
    }
  } else if (getABTestValue('Parent', 'ServiceWorkerCaching') == 'Variant') {
    path += '&user_sw_enabled=1';
  }

  if(item.subject_name){
    path += `&subject_name=${item.subject_name}`;
  }

  if(item.recommendedTileRank){
    ctrEventProperties['recommendedTileRank'] = item.recommendedTileRank
  }
  if(item.recommendedTileName){
    ctrEventProperties['recommendedTileName'] = item.recommendedTileName

  }
  if(item.recommendedRailName){
    ctrEventProperties['recommendedRailName'] = item.recommendedRailName

  }
  if(item.recommendedRailRank){
    ctrEventProperties['recommendedRailRank'] = item.recommendedRailRank
  }
  if(item.clickId){
    ctrEventProperties['clickId'] = item.clickId
  }

  if(item.contentGroup){
    ctrEventProperties['contentGroup'] = item.contentGroup
  }

  if(Object.keys(ctrEventProperties).length > 0){
    path += `&ctr_event_properties=${encodeURIComponent(JSON.stringify(ctrEventProperties))}`;
  }

  if(item.event_properties){
    path += `&event_properties=${encodeURIComponent(JSON.stringify(item.event_properties))}`;
  }

  path = addUniqueParamsToPath(path, item.extra_param_for_launcher);

  launcherFunction && launcherFunction({path, closeHandler: closeHandlerWithSettings, playableName});
}

export default PlayableLauncher;
