import React from 'react'
import HomepageFaqs from '../../../../../constant-data/homepage-faqs'
import SingleQA from './singleQA'

const SectionFAQs = () => {
  return (
    <section className="homepage-faq-section">
      <div className="faq-container">
        <h2 className="faq-heading">Frequently Asked Questions</h2>
        <ul className="faq-items">
          {HomepageFaqs.map((item, index) => (
            <SingleQA key={"hp-faq-"+index}
              index={index}
              item={item}
            />
          ))}
        </ul>
      </div>
    </section>
  )
}

export default SectionFAQs;