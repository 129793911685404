import { assessmentLauncher } from './assessmentLauncher';
import { bookLauncher } from './bookLauncher';
import { mathFactsPlayableLauncher } from './mathFactsPlayableLauncher';
import { playableLauncher } from './playableLauncher';
import { redirectionHandler } from './redirectionHandler';
export var getActionHandler = function (actionCode) {
    if (actionCode)
        switch (actionCode) {
            case 'playable_launcher':
                return playableLauncher;
            case 'math_facts_playable_launcher':
            case 'assignment_math_facts_playable_launcher':
                return mathFactsPlayableLauncher;
            case 'assessment_launcher':
                return assessmentLauncher;
            case 'book_launcher':
                return bookLauncher;
            case 'redirect':
                return redirectionHandler;
            case 'default':
                return function () {
                    /* Empty action handler */
                };
        }
    return function () {
        /* Empty action handler */
    };
};
