import styled from 'styled-components';
import {breakpoints, getWidthRange} from '../../../utils/responsive_utilities';

export const Heading1 = styled.h1`
  font-family: Graphie;
  font-size: 4.6rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.92px;
  @media ${getWidthRange(0, breakpoints.sm)} {
    font-size: 34px;
  }
`
export const Subhead = styled.p`
  font-family: Graphie;
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
`
export const Heading2 = styled.h2`
  font-family: Graphie;
  font-size: 3.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: 0.68px;
`
export const Heading3 = styled.h3`
  font-family: Graphie;
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.4px;
`

export const Heading4 = styled.h3`
  font-family: Graphie;
  font-size: 3.2rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.4px;
  color: ${props => props.color};
`;

export const BodyHeavy = styled.p`
  font-family: Graphie;
  font-size: 1.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.36px;
`
export const Testimonial = styled.p`
  font-family: Graphie;
  font-size: 2.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`

export const Caption = styled.p`
  font-family: Graphie;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.28px;
`