var _a;
export var FLUTTER_SETTINGS = 'flutterSetting';
export var tabNames;
(function (tabNames) {
    tabNames["gameZone"] = "game_zone";
    tabNames["liveClass"] = "live_class";
    tabNames["liveClassEvent"] = "live_class_event";
    tabNames["curriculum"] = "curriculum";
    tabNames["home"] = "home";
    tabNames["classInvitesPopup"] = "class_invites_popup";
    tabNames["catexMigrationScreen"] = "catex_migration_screen";
    tabNames["printables"] = "printables";
})(tabNames || (tabNames = {}));
export var screenName;
(function (screenName) {
    screenName["home"] = "home";
    screenName["todo_list"] = "todo_list";
    screenName["onboarding"] = "onboarding";
})(screenName || (screenName = {}));
export var screenRoute = (_a = {},
    _a[screenName.todo_list] = "/home/todo_list",
    _a[screenName.home] = "/home",
    _a[screenName.onboarding] = null,
    _a);
