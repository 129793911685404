import React from 'react';
import Image from '../../../common/Image';
import GameImg from '~images/loggedout/home/modularPage/games.png';
import PlaysheetsImg from '~images/loggedout/home/modularPage/playsheets.png';
import DecodableBooksImg from '~images/loggedout/home/modularPage/decodable_books.png';
import BedtimeStoriesImg from '~images/loggedout/home/modularPage/bedtime_stories.png';
import {PARENT_SIGNUP_PATH} from '../../../../../routes/route-paths';
import { trackToGtmFE } from '../../../../../utils/gtm-event-common';

const ModularSection2 = () => {
  const handleCtaClick = (eventData) => {
    trackToGtmFE("clicked_other_signup_button",eventData);
  }
  return (
    <div className="clearfix modular-section-2-wrapper ">
      <h3 className="head-text">
        Something new to choose from, every day
      </h3>

      <p className="sub-head-text">
        With thousands of games, playsheets, books and videos at their fingertips, your child will never want to stop learning
      </p>

      <ul className="section-2-ul-list">
        <li className="section-2-li-list-item">
          <Image src={GameImg} alt="Games for kids" className="section-2-img" />
          <p>Games</p>
          <span>Immersive games that educate and entertain in equal measure</span>
        </li>
        <li className="section-2-li-list-item">
          <Image src={PlaysheetsImg} alt="Playsheets for kids" className="section-2-img" />
          <p>Playsheets</p>
          <span>Practice sheets - in a fun avatar - for extra reinforcement</span>
        </li>
        <li className="section-2-li-list-item">
          <Image src={DecodableBooksImg} alt="Decodable books for kids" className="section-2-img" />
          <p>Decodable Books</p>
          <span>Guided story books for early learners </span>
        </li>
        <li className="section-2-li-list-item">
          <Image src={BedtimeStoriesImg} alt="Bedtime stories for kids" className="section-2-img" />
          <p>Bedtime Stories</p>
          <span>From new to classics, find hundreds of exciting bedtime stories for all age groups</span>
        </li>
      </ul>
      <div className="section-2-primary-cta-button">
        <a href={PARENT_SIGNUP_PATH} onClick={()=> {
          handleCtaClick({'Click Text': 'Try for free','Click URL': PARENT_SIGNUP_PATH})
          }}>
          Try for free
        </a>
      </div>

    </div>
  );
};

export default ModularSection2;
