import React, { useState } from 'react';
import * as S from './style';
import { CardWrapper } from '../signup/style';
import SignupCard from '../common/SignupCard';
import InSchoolIcon from '~images/loggedout/icons/in_school_icon.png';
import AtHomeIcon from '~images/loggedout/icons/at_home_icon.png';
import { Caption, Heading2 } from '../shared-styles/Font';
import EmailPassForm from './EmailPassForm';
import ClassCode from './ClassCode';
import SocialLinks from './SocialLinks';
import { eventHandler } from '../../../utils/event-utils';

const pagesMap = {
  MAINPAGE: 'main page',
  STUDENTLOGIN: 'student login',
  LOGINFORM: 'class/parent/teacher login',
  CLASSLOGIN: 'class login',
  HOMELOGIN: 'home login',
};
const MainSigninPage = (props) => {
  const [currentPage, setCurrentPage] = useState(pagesMap.LOGINFORM);
  const loadPage = () => {
    switch (currentPage) {
      case pagesMap.MAINPAGE:
        return MainPage();
      case pagesMap.STUDENTLOGIN:
        return <StudentLogin />;
      case pagesMap.LOGINFORM:
        return <LoginForm />;
      case pagesMap.HOMELOGIN:
        return HomeLogin();
      case pagesMap.CLASSLOGIN:
        return classCodeLogin();
      default:
        return MainPage();
    }
  };
  const handleChangePage = (newPage) => {
    setCurrentPage(() => newPage);
  };
  const MainPage = () => {
    return (
      <S.ContentWrapper>
        <div>
          <Heading2>Where are you playing SplashLearn?</Heading2>
          <CardWrapper>
            <SignupCard
              text="At Home"
              icon={AtHomeIcon}
              onClick={() => handleChangePage(pagesMap.HOMELOGIN)}
            />
            <SignupCard
              text="In School"
              icon={InSchoolIcon}
              onClick={() => handleChangePage(pagesMap.CLASSLOGIN)}
            />
          </CardWrapper>
          <Caption>
            I’m a{' '}
            <S.Link
              onClick={() => handleChangePage(pagesMap.PARENTTEACHERLOGIN)}
            >
              Teacher/Parent with an existing account
            </S.Link>
          </Caption>
        </div>
        <Caption>
          New User? Sign Up as a <S.Link>parent</S.Link> or a{' '}
          <S.Link>teacher</S.Link>
        </Caption>
      </S.ContentWrapper>
    );
  };
  return (
    <>
      {loadPage()}
    </>
  );
};

const LoginForm  = ({userType, ...props}) => {
  const currentPage = userType !== null ? ((userType === 'teacher') ? 3 : ((userType === 'parent') ? 2 : 1)) : 1;
  const [selectedTab, setSelectedTab] = useState(currentPage);
  const [selectedStudentLoginScreen, setSelectedStudentLoginScreen] = useState(1);
  const handleTabSelect = (newTab) => {
    if (selectedTab !== newTab) {
      if (newTab === 1) {
        eventHandler('clicked student tab', {"From Page":"Login Page"});
      } else if (newTab === 2) {
        eventHandler('clicked parent tab', {"From Page":"Login Page"});
      } else if (newTab === 3) {
        eventHandler('clicked teacher tab', {"From Page":"Login Page"});
      }
    }
    setSelectedTab(() => newTab)
  }

  const handleStudentScreenSelect = (studentSelectedLoginScreen) => {
    setSelectedStudentLoginScreen(studentSelectedLoginScreen)
  }

  return (
    <div className="login-form">
      <S.FormWrapper>
        <S.FormHeading>Log In as</S.FormHeading>
        <S.TabWrapper>
          <S.Tab selected={selectedTab === 1} onClick={() => handleTabSelect(1)}>Student</S.Tab>
          <S.Tab selected={selectedTab === 2} onClick={() => handleTabSelect(2)}>Parent</S.Tab>
          <S.Tab selected={selectedTab === 3} onClick={() => handleTabSelect(3)}>Teacher</S.Tab>
        </S.TabWrapper>
        { (selectedTab === 1) ? 
            (selectedStudentLoginScreen === 1 ? 
              <StudentKlassLoginForm {...props} onUsernameLoginClicked={() => handleStudentScreenSelect(2)} /> : 
              <StudentLogin {...props} onClassCodeLoginClicked={() => handleStudentScreenSelect(1)} /> ) : 
            (selectedTab === 2) ? <ParentLoginForm {...props} linkClickListener={() => handleTabSelect(1)} /> : <TeacherLoginForm /> }
      </S.FormWrapper>
    </div>
  );
}

const StudentKlassLoginForm = ({onUsernameLoginClicked, ...props}) => {
  return (
        <div className='class-code-form'>
          <ClassCode />
          {/* <p className="login-form-footer-link class-code-link-margin">Have a Username? <a onClick={onUsernameLoginClicked}>Log in here</a></p> */}
        </div>
    )
}

const ParentLoginForm = ({linkClickListener, ...props}) => {
  return (
        <>
          <EmailPassForm {...props} source='parent' />
          <SocialLinks apple={true} clever={false} facebook={true} userType='parent'/>
          <p className="login-form-footer-link parent-register-link">New parent? <a href="/parents/register">Register here</a></p>
          <p className="login-form-footer-link margin-top-30">Got invited by your teacher? <a onClick={linkClickListener}>Sign up here</a></p>
        </>
    )
}

const TeacherLoginForm = (...props) => {
  return (
        <>
          <EmailPassForm {...props} source='teacher' />
          <SocialLinks apple={false} clever={true} facebook={false} userType='teacher'/>
          <p className="login-form-footer-link teacher-register-link">New Teacher? <a href="/teachers/register">Register here</a></p>
        </>
    )
}

const StudentLogin = ({onClassCodeLoginClicked, ...props}) => {
  return (
      <>
        <EmailPassForm {...props} source='class' />
        <p className="login-form-footer-link margin-top-30"><a onClick={onClassCodeLoginClicked}>Login using Class Code</a></p>
      </>
    )
};
const HomeLogin = () => {
  return <div></div>;
};
const classCodeLogin = () => {
  return <div></div>;
};

export default MainSigninPage;
