import React, { useEffect, useState } from 'react';
import Image from '../../../common/Image';
import LearningPathImg from '~images/loggedout/home/modularPage/learning_path.png';
import ModularImage from '~images/loggedout/home/modularPage/modular_section3.png';
import { breakpoints } from '../../../../../utils/responsive_utilities';

const ModularSection2 = () => {
  const [showModularImage, setModularImage] = useState(window.innerWidth <= breakpoints.md ? ModularImage : LearningPathImg);
  
  useEffect(()=>{
    //add resize listener
    function handleResize(){
      window.innerWidth <= breakpoints.md ? setModularImage(ModularImage) : setModularImage(LearningPathImg);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
    
  },[])
  return (
    <div className="clearfix modular-section-3-wrapper">
      <h3 className="head-text">
        Make effective independent learning a reality
      </h3>

      <p className="sub-head-text">
        The learning path is your child's personalized learning journey divided into meaningful daily milestones.
      </p>

      <div className="img-wrap">
        <Image src={showModularImage} alt="Personalized Learning Path" />
      </div>

      <p className="sub-head-text1">
        Saves the hassle of hunting for <span>learning plans</span>
      </p>

      <p className="sub-head-text1">
        <span>Adapts</span> to your child's learning needs
      </p>

      <p className="sub-head-text1">
        Leads to <span>curriculum mastery,</span> one day at a time
      </p>
    </div>
  );
};

export default ModularSection2;
