import React, { Fragment, useEffect, useState, useRef } from 'react';
import useIsClient from '../../custom-hooks/useIsClient';
import Slider from 'react-slick';
import { breakpoints } from '../../../../utils/responsive_utilities';

import { Heading2 } from '../../shared-styles/Font';
import Image from '../../common/Image';
import Popup from '../../common/Popup';
import SignupCard from '../../common/SignupCard';
import ParentSignupIcon from '~images/loggedout/icons/parent_signup.png';
import TeacherSignupIcon from '~images/loggedout/icons/teacher_signup.png';
import { trackToGtmFE } from '../../../../utils/gtm-event-common';

import {GRADE_SUBJECT_CONTENT_LIST, ContentTypeList, gradeList, subjectList, getGradeImage} from '../../../../constant-data/universe-learning-constants'
import i18n from '../../../../utils/i18n';

const UniverseLearning = ({abTestsDetails}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  
  const [currentSub, setCurrentSub] = useState({});
  const [currentContentType, setCurrentContentType] = useState({});
  const [activeGrades, setActiveGrades] = useState([])
  
  const isClient = useIsClient();

  useEffect(() => {
    const initialSubId = 1;
    const initialContentTypeId = 1;
    setCurrentSub(() => subjectList[initialSubId]);
    setCurrentContentType(() => ContentTypeList[initialContentTypeId])

    setActiveGrades(() => findActiveGradeList(getAvailableListByContentAndSubject(currentContentType, currentSub)))
  }, []);

  useEffect(() =>{
    setActiveGrades(() => findActiveGradeList(getAvailableListByContentAndSubject(currentContentType, currentSub)))
  }, [currentContentType, currentSub]);

  const findActiveGradeList = (data) => {
    let activeGrades = [];
    gradeList.forEach((grade, index) => {
      if(data.filter(item => (item.grade_id == grade.id)).length)
        activeGrades.push({id: grade.id, code: grade.code, title: grade.title})
        // activeGrades.push(grade.id)
    })
    return activeGrades;
  }

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: true,
    slidesToShow: 3,
    centerPadding: '130px',
    afterChange: (index) => {
      setActiveIndex(index);
    },
    responsive: [
      {
        breakpoint: breakpoints.xl,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: '60px',
        },
      },
      {
        breakpoint: breakpoints.lg,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '200px',
        },
      },
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '140px',
        },
      },
      {
        breakpoint: breakpoints.sm,
        settings: {
          slidesToShow: 1.2,
          arrows: false,
          centerMode: false,
          infinite: false,
        },
      },
    ],
  };
  const handleGradeClick = (e,index) => {
    setActiveIndex(index);
    let label = e.target.getAttribute('data-grade-label');
    trackToGtmFE('clicked_grade_selector',{'Click Text': label});
    typeof trackEventOnGA != "undefined" && trackEventOnGA("Clicked grade on curriculum section",'',label);

  };
  const handleSubjectChange = (newSubject) => {
    //reset the active index to 0
    setActiveIndex(0)
    // set the newSubject as current subject
    setCurrentSub(newSubject);
    trackToGtmFE('clicked_subject_selector',{'Click Text': newSubject?.title});

    typeof trackEventOnGA != "undefined" && trackEventOnGA("Clicked subject on curriculum section",'',newSubject.code);
  };
  const handleContentTypeChange = (newType) => {
    //reset the active index to 0
    setActiveIndex(0)
    // set the newType as current content type
    setCurrentContentType(newType);
    trackToGtmFE('clicked_content_type',{'Click Text':newType?.title});

    typeof trackEventOnGA != "undefined" && trackEventOnGA("Clicked content group on curriculum section",'',newType.code);
  };
  
  return (
    <Fragment>
      <div className="home-page-subject-grade-selectors">
        <GradesWrapper
          gradeList={gradeList}
          onGradeClick={handleGradeClick}
          activeIndex={activeIndex}
          activeGrades={activeGrades}
        />
        
        {Object.keys(subjectList).length > 1 ? (
          <SubjectWrapper
            onSubjectChange={handleSubjectChange}
            currentSub={currentSub}
            subjectList={subjectList}
          />
        ) : null}
      </div>
      {Object.keys(ContentTypeList).length > 1 ? (
        <TopicWrapper
          onContentTypeChange={handleContentTypeChange}
          currentContentType={currentContentType}
          ContentTypeList={ContentTypeList}
        />
      ) : null}
      
      <div className="home-page-slider">
        <UniverseSlider
          key={currentSub.id + (isClient ? 'client' : 'server')}
          settings={sliderSettings}
          gradeList={gradeList}
          activeIndex={activeIndex}
          currentSub={currentSub}
          currentContentType={currentContentType}
          abTestsDetails = {abTestsDetails}
        />
        {/* commenting for phase 1 */}
        {/* <ExploreLink
          text="Explore complete curriculum"
          style={{ margin: '3rem auto 0 auto' }}
        /> */}
      </div>
    </Fragment>
  );
};
const GradesWrapper = ({ onGradeClick, activeIndex, activeGrades, gradeList}) => {
  const handleSelect = (e,selectedindex) => {
    onGradeClick(e,selectedindex);
  };
  return (
    <ul className="grade-list universe-learning-grade-list" data-testid="grades-wrapper">
      {activeGrades?.map((item, index) => {
        // code is the name of grade => Pre-k, K, 1,2,3 etc
        const { id: grade_id } = item;
        const { code } = gradeIdMap.get(grade_id);
        return (
          <li className={`grade-item grade-box-shadow ${code === 'pk' ? "grade-prek":"grade-other"} ${index === activeIndex ? "grade-selected":"grade-not-selected"}`}
            selected={index === activeIndex}
            onClick={(e) => handleSelect(e,index)}
            key={"grade_"+grade_id}
            data-grade-index = {index}
            data-grade-label = {i18n.t(`loggedout_homepage.universe_section.grade_selectors.${code}`)}
            data-testid = {"grade-index-"+index}
          >
            {i18n.t(`loggedout_homepage.universe_section.grade_selectors.${code}`)}
          </li>
        );
      })}
    </ul>
  );
};

const SubjectWrapper = ({ onSubjectChange, subjectList, currentSub }) => {
  const handleSelect = (newSubject) => {
    onSubjectChange(newSubject);
  };
  return (
    <ul className="universe-learning-subject-list" data-testid="subject-wrapper">
      {Object.keys(subjectList)?.map((subject_id, index) => (
        <li className={`subject-item grade-box-shadow ${index === 0 ? "first-sub":"other-sub"} ${subject_id == currentSub.id ? "sub-selected":"sub-not-selected"} `}
          selected={subject_id === currentSub.id}
          onClick={() => handleSelect(subjectList[subject_id])}
          key={"subject-"+index}
          data-testid={"subject-index-"+index}
        >
          {subjectList[subject_id].title}
        </li>
      ))}
    </ul>
  );
};

const TopicWrapper = ({ onContentTypeChange, ContentTypeList, currentContentType }) => {
  const handleSelect = (newTopic) => {
    onContentTypeChange(newTopic);
  };
  return (
    <ul className="universe-learning-subject-list content-type" data-testid="topic-wrapper">
      {Object.keys(ContentTypeList)?.map((content_id, index) => (
        <li className={`subject-item grade-box-shadow content-type-item ${index === 0 ? "first-sub":"other-sub"} ${content_id == currentContentType.id ? "sub-selected":"sub-not-selected"} `}
          selected={content_id === currentContentType.id}
          onClick={() => handleSelect(ContentTypeList[content_id])}
          key={"topic-"+index}
          data-testid={"content-type-index-"+index}
        >
          {ContentTypeList[content_id].title} {ContentTypeList[content_id].count}
        </li>
      ))}
    </ul>
  );
};

const UniverseSlider = ({ settings, activeIndex, currentSub, currentContentType, abTestsDetails }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [slideItems, setSlideItems] = useState([])
  const sliderRef = useRef();
  const parentText = "I’m a parent";
  const teacherText = "I’m a teacher";

  useEffect(() => {
    if(openPopup){
      trackToGtmFE("homepage_signup_popup_shown");
    }
  },[openPopup]);

  useEffect(() => {
    sliderRef.current.slickGoTo(activeIndex);
  }, [activeIndex]);

  useEffect(() => {
    var slickScrollEventTriggered = false;
    const handleSlickEvent = e => {
      const target = e.target;
      if (!slickScrollEventTriggered && target.tagName === 'BUTTON' && (target.classList.contains('slick-next') || target.classList.contains('slick-prev'))){
        trackToGtmFE('horizontal_scrolled_playable_worksheet_cards');
        slickScrollEventTriggered=true;
      }
    };
    const handleScrollEventForMobileAndTab = () => {
      if(!slickScrollEventTriggered){
        slickScrollEventTriggered =true;
        trackToGtmFE('horizontal_scrolled_playable_worksheet_cards');
      }
    }

    document.addEventListener('click', handleSlickEvent);
    var slickElementForScrollEvent = document.getElementsByClassName('slick-list')?.[0];
    slickElementForScrollEvent?.addEventListener('scroll', handleScrollEventForMobileAndTab);
    return () => {
      document.removeEventListener('click', handleSlickEvent);
      slickElementForScrollEvent?.removeEventListener('scroll', handleScrollEventForMobileAndTab);
    };
  }, []);


  useEffect(() => {
    setSlideItems(getAvailableListByContentAndSubject(currentContentType, currentSub))
  }, [currentSub, currentContentType])

  const handleOpenPopup = (e, data,subjectCode) => {
    e.stopPropagation()
    trackToGtmFE('clicked_playable_worksheet_card',
    {
      'Click Text':e?.target?.textContent || e?.target?.innerText,
      'Click URL':e?.target?.getAttribute('href')
    });
    typeof trackEventOnGA != "undefined" && trackEventOnGA("Clicked card on curriculum section",'',data+' '+subjectCode);

    setOpenPopup(true)
  };
const handleClosePopup = () => {
    setOpenPopup(false);
    trackToGtmFE("homepage_crossed_signup_popup")
  }

  const ctaClickHandler = async (e) => {
    const eventProperties={'Click Text': parentText,'Click URL':"/parents/register"};
    trackToGtmFE('clicked_signup_popup_parent', eventProperties)
    location.href= "/parents/register";
  }
  const ctaTeacherClickHandler = async (e) => {
    const eventProperties={'Click Text': teacherText,'Click URL':"/teachers/register"};
    trackToGtmFE('clicked_signup_popup_teacher', eventProperties)
    location.href= "/teachers/register";
  }

  /**
   * only-seo-visible is a class to hide the content from the user but visible to the search engine 
   * as Slider content is not visible to the search engine. We are listing all the available worksheets
   *  and games for ela and maths for each grades for search engine read and making Slider by default hidden.
   **/

  return (
    <>
      <ul className="only-seo-visible">
        {GRADE_SUBJECT_CONTENT_LIST.map((item, key) => {
          const { title } = gradeIdMap.get(item.grade_id);
          const { title: subjectTitle } = subjectList[item.subject_id];
          
          return (
            <li  className="universe-learning-card-wrapper" key={key}>
              <div  className="card">
                <div className="card-image">{`${title} ${subjectTitle} ${item.content_title}`}</div>
                <p className="card-title">{title}</p>
                <div className="card-desc-group">
                  {Object.keys(item.topics)?.map((contentGroupTitle, index) => {
                    let link = item.topics[contentGroupTitle]
                    return (
                      <a className="card-desc" href={link} key={"seo_content_link_"+index} onClick={(e) => e.stopPropagation()}>{contentGroupTitle || 'Not found'}</a>
                    );
                  })}
                </div>
                <a className="see-link" href={item.seeAll.url}  onClick={(e) => e.stopPropagation()}>{item.seeAll.text}</a>
              </div>
            </li>
          );
        })
        }
      </ul>
      <Slider {...settings} ref={sliderRef} style={{display:"none"}}>
        {slideItems?.map((item, key) => {
          let { grade_id, subject_id, content_title, content_type } = item;
          let { code: subjectCode, title: subjectTitle } = subjectList[subject_id];
          // title of grade => Pre-k, Kindergarten, Grade 1,2,3 etc
          const { title, code: gradeCode } = gradeIdMap.get(grade_id);
          
          //get card images for current selection
          const gradeImages = getGradeImage(content_type, subject_id, gradeCode)
          const coverImage = gradeImages['src'];
          const coverImageSrcset = gradeImages['srcset'];
          const coverImageSizes = gradeImages['sizes'];
          
          return (
            <div  className="universe-learning-card-wrapper" key={"universe-card-"-key}  data-testid={"ul-card-"+key}>
              <div  className="card" onClick={((e) => handleOpenPopup(e, gradeCode,subjectCode))} >
                <div className="card-image">
                  <Image src={coverImage} sizes={coverImageSizes} srcSet={coverImageSrcset} alt={`${title} ${subjectTitle} ${content_title}`} className="universe-learning-img" data-testid={"card-image-"+key}/>
                </div>
                <p className="card-title">{title}</p>
                <div className="card-desc-group">
                  {Object.keys(item.topics)?.map((contentGroupTitle, index) => {
                    let link = item.topics[contentGroupTitle]
                    return (
                      <a className="card-desc" href={link} key={contentGroupTitle+"-card-desc-"+index}>{contentGroupTitle || 'Not found'}</a>
                    );
                  })}
                </div>
                <a className="see-link" href={item.seeAll.url} >{item.seeAll.text}</a>
              </div>
            </div>
          );
        })}
      </Slider>
      {openPopup ? (
        <Popup onClose={handleClosePopup} minHeight={562}>
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div className="universe-learning-popup-content">
              <Heading2>Sign Up to explore our Curriculum</Heading2>
              <div className="signup-cards">
                <SignupCard
                  text={parentText}
                  icon={ParentSignupIcon}
                  onClick = {ctaClickHandler}
                  imageHeight="8.6rem"
                  imageWidth="9.5rem"
                />
                <SignupCard
                  text={teacherText}
                  icon={TeacherSignupIcon}
                  onClick = {ctaTeacherClickHandler}
                  imageHeight="8.8rem"
                  imageWidth="8.8rem"
                />
              </div>
            </div>
          </div>
        </Popup>
      ) : null}
    </>
  );
};

const gradeIdMap = makeMap(gradeList);

function makeMap(arr) {
  const map = new Map();
  arr.forEach((item) => {
    map.set(item.id, item);
  });
  return map;
};

function getAvailableListByContentAndSubject(currentContentType, currentSub){
  return GRADE_SUBJECT_CONTENT_LIST?.filter(item => (item.content_type == currentContentType.id && item.subject_id == currentSub.id))
}

export default UniverseLearning;