export var footerUkData = [
    {
        mainLink: 'Explore Games',
        sublinks: [
            {
                title: 'Reception Maths Games',
                link: '/maths-games-for-reception',
            },
            {
                title: 'Year 1 Maths Games',
                link: '/maths-games-for-year-1',
            },
            {
                title: 'Year 2 Maths Games',
                link: '/maths-games-for-year-2',
            },
            {
                title: 'Year 3 Maths Games',
                link: '/maths-games-for-year-3',
            },
            {
                title: 'Year 4 Maths Games',
                link: '/maths-games-for-year-4',
            },
            {
                title: 'Year 5 Maths Games',
                link: '/maths-games-for-year-5',
            },
            {
                title: 'Year 6 Maths Games',
                link: '/maths-games-for-year-6',
            },
            {
                title: 'Reception English Games',
                link: '/english-games-for-reception',
            },
            {
                title: 'Year 1 English Games',
                link: '/english-games-for-year-1',
            },
            {
                title: 'Year 2 English Games',
                link: '/english-games-for-year-2',
            },
            {
                title: 'Year 3 English Games',
                link: '/english-games-for-year-3',
            },
            {
                title: 'Year 4 English Games',
                link: '/english-games-for-year-4',
            },
            {
                title: 'Year 5 English Games',
                link: '/english-games-for-year-5',
            },
            {
                title: 'Year 6 English Games',
                link: '/english-games-for-year-6',
            },
        ],
    },
    {
        mainLink: 'Explore Worksheets',
        sublinks: [
            {
                title: 'Reception Maths Worksheets',
                link: '/maths-worksheets-for-reception',
            },
            {
                title: 'Year 1 Maths Worksheets',
                link: '/maths-worksheets-for-year-1',
            },
            {
                title: 'Year 2 Maths Worksheets',
                link: '/maths-worksheets-for-year-2',
            },
            {
                title: 'Year 3 Maths Worksheets',
                link: '/maths-worksheets-for-year-3',
            },
            {
                title: 'Year 4 Maths Worksheets',
                link: '/maths-worksheets-for-year-4',
            },
            {
                title: 'Year 5 Maths Worksheets',
                link: '/maths-worksheets-for-year-5',
            },
            {
                title: 'Year 6 Maths Worksheets',
                link: '/maths-worksheets-for-year-6',
            },
            {
                title: 'Reception English Worksheets',
                link: '/english-worksheets-for-reception',
            },
            {
                title: 'Year 1 English Worksheets',
                link: '/english-worksheets-for-year-1',
            },
            {
                title: 'Year 2 English Worksheets',
                link: '/english-worksheets-for-year-2',
            },
            {
                title: 'Year 3 English Worksheets',
                link: '/english-worksheets-for-year-3',
            },
            {
                title: 'Year 4 English Worksheets',
                link: '/english-worksheets-for-year-4',
            },
            {
                title: 'Year 5 English Worksheets',
                link: '/english-worksheets-for-year-5',
            },
            {
                title: 'Year 6 English Worksheets',
                link: '/english-worksheets-for-year-6',
            },
        ],
    },
    {
        mainLink: 'Explore Activities',
        sublinks: [
            {
                title: 'General Knowledge',
                link: '/games/general-knowledge',
            },
            {
                title: 'Art & Creativity',
                link: '/games/art-creativity',
            },
            {
                title: 'Coloring',
                link: '/games/coloring',
            },
            {
                title: 'Puzzles',
                link: '/games/puzzles',
            },
            {
                title: 'Multiplayer',
                link: '/games/multiplayer',
            },
            {
                title: 'Motor Skills',
                link: '/games/motor-skills',
            },
            {
                title: 'Rhymes',
                link: '/games/music/rhymes',
            },
            {
                title: 'Logic & Thinking',
                link: '/games/logic-thinking',
            },
            {
                title: 'Cooking',
                link: '/games/cooking',
            },
            {
                title: 'Stories',
                link: '/games/stories',
            },
            {
                title: 'Seasonal',
                link: '/games/seasonal',
            },
        ],
    },
    {
        mainLink: 'SplashLearn Content',
        sublinks: [
            {
                title: 'For Parents',
                link: '/features/parents',
            },
            {
                title: 'For Classrooms',
                link: '/features/teachers',
            },
            {
                title: 'For HomeSchoolers',
                link: 'https://www.splashlearn.com/homeschool-program',
            },
            {
                title: 'Blog',
                link: 'https://www.splashlearn.com/blog/',
            },
            {
                title: 'About Us',
                link: 'https://www.splashlearn.com/about',
            },
            {
                title: 'Careers',
                link: 'https://www.splashlearn.com/careers/',
            },
            {
                title: 'Success Stories',
                link: 'https://www.splashlearn.com/success-stories',
            },
        ],
    },
    {
        mainLink: 'Help & Support',
        sublinks: [
            {
                title: 'Parents',
                link: 'https://support.splashlearn.com/hc/en-us/categories/12271293255570-I-am-a-Parent',
            },
            {
                title: 'Teachers',
                link: 'https://support.splashlearn.com/hc/en-us/categories/12271297576722-I-am-a-Teacher',
            },
            {
                title: 'Download SplashLearn',
                link: 'https://www.splashlearn.com/apps',
            },
            {
                title: 'Contact Us',
                link: 'https://www.splashlearn.com/about/contact-us',
            },
            {
                title: 'Gift SplashLearn',
                link: 'https://crmlp.splashlearn.com/gift',
            },
        ],
    },
];
