import React from 'react';
import Image from '../../../common/Image';
import StarIcon from '~images/loggedout/shapes/star.svg';
import i18n from '../../../../../utils/i18n';

const ModularSection0 = () => {

  const tempDataArr = [
  {
    "heading":  "Loved by over",
    "countText": "40 million learners"
  },{
    "heading":  i18n.t('loggedout_homepage.social_point_2_super_heading'),
    "countText": i18n.t('loggedout_homepage.social_point_2_heading')
  },{
    "heading":  "80% learners show",
    "countText": "increased confidence in 4 weeks"
  }];

  return (
    <div className="clearfix modular-section-0-wrapper">
      <p className="head-text">
        Join millions of confident learners
      </p>

      <ul className="section-0-ul-list">
        {tempDataArr.map(function(individualData) {
          return(
            <li className="section-0-li-list-item">
              <span className="section-0-span">{individualData.heading}</span>
              <p className="section-0-p">{individualData.countText}</p>
            </li>
          )
        })}
      </ul>
      <Image src={StarIcon} alt="Blue star" className="section-0-img" />
    </div>
  );
};

export default ModularSection0;
